import React from "react";
import { Popover, Button, Typography } from "@material-ui/core";

const ReportAction = ({ downloadReport, saveReport }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Button aria-describedby={id} variant="outlined" onClick={handleClick}>
        Action
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography
          sx={{
            p: 1,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ddd",
            },
          }}
          onClick={downloadReport}
        >
          Download PDF
        </Typography>
        <Typography
          sx={{
            p: 1,
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#ddd",
            },
          }}
          onClick={saveReport}
        >
          Save Report
        </Typography>
      </Popover>
    </>
  );
};

export default ReportAction;
