import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  dropdownPortfolios,
  dropdownProjects,
} from "../../../api/budget/Budget";
import {
  getHeading,
  healthReport,
  listReport,
} from "../../../api/reports/Report";
import useLoader from "../../../hooks/useLoader";
import ReportAction from "./ReportAction";

require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
// HighchartsDownloadCSV(ReactHighcharts.Highcharts)

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ProjectHealth = () => {
  const classes = useStyles();
  const [allProjects, setAllProjects] = useState([]);
  const [allPortfolio, setAllPortfolio] = useState([]);
  const [seledtedPortfolio, setSeledtedPortfolio] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [reports, setReports] = useState([]);
  const { loaderStart, loaderEnd } = useLoader();
  const [series, setSeries] = useState([]);
  const [reportHeading, setHeading] = useState([]);

  const Highchart = useRef();

  const [options, setObject] = useState(null);

  useEffect(() => {
    getDropdownPortfolios();
    return () => {
      setSelectedProject("");
    };
  }, []);
  useEffect(() => {
    getDropdownProjects();
  }, [seledtedPortfolio]);

  const getDropdownProjects = async () => {
    const res = await dropdownProjects({ portfolioId: seledtedPortfolio });
    if (res.data.success) {
      setAllProjects(res.data.data.projectDropdown);
    }
  };

  const getDropdownPortfolios = async () => {
    const res = await dropdownPortfolios();
    if (res.data.success) {
      setAllPortfolio(res.data.data.portfolioDropdown);
    }
  };

  const handlePortfolioChange = (e) => {
    setSeledtedPortfolio(e.target.value);
    setSelectedProject("");
  };

  const projectHealthReport = async () => {
    loaderStart();
    try {
      const res = await healthReport({
        projectId: selectedProject,
        portfolioId: seledtedPortfolio,
      });
      if (res) {
        setSeries([...res.data]);
        setObject({
          chart: {
            type: "column",
          },
          xAxis: {
            categories: ["Milestone Health", "Hours Health", "Budget Health"],
            abels: {
              style: {
                fontSize: 25,
              },
            },
          },
          title: {
            text: "",
          },
          yAxis: {
            min: 0,
            title: {
              text: "Total Project Count",
            },
          },
          legend: {
            // align: 'right',
            // x: -40,
            // verticalAlign: 'top',
            // y: 0,
            // floating: true,
            // backgroundColor:
            //     Highcharts.defaultOptions.legend.backgroundColor || 'white',
            // borderColor: '#CCC',
            // borderWidth: 1,
            // shadow: false
            itemStyle: {
              fontSize: "20px",
            },
          },
          //   exporting: {
          //     chartOptions: { // specific options for the exported image
          //         plotOptions: {
          //             series: {
          //                 dataLabels: {
          //                     enabled: true
          //                 }

          //             }
          //         }
          //     },
          //     scale: 3,
          //     fallbackToExportServer: false
          // },
          tooltip: {
            headerFormat: "<b>{point.x}</b><br/>",
            pointFormat:
              "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
          },
          plotOptions: {
            column: {
              stacking: "normal",
              dataLabels: {
                enabled: true,
              },
            },
          },
          series: [...res.data],
        });
        console.log(res);
        loaderEnd();
      }
      const heading = await getHeading({
        projectId: selectedProject,
        portfolioId: seledtedPortfolio,
      });
      setHeading(heading.data);
      const res1 = await listReport({
        projectId: selectedProject,
        portfolioId: seledtedPortfolio,
      });
      // if (res1.data) {
      setReports(res1.data);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    projectHealthReport();
  }, []);

  const getDataURI = async () => {
    // var imgURl;
    // await ApexCharts.exec("chart", "dataURI").then(({ imgURI, blob }) => {
    //     console.log(imgURI);
    //     const pdf = new jsPDF();
    //     pdf.addImage(imgURI, 'JPEG', 15, 30, 180, 120);
    //     pdf.save("pdf-chart.pdf");
    // });
    if (Highchart && Highchart.current && Highchart.current.chart) {
      // Highchart.current.chart.exportChart('application/pdf');
      Highchart.current.chart.exportChart({
        type: "application/pdf",
      });
    }
  };

  const saveReport = async () => {
    let svgString = Highchart.current.chart.getChartHTML();
    console.log(svgString);
    // Use DOMParser to parse new svg element from svgString
    // let parser = new DOMParser();
    // let svgElem = parser.parseFromString(svgString, "image/svg+xml").documentElement;

    // Use toDataURL extension to generate Base64 string
    // let b64 = svgString.getDataUrl()

    // Log string in console
    // console.log(b64);
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item md={3} sm={12} xs={12}>
          <Box sx={{ width: "100%" }}>
            <FormControl className={classes.formControl}>
              <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                Portfolios
              </InputLabel>
              <Select
                labelId="select-status"
                label="SELECT PORTFOLIO"
                name="seledtedPortfolio"
                value={seledtedPortfolio}
                fullWidth
                onChange={handlePortfolioChange}
              >
                <MenuItem value="">
                  {" "}
                  {allPortfolio.length > 0 ? "All Portfolios" : "none"}
                </MenuItem>
                {allPortfolio.map((portfolio) => {
                  return (
                    <MenuItem key={portfolio.id} value={portfolio.id}>
                      {portfolio.name}
                    </MenuItem>
                  );
                })}{" "}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <Box sx={{ width: "100%" }}>
            <FormControl className={classes.formControl}>
              <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                Projects
              </InputLabel>
              <Select
                labelId="select-status"
                label="SELECT PROJECT"
                name="selectedProject"
                value={selectedProject}
                fullWidth
                onChange={(e) => {
                  setSelectedProject(e.target.value);
                }}
              >
                <MenuItem value="">
                  {allProjects.length > 0 ? "All Projects" : "none"}
                </MenuItem>
                {allProjects?.length > 0 &&
                  allProjects.map((proj) => (
                    <MenuItem key={proj.id} value={proj.id}>
                      {proj.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <Box sx={{ m: -1 }}>
            <Button
              color="primary"
              sx={{ m: 1, py: 1.7 }}
              variant="contained"
              size="large"
              fullWidth
              onClick={projectHealthReport}
            >
              Generate Report
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ pt: 4 }}>
        <Box sx={{ ml: "auto", display: "flex", justifyContent: "flex-end" }}>
          <Box component="span">
            {/* <Button
              color="primary"
              sx={{ m: 1, py: 1.7 }}
              variant="contained"
              size="large"
              fullWidth
              onClick={getDataURI}
            >
              Generate Report
            </Button> */}
            <ReportAction downloadReport={getDataURI} saveReport={saveReport} />
          </Box>
        </Box>
        <Box>
          <Typography variant="h4" sx={{ pt: 1 }} align="center">
            Report
          </Typography>
          <Typography variant="h6" sx={{ pt: 1, pb: 3 }} align="center">
            {reportHeading}
          </Typography>
        </Box>
      </Box>

      {options && (
        <div id="chart">
          {/* <Chart options={option} series={series} type="bar" height={500} width={1300}/> */}
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={Highchart}
          />
        </div>
      )}

      <Typography variant="h4" sx={{ pt: 1 }} align="left">
        List Chart Active Health
      </Typography>
      {reports?.length > 0 ? (
        <Table style={{ paddingTop: "10%" }}>
          <TableHead sx={{ th: { fontSize: "12px", opacity: "0.8" } }}>
            <TableRow>
              <TableCell sx={{ px: 2 }}>#</TableCell>
              <TableCell>PROJECT NAME</TableCell>
              <TableCell>START DATE</TableCell>
              <TableCell>END DATE</TableCell>
              <TableCell>MILESTONE</TableCell>
              <TableCell>HOURS</TableCell>
              <TableCell>BUDGET</TableCell>
              <TableCell align="center">STATUS</TableCell>
            </TableRow>
          </TableHead>
          {reports?.map((report, index) => (
            <TableRow>
              <TableCell sx={{ px: 2 }}>{++index}</TableCell>
              <TableCell>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to={`/dashboard/projects/view-and-edit-project/${report.ProjectId}`}
                  underline="none"
                  variant="subtitle2"
                  className="redirect-link"
                >
                  {report.ProjectName}
                </Link>
              </TableCell>
              <TableCell>{report.StartDate}</TableCell>
              <TableCell>{report.DueDate}</TableCell>
              <TableCell align="centre">
                <Box
                  sx={{
                    height: "18px",
                    width: "18px",
                    borderRadius: "18px",
                    backgroundColor:
                      report.milestone === 3
                        ? "red"
                        : report.milestone === 2
                        ? "yellow"
                        : report.milestone === 1
                        ? "green"
                        : report.milestone === 0
                        ? "silver"
                        : "",
                    // mx: "auto",
                    marginLeft: "15%",
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <Box
                  sx={{
                    height: "18px",
                    width: "18px",
                    borderRadius: "18px",
                    backgroundColor:
                      report.hours === 3
                        ? "red"
                        : report.hours === 2
                        ? "yellow"
                        : report.hours === 1
                        ? "green"
                        : report.hours === 0
                        ? "silver"
                        : "",
                    // mx: "auto",
                    marginLeft: "15%",
                  }}
                />
              </TableCell>
              <TableCell align="center">
                <Box
                  sx={{
                    height: "18px",
                    width: "18px",
                    borderRadius: "18px",
                    backgroundColor:
                      report.budget === 3
                        ? "red"
                        : report.budget === 2
                        ? "yellow"
                        : report.budget === 1
                        ? "green"
                        : report.budget === 0
                        ? "silver"
                        : "",
                    // mx: "auto",
                    marginLeft: "15%",
                  }}
                />
              </TableCell>
              <TableCell align="center">Active</TableCell>
            </TableRow>
          ))}
        </Table>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ pt: 8 }} align="center">
            No Report Found
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ProjectHealth;
