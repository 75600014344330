import { combineReducers } from "@reduxjs/toolkit";
import { reducer as calendarReducer } from "../slices/calendar";
import { reducer as userReducer } from "../slices/user";

const rootReducer = combineReducers({
  calendar: calendarReducer,
  userList: userReducer,
});

export default rootReducer;
