const portfolioStatus = [
  {
    value: 1,
    title: "Active",
  },
  {
    value: 2,
    title: "Inactive",
  },
];

export default portfolioStatus;
