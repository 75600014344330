import { handleApiRequest } from "../../hooks/callApi";

export const saveProjectOtherDetails = async (
  projectId,
  whyWeAreDoingThisProject,
  whatWeNeedToDo,
  scopeAndDeliverables,
  keySuccessMeasures
) => {
  const body = {
    project_id: projectId,
    why_we_are_doing_this_project: whyWeAreDoingThisProject,
    what_we_need_to_do: whatWeNeedToDo,
    scope_and_deliverables: scopeAndDeliverables,
    key_success_measures: keySuccessMeasures,
  };
  const res = await handleApiRequest(
    `/api/save-project-other-details`,
    "post",
    body
  );
  return res;
};
