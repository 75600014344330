import { createContext, useReducer, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import useLoader from "../hooks/useLoader";
import useAuth from "../hooks/useAuth";
import { portfolioDropDown, searchPortfolio } from "../api/portfolio/Portfolio";
const initialState = {
  allPortfolio: [],
  totalPortfolioCount: 0,
  dropdownPortfolio: [],
};

const handlers = {
  ALLPORTFOLIOS: (state, action) => {
    const { allPortfolio } = action.payload;

    return {
      ...state,
      allPortfolio,
    };
  },
  DROPDOWNPORTFOLIOS: (state, action) => {
    const { dropdownPortfolio } = action.payload;

    return {
      ...state,
      dropdownPortfolio,
    };
  },
  PORTFOLIOCOUNT: (state, action) => {
    const { totalPortfolioCount } = action.payload;

    return {
      ...state,
      totalPortfolioCount,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const PortfolioContext = createContext({
  ...initialState,
  getAllPortfolio: () => Promise.resolve(),
  getPortfolioCount: () => Promise.resolve(),
  getAllDropdownPortfolios: () => Promise.resolve(),
});

export const PrtfolioProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loaderStart, loaderEnd } = useLoader();
  const { user } = useAuth();
  useEffect(() => {
    if (user?.id) {
      getPortfolioCount();
    }
  }, [user]);

  const getAllPortfolio = async (pageNum, portfoliotype, portfolioName) => {
    loaderStart();
    const currentPage = pageNum || 1;
    const res = await searchPortfolio(
      currentPage,
      portfoliotype,
      portfolioName
    );

    dispatch({
      type: "ALLPORTFOLIOS",
      payload: {
        allPortfolio: res.data.data.portfolios,
      },
    });
    loaderEnd();
  };
  const getAllDropdownPortfolios = async () => {
    loaderStart();
    const res = await portfolioDropDown();
    console.log(res);

    dispatch({
      type: "DROPDOWNPORTFOLIOS",
      payload: {
        dropdownPortfolio: res.data.data.portfolioDropdown,
      },
    });
    loaderEnd();
  };

  const getPortfolioCount = async () => {
    try {
      loaderStart();
      const accessToken = window.localStorage.getItem("accessToken");
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/portfolio/count`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch({
        type: "PORTFOLIOCOUNT",
        payload: {
          totalPortfolioCount: res.data.data.count,
        },
      });
      loaderEnd();
    } catch (error) {
      console.log(error);
      loaderEnd();
    }
  };

  return (
    <PortfolioContext.Provider
      value={{
        ...state,
        getAllPortfolio,
        getPortfolioCount,
        getAllDropdownPortfolios,
      }}
    >
      {children}
    </PortfolioContext.Provider>
  );
};

PrtfolioProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PortfolioContext;
