import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import PermissionGuard from "../../PermissionGuard";
import ScoreRange from "./ScoreRange";
import { getScoreRange } from "../../../api/project/Score";

const impactMessage = (score) => {
  if (score >= 20) {
    return "High Positive Impact";
  } else if (score > 10 && score <= 20) {
    return "Medium Positive Impact";
  } else if (score <= 10) {
    return "Negative Impact";
  }
};
const convertToNumber = (value) => {
  if (value === "") {
    return 0;
  }
  return Number(value);
};

const allScores = [
  {
    id: 1,
    category: "Reward",
    metric: "Revenue Increasing",
    description: "This project will generate measurable, incremental revenue",
    example: "3 = $75,000[X]5 = $250,000[X]8 = $500,000",
    fieldName: "revenueIncreasing",
    aFieldName: "arevenueIncreasing",
  },
  {
    id: 2,
    category: "Reward",
    metric: "Strategic Fit",
    description:
      "This project supports a strategic direction or initiative of the company",
    example: `3 = minor element of strategic plan[X]
    5 = component of strategic plan[X]
    8 = significantly supports major element of company strategy`,
    fieldName: "strategicFit",
    aFieldName: "astrategicFit",
  },
  {
    id: 3,
    category: "Reward",
    metric: "Mission Criticality",
    description:
      "This project will introduce risk to the enterprise if it is not executed",
    example: `3 = small risk[X]
    5 = material risk[X]
    8 = significant risk`,
    fieldName: "missionCriticality",
    aFieldName: "amissionCriticality",
  },
  {
    id: 4,
    category: "Reward",
    metric: "Cost Reduction /Process Improvement",
    description: "This project will have measurable process savings",
    example: `3 = $20,000[X]
    5 = $50,000[X]
    8 = $100,000 savings`,
    fieldName: "costReduction",
    aFieldName: "acostReduction",
  },
  {
    id: 5,
    category: "Risk",
    metric: "Project Cost",
    description: "This project has a cost relative to other projects",
    example: `3 = $20,000[X]
    5 = $50,000[X]
    8 = $100,000`,
    fieldName: "projectCost",
    aFieldName: "aprojectCost",
  },
  {
    id: 6,
    category: "Risk",
    metric: "Business Risks",
    description:
      "There is a potential business risk to the company during development or implementation of this project.",
    example: `3 = small risk[X]
    5 = material risk[X]
    8 = significant risk`,
    fieldName: "businessRisks",
    aFieldName: "abusinessRisks",
  },
  {
    id: 7,
    category: "Risk",
    metric: "Technical Risk",
    description:
      "There is a potential risk to the project due to unproven or unknown technology this is a test",
    example: `3 = new but proven technology[X] 
    5 = new technology to the company[X]
    8 = new technology to industry this is a test`,
    fieldName: "score",
    aFieldName: "ascore",
  },
];

const Score = ({ selectedProject }) => {
  const { permissions } = useAuth();
  const { scoreID: scoreIdUrl } = useParams();
  const [isScoreRangeFormOpen, setIsScoreRangeFormOpen] = useState(false);
  const [projectScores, setProjectScores] = useState({
    revenueIncreasing: 0,
    strategicFit: 0,
    missionCriticality: 0,
    costReduction: 0,
    projectCost: 0,
    businessRisks: 0,
    score: 0,
  });

  const [aprojectScores, setAProjectScores] = useState({
    arevenueIncreasing: 0,
    astrategicFit: 0,
    amissionCriticality: 0,
    acostReduction: 0,
    aprojectCost: 0,
    abusinessRisks: 0,
    ascore: 0,
  });
  const [scoreId, setScoreId] = useState(null);
  const [overallScore, setOverallScore] = useState(0);
  const [overallAScore, setOverallAScore] = useState(0);
  const [overAllRiskScore, setOverAllRiskScore] = useState(0);
  const [overAllRiskActualScore, setOverAllRiskActualScore] = useState(0);
  const [projectName, setProjectName] = useState("");
  const [message, setMessage] = useState("");
  const [whyThisProject, setWhyThisProject] = useState("");
  const [scoreRange, setScoreRange] = useState({
    lowRange: 10,
    highRange: 21,
  });

  const [editDisabled, setEditDisabled] = useState(false);

  useEffect(() => {
    let totalScore = 0;
    Object.entries(projectScores).forEach(([key, value]) => {
      totalScore = totalScore + parseInt(value);
    });
    totalScore =
      convertToNumber(projectScores.revenueIncreasing) +
      convertToNumber(projectScores.strategicFit) +
      convertToNumber(projectScores.missionCriticality) +
      convertToNumber(projectScores.costReduction);
    setOverallScore(totalScore);

    const totalRiskScore =
      convertToNumber(projectScores.projectCost) +
      convertToNumber(projectScores.businessRisks) +
      convertToNumber(projectScores.score);

    setOverAllRiskScore(totalRiskScore);

    let atotalScore =
      convertToNumber(aprojectScores.arevenueIncreasing) +
      convertToNumber(aprojectScores.astrategicFit) +
      convertToNumber(aprojectScores.amissionCriticality) +
      convertToNumber(aprojectScores.acostReduction);
    setOverallAScore(atotalScore);

    let atotalRiskScore =
      convertToNumber(aprojectScores.aprojectCost) +
      convertToNumber(aprojectScores.abusinessRisks) +
      convertToNumber(aprojectScores.ascore);
    setOverAllRiskActualScore(atotalRiskScore);
  }, [projectScores, aprojectScores]);

  useEffect(() => {
    getRange();
  }, []);

  useEffect(() => {
    if (scoreIdUrl) {
      getProjectScore();
    }
  }, [scoreIdUrl]);

  useEffect(() => {
    setMessage(impactMessage(overallScore - overAllRiskScore));
  }, [overallScore, overAllRiskScore]);

  const handleScoreChange = (e) => {
    setProjectScores((prevScore) => ({
      ...prevScore,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAScoreChange = (e) => {
    setAProjectScores((prevScore) => ({
      ...prevScore,
      [e.target.name]: e.target.value,
    }));
  };

  const getRange = async () => {
    const res = await getScoreRange();
    if (res.data) {
      const t = res.data.data.item;
      setScoreRange({
        lowRange: t.low_range || 10,
        highRange: t.high_range || 21,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-score`,
        {
          project_name: projectName,
          project_description: whyThisProject,
          score_id: scoreIdUrl || "",
          revenue_increasing: projectScores.revenueIncreasing,
          strategic_fit: projectScores.strategicFit,
          mission_criticality: projectScores.missionCriticality,
          cost_reduction: projectScores.costReduction,
          project_cost: projectScores.projectCost,
          business_risks: projectScores.businessRisks,
          tech_risks: projectScores.score,
          score: overallScore,

          a_revenue_increasing: aprojectScores.arevenueIncreasing,
          a_strategic_fit: aprojectScores.astrategicFit,
          a_mission_criticality: aprojectScores.amissionCriticality,
          a_cost_reduction: aprojectScores.acostReduction,
          a_project_cost: aprojectScores.aprojectCost,
          a_business_risks: aprojectScores.abusinessRisks,
          a_tech_risks: aprojectScores.ascore,
          a_score: overallAScore,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      toast.success("Score added successfully");
    } catch (error) {
      setEditDisabled(false);
      console.log(error);
    }
  };

  const getProjectScore = async () => {
    try {
      console.log("scoreIdUrl", scoreIdUrl);
      if (!scoreIdUrl) return;
      // setEditDisabled(true);
      const accessToken = window.localStorage.getItem("accessToken");
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-score/${scoreIdUrl}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (res.data.success && res.data.data.scores.length > 0) {
        const scores = res.data.data.scores[0];
        setProjectName(scores?.project_name || "");
        setWhyThisProject(scores?.project_description || "");
        setProjectScores({
          revenueIncreasing: scores?.revenue_increasing || 0,
          strategicFit: scores?.strategic_fit || 0,
          missionCriticality: scores?.mission_criticality || 0,
          costReduction: scores?.cost_reduction || 0,
          projectCost: scores?.business_risks || 0,
          businessRisks: scores?.tech_risks || 0,
          score: scores?.tech_risks || 0,
        });

        setAProjectScores({
          arevenueIncreasing: scores?.actual_revenue_increasing || 0,
          astrategicFit: scores?.actual_strategic_fit || 0,
          amissionCriticality: scores?.actual_mission_criticality || 0,
          acostReduction: scores?.actual_cost_reduction || 0,
          aprojectCost: scores?.actual_business_risks || 0,
          abusinessRisks: scores?.actual_tech_risks || 0,
          ascore: scores?.actual_tech_risks || 0,
        });

        setScoreId(scores.id);
        setOverallScore(scores?.score || 0);
        setOverallAScore(scores?.actual_score || 0);
      } else {
        setEditDisabled(false);
      }
    } catch (error) {
      console.log(error);
      setEditDisabled(false);
    }
  };
  return (
    <Box
      sx={{
        p: 5,
      }}
    >
      {isScoreRangeFormOpen ? (
        <ScoreRange
          isOpen={isScoreRangeFormOpen}
          setIsOpen={setIsScoreRangeFormOpen}
          scoreRange={scoreRange}
          setScoreRange={setScoreRange}
        />
      ) : (
        ""
      )}
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              name="projectName"
              fullWidth
              label="Project Name"
              variant="outlined"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              required
              name="whyThisProject"
              label="Why we are doing this project"
              variant="outlined"
              value={whyThisProject}
              onChange={(e) => setWhyThisProject(e.target.value)}
            />
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>CATEGORY</TableCell>
              <TableCell>METRIC</TableCell>
              <TableCell>DESCRIPTION </TableCell>
              <TableCell>EXAMPLE </TableCell>
              <TableCell>FORECASTED SCORES</TableCell>
              <TableCell>ACTUAL SCORES</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allScores.map((score, index) => (
              <>
                <TableRow hover key={index}>
                  <TableCell> {score.category} </TableCell>
                  <TableCell sx={{ maxWidth: "120px" }}>
                    {" "}
                    {score.metric}{" "}
                  </TableCell>
                  <TableCell sx={{ maxWidth: "180px" }}>
                    {" "}
                    {score.description}{" "}
                  </TableCell>
                  <TableCell>
                    {score.example.split("[X]")[0]}
                    <br />
                    {score.example.split("[X]")[1]}
                    <br />
                    {score.example.split("[X]")[2]}
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      disabled={!permissions["PROJECTSCORE_UPDATE"]}
                      name={score.fieldName}
                      onChange={handleScoreChange}
                      select
                      SelectProps={{ native: true }}
                      value={projectScores[score.fieldName]}
                      variant="outlined"
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                        (scoreValue, index) => (
                          <option key={index} value={scoreValue}>
                            {scoreValue}
                          </option>
                        )
                      )}
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      disabled={!permissions["PROJECTSCORE_UPDATE"]}
                      name={score.aFieldName}
                      onChange={handleAScoreChange}
                      select
                      SelectProps={{ native: true }}
                      value={aprojectScores[score.aFieldName]}
                      variant="outlined"
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                        (scoreValue, index) => (
                          <option key={index} value={scoreValue}>
                            {scoreValue}
                          </option>
                        )
                      )}
                    </TextField>
                  </TableCell>
                </TableRow>
                {index === 3 && (
                  <TableRow>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell>&nbsp;</TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontSize: "18px", fontWeight: "bold" }}
                    >
                      &nbsp;
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#337fff",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        {overallScore}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        background: "#4388fe",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        {overallAScore}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))}

            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell
                align="right"
                sx={{ fontSize: "18px", fontWeight: "bold" }}
              >
                &nbsp;
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#e46739",
                }}
              >
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "bold", color: "#fff" }}
                >
                  {overAllRiskScore}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: "#ef8158",
                }}
              >
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "bold", color: "#fff" }}
                >
                  {overAllRiskActualScore}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell>&nbsp;</TableCell>
              <TableCell
                align="right"
                sx={{ fontSize: "18px", fontWeight: "bold" }}
              >
                NET TOTAL SCORE
              </TableCell>
              <TableCell>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "bold", color: "#f33" }}
                >
                  {overallScore - overAllRiskScore}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  sx={{ fontSize: "18px", fontWeight: "bold", color: "#f33" }}
                >
                  {overallAScore - overAllRiskActualScore}
                </Typography>
              </TableCell>
            </TableRow>
            <PermissionGuard
              permissionCheck="PROJECTSCORE_UPDATE"
              returnEmpty={true}
            >
              <TableRow>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>&nbsp;</TableCell>
                <TableCell>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    SUBMIT
                  </Button>
                </TableCell>
              </TableRow>
            </PermissionGuard>
          </TableBody>
        </Table>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "50%",
            pt: 4,
            pb: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
            variant="h4"
          >
            Final Analysis
          </Typography>
          <Tooltip title="Customize Score Range">
            <Button
              onClick={() => setIsScoreRangeFormOpen(true)}
              variant="contained"
              color="primary"
              sx={{
                float: "right",
              }}
            >
              Customize
            </Button>
          </Tooltip>
        </Box>
        <Table
          sx={{
            width: "50%",
            "& td": {
              border: "1px solid #000",
              padding: "10px",
            },
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                Impact
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                }}
              >
                Range
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Project looks feasible. Proceed </TableCell>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  color: "#0f0",
                  fontWeight: "bold",
                }}
              >
                {scoreRange["highRange"]} to 30
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Project is feasible but keep a close eye on it{" "}
              </TableCell>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  color: "orange",
                  fontWeight: "bold",
                }}
              >
                {scoreRange["lowRange"] + 1} to {scoreRange["highRange"] - 1}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                Project is risky, would not recommend - only proceed if it meets
                strategic objective. Keep a very close eye{" "}
              </TableCell>
              <TableCell
                sx={{
                  whiteSpace: "nowrap",
                  color: "#f00",
                  fontWeight: "bold",
                }}
              >
                {"<"} {scoreRange["lowRange"]}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </form>
    </Box>
  );
};

export default Score;
