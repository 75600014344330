import { gantt } from "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/dhtmlxgantt.css";
import moment from "moment";
import React, { useEffect } from "react";

export default function GanttChart({ data, onSprintClick }) {
  useEffect(() => {
    // Set up Gantt chart configuration
    gantt.config.xml_date = "%m-%d-%y %H:%i"; // Updated date format

    gantt.config.columns = [
      { name: "text", label: "Sprint Name", width: "*", align: "left" }, // Updated label to Sprint Name
      {
        name: "start_date",
        label: "Start Date",
        align: "center",
        template: (task) =>
          moment(task.start_date, "YYYY-MM-DD").format("MM-DD-YY"), // Format displayed date
      },
      {
        name: "end_date",
        label: "End Date",
        align: "center",
        template: (task) =>
          moment(task.end_date, "YYYY-MM-DD").format("MM-DD-YY"), // Format displayed date
      },
    ];

    // Set a custom tooltip for tasks
    gantt.templates.tooltip_text = (start, end, task) => {
      return `<b>Sprint Name:</b> ${task.text}<br/>
              <b>Start Date:</b> ${moment(start).format("MM-DD-YY")}<br/>
              <b>End Date:</b> ${moment(end).format("MM-DD-YY")}`;
    };

    // Initialize the Gantt chart
    gantt.init("sprint-gantt-container");

    // Transform the data to match DHTMLX Gantt format
    const tasks = data.map((sprint) => ({
      id: sprint.id,
      text: sprint.name, // Use sprint.name for the task text
      project_id: sprint.project_id,
      start_date: moment(sprint.start_date).format("MM-DD-YY HH:mm"),
      end_date: moment(sprint.end_date).format("MM-DD-YY HH:mm"),
      color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    }));

    // Parse the data into the Gantt chart
    gantt.parse({ data: tasks });

    // Task click event to navigate to the details page
    gantt.attachEvent("onTaskClick", (id) => {
      console.log("Task clicked:", id);
      onSprintClick(id, "sprints");
      return false; // Prevent default behavior
    });

    // Before task delete (e.g., confirmation)
    gantt.attachEvent("onBeforeTaskDelete", async (id, task) => {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/sprints/${id}`;
      const res = await fetch(url, { method: "DELETE" });
      const data = await res.json();
      console.log({ data });
      return true; // Continue with the delete
    });

    gantt.attachEvent("onAfterTaskUpdate", async (id, task) => {
      const url = `${process.env.REACT_APP_BACKEND_URL}/api/sprints/${id}`;
      const res = await fetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: task.text,
          project_id: task.project_id,
          start_date: moment(task.start_date).format("MM-DD-YY"),
          end_date: moment(task.end_date).format("MM-DD-YY"),
        }),
      });
      const data = await res.json();
      console.log({ data });
      return true;
    });

    // Cleanup event listeners when component unmounts
    return () => {
      gantt.clearAll();
    };
  }, [data]);

  return (
    <div
      id="sprint-gantt-container"
      style={{ width: "100%", height: "500px", overflowY: "auto" }}
    />
  );
}
