import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PendingCredentialsListTable from "../../components/dashboard/admin/PendingCredentialsListTable";
import useAuth from "../../hooks/useAuth";
import useCommons from "../../hooks/useCommons";

const RequestedUser = () => {
  const { settings } = useSettings();
  const { allPeopleCount } = useCommons();

  const { getAllUserRequests, userRequests, acceptUserRequest } = useAuth();

  const handleRequestedUser = (pageNum) => {
    getAllUserRequests(pageNum);
  };

  const handleAdminRequest = (uid, uState) => {
    acceptUserRequest(uid, uState);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Requested Users |</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Admin Settings
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                All Pending Requests
              </Typography>
              <Box
                sx={{
                  mt: 0.5,
                }}
              >
                <Typography
                  color="textSecondary"
                  component="span"
                  variant="dashboardInfo"
                >
                  Total People:
                </Typography>
                <Typography
                  component="span"
                  variant="dashboardInfo"
                  sx={{
                    color: "#00000E",
                    pl: 1,
                  }}
                >
                  {allPeopleCount}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <PendingCredentialsListTable
              userRequests={userRequests}
              handleRequestedUser={handleRequestedUser}
              handleAdminRequest={handleAdminRequest}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default RequestedUser;
