import { createSlice } from "@reduxjs/toolkit";
import { getUsersUnderAdmin } from "../api/project/Project";
import { dropdownProjects } from "../api/budget/Budget";
const initialState = {
  userList: [],
  projectDropDown: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserList(state, action) {
      state.userList = action.payload;
    },
    getProjectDropDown(state, action) {
      state.projectDropDown = action.payload;
    },
  },
});

export const { reducer } = slice;

export const getUserList = () => async (dispatch) => {
  const res = await getUsersUnderAdmin();
  dispatch(slice.actions.getUserList(res?.data?.data?.peopleDropdown || []));
};

export const getProjectDropDown = () => async (dispatch) => {
  const res = await dropdownProjects({
    portfolioId: "",
    memberId: "",
  });
  dispatch(
    slice.actions.getProjectDropDown(res?.data?.data?.projectDropdown || [])
  );
};

export default slice;
