import React, { useState } from "react";
import { TableCell, TableRow, Box } from "@material-ui/core";
import AddPreveiousTime from "./AddPreviousTime";
import { Add } from "@material-ui/icons";
import moment from "moment";

const WeekInfo = ({
  singleDayTimeLog,
  weekDates,
  hasRecord,
  week,
  year,
  selectedUser,
  getUserTimeLogHistory,
  startDate,
  endDate,
}) => {
  const [showPreviousTimeModal, setShowPreviousTimeModal] = useState(false);

  const isBetweenStartAndEndDate = (date) => {
    // Return true if the date is equal to or between start date and end date
    const sDate = moment(startDate);
    const eDate = moment(endDate);
    const dt = moment(date);

    console.log();
    // Check if same
    if (sDate.isSame(eDate)) {
      return dt.isSame(sDate) || dt.isSame(eDate);
    }

    return dt.isAfter(sDate) && dt.isBefore(eDate);
  };

  return (
    <>
      {showPreviousTimeModal && (
        <AddPreveiousTime
          showPreviousTimeModal={showPreviousTimeModal}
          setShowPreviousTimeModal={setShowPreviousTimeModal}
          week={week}
          year={year}
          selectedUser={selectedUser}
          getUserTimeLogHistory={getUserTimeLogHistory}
        />
      )}
      <TableRow
        sx={{
          td: {
            borderBottom: hasRecord ? "1px solid #ECF1F9" : "2px solid #C0CBDE",
          },
        }}
      >
        <TableCell>
          <Box sx={{ display: "flex" }}>
            <Box
              component="span"
              sx={{
                p: 1,
                backgroundColor: "#FB6C7E26",
                display: "inherit",
                lineHeight: 0,
                borderRadius: "6px",
              }}
              onClick={() => setShowPreviousTimeModal(true)}
            >
              <Add className="action-icon time-delete" />
            </Box>
          </Box>
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          {singleDayTimeLog.year}-WK{singleDayTimeLog.week}(
          {moment(hasRecord ? weekDates[0].date : weekDates[0]).format(
            "DD-MMM"
          )}{" "}
          -{" "}
          {moment(hasRecord ? weekDates[6].date : weekDates[6]).format(
            "DD-MMM"
          )}
          )
        </TableCell>
        {weekDates.map((singleDayInAWeek) => (
          <TableCell
            sx={{
              opacity: isBetweenStartAndEndDate(singleDayInAWeek.date)
                ? 1
                : 0.5,
            }}
          >
            {hasRecord
              ? singleDayInAWeek.date.split("-")[2]
              : singleDayInAWeek.split("-")[2]}
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </>
  );
};

export default WeekInfo;
