import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { saveProjectOtherDetails } from "../../../../api/project/ProjectOtherDetails";
import useMounted from "../../../../hooks/useMounted";

const ProjectOtherDetails = ({ selectedProject }) => {
  const mounted = useMounted();
  return (
    <Box
      sx={{
        py: 4,
        px: 2,
        boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Typography variant="h6" sx={{ mb: 3 }}>
        PURPOSE OF THE PROJECT
      </Typography>
      <Formik
        initialValues={{
          why_we_are_doing_this_project:
            selectedProject.why_we_are_doing_this_project,
          what_we_need_to_do: selectedProject.what_we_need_to_do,
          scope_and_deliverables: selectedProject.scope_and_deliverables,
          key_success_measures: selectedProject.key_success_measures,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          why_we_are_doing_this_project: Yup.string().nullable(),
          what_we_need_to_do: Yup.string().nullable(),
          scope_and_deliverables: Yup.string().nullable(),
          key_success_measures: Yup.string().nullable(),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const res = await saveProjectOtherDetails(
              selectedProject.id,
              values.why_we_are_doing_this_project,
              values.what_we_need_to_do,
              values.scope_and_deliverables,
              values.key_success_measures
            );

            if (res.data.success) {
              toast.success("Updated Project Details");
            }
            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            toast.error("Something went wrong");
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Why we are doing this project"
                  error={Boolean(
                    touched.why_we_are_doing_this_project &&
                      errors.why_we_are_doing_this_project
                  )}
                  fullWidth
                  helperText={
                    touched.why_we_are_doing_this_project &&
                    errors.why_we_are_doing_this_project
                  }
                  margin="normal"
                  name="why_we_are_doing_this_project"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.why_we_are_doing_this_project}
                  variant="outlined"
                  multiline
                  rows={2}
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="What we need to do"
                  error={Boolean(
                    touched.what_we_need_to_do && errors.what_we_need_to_do
                  )}
                  fullWidth
                  helperText={
                    touched.what_we_need_to_do && errors.what_we_need_to_do
                  }
                  margin="normal"
                  name="what_we_need_to_do"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.what_we_need_to_do}
                  variant="outlined"
                  multiline
                  rows={2}
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Scope and Deliverables"
                  error={Boolean(
                    touched.scope_and_deliverables &&
                      errors.scope_and_deliverables
                  )}
                  fullWidth
                  helperText={
                    touched.scope_and_deliverables &&
                    errors.scope_and_deliverables
                  }
                  margin="normal"
                  name="scope_and_deliverables"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.scope_and_deliverables}
                  variant="outlined"
                  multiline
                  rows={2}
                  sx={{ mt: 0 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Key Success Measures"
                  error={Boolean(
                    touched.key_success_measures && errors.key_success_measures
                  )}
                  fullWidth
                  helperText={
                    touched.key_success_measures && errors.key_success_measures
                  }
                  margin="normal"
                  name="key_success_measures"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.key_success_measures}
                  variant="outlined"
                  multiline
                  rows={2}
                  sx={{ mt: 0 }}
                />
              </Grid>
            </Grid>
            <Box>
              <Button
                color="primary"
                disabled={isSubmitting}
                size="large"
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default ProjectOtherDetails;
