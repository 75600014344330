import React, { useState, useEffect, useRef } from "react";
import {
  getGanttChartData,
  getProjectTasks,
} from "../../../api/project/Project";
import { Typography } from "@material-ui/core";
import AddNewTaskModal from "./AddNewTaskModal";
import useLoader from "../../../hooks/useLoader";
import HighchartsReact from "highcharts-react-official";
// Import Highcharts
import Highcharts from "highcharts";
import highchartsGantt from "highcharts/modules/gantt";
import highchartsDraggablePoints from "highcharts/modules/draggable-points";
import moment from "moment";
import ImportTaskModal from "./ImportTaskModal";
import { useParams } from "react-router-dom";

highchartsGantt(Highcharts);
highchartsDraggablePoints(Highcharts);

const AllTasksGanttChartView = ({
  selectedProject,
  showAddTaskModal,
  setShowAddTaskModal,
  showImportTaskModal,
  setShowImportTaskModal,
  shared,
}) => {
  const [ganttData, setGanttData] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { loaderState, loaderStart, loaderEnd } = useLoader();
  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({
    series: [{ data: [] }],
  });
  const { shareId } = useParams();

  const updateRemoveButtonStatus = (task) => {
    console.log("updateRemoveButtonStatus", task);
  };

  useEffect(async () => {
    loaderStart();
    const res = await getGanttChartData(selectedProject.id, shared, shareId);
    // s
    if (res?.data?.data?.ganttTaskData.length > 0) {
      const ganttData = res?.data?.data?.ganttTaskData;

      const ganttDataWithUnixDate = ganttData.map((obj) => {
        if (obj.x && obj.x2) {
          const x1date = moment(obj.x, "YYYY-MM-DD")
            .format("YYYY-MM-DD")
            .split("-");
          const nextEndDay = moment(obj.x2, "YYYY-MM-DD")
            .add(obj.x == obj.x2 ? 1 : 0, "days")
            .format("YYYY-MM-DD");
          const x2date = nextEndDay.split("-");
          const m1 = +x1date[1] - 1;
          const m2 = +x2date[1] - 1;
          const x = Date.UTC(x1date[0], m1, x1date[2]);
          const x2 = Date.UTC(x2date[0], m2, x2date[2]);

          return { ...obj, x: x, x2: x2 };
        }
        return obj;
      });

      const minDate = moment(selectedProject.start_date, "YYYY-MM-DD")
        .add(-10, "days")
        .format("YYYY-MM-DD")
        .split("-");
      const maxDate = moment(selectedProject.due_date, "YYYY-MM-DD")
        .add(10, "days")
        .format("YYYY-MM-DD")
        .split("-");

      setChartOptions({
        chart: { type: "xrange", panning: true, height: 600 },
        credits: { enabled: false },
        // show week start and end date on x axis highcharts gantt chart for week view only and show week numbers for month view
        xAxis: {
          type: "datetime",
          min: Date.UTC(minDate[0], minDate[1] - 1, minDate[2]),
          max: Date.UTC(maxDate[0], maxDate[1] - 1, maxDate[2]),
          dateTimeLabelFormats: { day: "%e-%b", week: "%e %b %Y", month: "%b" },
          currentDateIndicator: true,
        },
        rangeSelector: {
          enabled: true,
          selected: 1,
          zIndex: 100,
          position: { y: 0 },
          inputPosition: { align: "left" },
          buttonPosition: { align: "right" },
          inputStyle: {
            fontSize: "14px",
            fontWeight: "800",
            color: "#092bb1",
            fill: "#092bb1",
          },
          // Default selected button all
          selected: 3,
          buttons: [
            { type: "month", count: 12, text: "12m" },
            { type: "month", count: 3, text: "3m" },
            { type: "day", count: 21, text: "3w" },
            { type: "all", text: "All" },
          ],
        },
        yAxis: {
          uniqueNames: true,
          min: 0,
          max: 8,
          scrollbar: { enabled: true },
        },

        scrollbar: { enabled: true, liveRedraw: true },
        plotOptions: {
          series: {
            minPointLength: 5, // Always show points, even when resized down
            dragDrop: {
              draggableX: true,
              draggableY: true,
              dragMinY: 2,
              dragMaxY: 2,
              liveRedraw: false,
              dragPrecisionX: 8,
            },
            allowPointSelect: true,
            point: {
              events: {
                select: () => {
                  console.log("select");
                  updateRemoveButtonStatus();
                },
                unselect: () => {
                  console.log("unselect");
                  updateRemoveButtonStatus();
                },
                remove: () => {
                  console.log("remove");
                  updateRemoveButtonStatus();
                },
                drop(event) {
                  console.log("drop");
                  console.log(this, event);
                },
                drag(event) {
                  console.log("drag");
                  console.log(this, event);
                },
              },
            },
          },
        },

        tooltip: {
          formatter: function () {
            const dt1 = new Date(this.x);
            const dt2 = new Date(this.x2);
            dt2.setDate(dt2.getDate() - (this.x == this.x2 ? 1 : 0));

            return `<b>Name: ${
              this.key
            }</b><br/>Start Date: <b>${dt1.getDate()}-${
              dt1.getMonth() === 0 ? 12 : dt1.getMonth() + 1
            }-${dt1.getFullYear()}</b><br/>End Date: <b>${dt2.getDate()}-${
              dt2.getMonth() === 0 ? 12 : dt2.getMonth() + 1
            }-${dt2.getFullYear()}</b><br/>
        `;
          },
        },

        // yAxis: {
        //   labels: {
        //     formatter: function () {
        //       console.log(this);
        //       return `<b>${this.value}</b>`;
        //     },
        //   },
        // },
        series: [{ data: ganttDataWithUnixDate, cursor: "move" }],
      });
      setGanttData(ganttDataWithUnixDate);
    }
    loaderEnd();
  }, []);

  useEffect(() => {
    getTaskList();
  }, []);

  const getTaskList = async () => {
    loaderStart();
    const res = await getProjectTasks({
      projectId: selectedProject.id,
      page: currentPage,
      shared,
      authToken: shareId,
    });
    if (res.data.success) {
      setAllTasks(res.data.data.tasks);
    }
    loaderEnd();
  };

  return (
    <div>
      {showImportTaskModal && (
        <ImportTaskModal
          selectedProject={selectedProject}
          showImportTaskModal={showImportTaskModal}
          setShowImportTaskModal={setShowImportTaskModal}
          onSuccess={getTaskList}
        />
      )}
      {showAddTaskModal && (
        <AddNewTaskModal
          selectedProject={selectedProject}
          showAddTaskModal={showAddTaskModal}
          setShowAddTaskModal={setShowAddTaskModal}
          allTasks={allTasks}
          getTaskList={getTaskList}
        />
      )}
      {ganttData?.length > 0 ? (
        // <FrappeGantt
        //   tasks={ganttData}
        //   viewMode="Day"
        //   onClick={(task) => console.log(task)}
        //   onDateChange={(task, start, end) => console.log(task, start, end)}
        //   onProgressChange={(task, progress) => console.log(task, progress)}
        //   onTasksChange={(tasks) => console.log(tasks)}
        // />
        <HighchartsReact
          constructorType="ganttChart"
          highcharts={Highcharts}
          options={chartOptions}
          ref={chartRef}
        />
      ) : (
        <Typography align="center" sx={{ p: 4 }} variant="h6" color="secondary">
          {loaderState ? "Loading..." : "Add task to see the gantt chart"}
        </Typography>
      )}
    </div>
  );
};

export default AllTasksGanttChartView;
