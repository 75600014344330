import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { getAllCompanyScores } from "../../api/project/Score";
// ÷Need to redirect user to add score page if no scores are found
import { useNavigate } from "react-router-dom";

const convertToNumber = (value) => {
  if (value === "") {
    return 0;
  }
  return Number(value);
};

const calculateReward = (score) => {
  const total =
    convertToNumber(score.revenue_increasing) +
    convertToNumber(score.strategic_fit) +
    convertToNumber(score.mission_criticality) +
    convertToNumber(score.cost_reduction);
  return total;
};
const calculateActualReward = (score) => {
  const total =
    convertToNumber(score.actual_revenue_increasing) +
    convertToNumber(score.actual_strategic_fit) +
    convertToNumber(score.actual_mission_criticality) +
    convertToNumber(score.actual_cost_reduction);
  return total;
};

const calCulateRisk = (score) => {
  const totalRiskScore =
    convertToNumber(score.project_cost) +
    convertToNumber(score.business_risks) +
    convertToNumber(score.tech_risks);
  return totalRiskScore;
};
const calCulateActualRisk = (score) => {
  const totalRiskScore =
    convertToNumber(score.actual_project_cost) +
    convertToNumber(score.actual_business_risks) +
    convertToNumber(score.actual_tech_risks);
  return totalRiskScore;
};

const ViewAllScoresListTable = () => {
  const [allScores, setAllScores] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllScores();
  }, []);

  const getAllScores = async () => {
    const res = await getAllCompanyScores();
    console.log(res?.data.data);
    if (res?.data?.data?.scores?.length === 0) {
      console.log("No scores found");
      navigate("/dashboard/add-score");
    }
    setAllScores(res?.data?.data?.scores);
  };
  return (
    <Card>
      <Box sx={{ pt: 3 }}>
        <Table>
          <TableHead sx={{ th: { fontSize: "12px" } }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell
                sx={{
                  borderRight: "2px solid #000",
                }}
              >
                Forecast
              </TableCell>
              <TableCell
                sx={{
                  pl: 8,
                }}
              >
                Actual
              </TableCell>
              <TableCell></TableCell>
              <TableCell> </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableHead sx={{ th: { fontSize: "12px" } }}>
            <TableRow>
              <TableCell sx={{ px: 2 }}>#</TableCell>
              <TableCell>Project Name</TableCell>
              <TableCell>Reward</TableCell>
              <TableCell>Risk</TableCell>
              <TableCell
                sx={{
                  borderRight: "2px solid #000",
                }}
              >
                {" "}
                Score
              </TableCell>
              <TableCell
                sx={{
                  pl: 8,
                }}
              >
                {" "}
                Reward
              </TableCell>
              <TableCell>Risk</TableCell>
              <TableCell> Score</TableCell>

              <TableCell>Visit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allScores.length > 0 ? (
              allScores.map((score, index) => (
                <TableRow hover key={score.id}>
                  <TableCell sx={{ px: 2 }}>{index + 1}</TableCell>
                  <TableCell>{score.project_name}</TableCell>
                  <TableCell>{calculateReward(score)}</TableCell>
                  <TableCell>{calCulateRisk(score)}</TableCell>
                  <TableCell
                    sx={{
                      borderRight: "2px solid #000",
                    }}
                  >
                    {calculateReward(score) - calCulateRisk(score)}
                  </TableCell>
                  <TableCell
                    sx={{
                      pl: 8,
                    }}
                  >
                    {calculateActualReward(score)}
                  </TableCell>
                  <TableCell>{calCulateActualRisk(score)}</TableCell>
                  <TableCell>
                    {calculateActualReward(score) - calCulateActualRisk(score)}
                  </TableCell>
                  <TableCell>
                    <Link to={`/dashboard/score/${score.id}`}>Visit</Link>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No scores found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

ViewAllScoresListTable.propTypes = {
  invoices: PropTypes.array.isRequired,
};

export default ViewAllScoresListTable;
