import { Box, Grid, TableBody, TableCell, Typography } from "@material-ui/core";
import React from "react";
import { Draggable } from "react-beautiful-dnd";

const SingleTask = ({ selectedProject, getTaskList, item, i }) => {
  return (
    <>
      <Draggable
        draggableId={item.id ? `m#${item.id}` : `nomilestone#${i}`}
        index={i}
      >
        {(provided, snapshot) => (
          <Box>
            <Box
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={{
                ...provided.draggableProps.style,
                boxShadow: snapshot.isDragging ? "0 0 0.6rem #999" : "none",
                background: snapshot.isDragging ? "#3dc33333" : "none",
              }}
            >
              <Grid
                container
                {...provided.dragHandleProps}
                sx={{
                  borderBottom: "1px solid #ddd",
                  py: 1,
                  px: 1,
                }}
              >
                <Grid item xs={8} {...provided.dragHandleProps}>
                  <Typography sx={{ width: "85%" }}>{item?.name}</Typography>
                </Grid>
                <Grid item xs={2} {...provided.dragHandleProps}>
                  <Typography>{item.start_date}</Typography>
                </Grid>
                <Grid item xs={2} {...provided.dragHandleProps}>
                  <Typography>{item.end_date}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Draggable>
    </>
  );
};

export default SingleTask;
