import React from "react";
import useTable from "../../../hooks/useTable";
import {
  Box,
  Link,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ScheduleAction from "./ScheduleAction";

const ViewAllSchedules = ({ schedules, userView }) => {
  const headCells = userView
    ? [
        { id: "proj_name", label: "PROJECT" },
        { id: "estimate_hours ", label: "ESTIMATE TIME" },
        { id: "proj_status", label: "STATUS" },
        { id: "tasks_in_hand", label: "TASK COUNT" },
        { id: "action", label: "ACTION", align: "right", disableSorting: true },
      ]
    : [
        { id: "member_name", label: "NAME" },
        { id: "total_utilize", label: "UTILIZATION" },
        { id: "estimate_hours ", label: "ESTIMATE TIME" },
        { id: "total_project", label: "PROJECT COUNT" },
        { id: "tasks_in_hand", label: "TASK COUNT" },
        { id: "member_available", label: "AVAILABILITY" },
        { id: "action", label: "ACTION", align: "right", disableSorting: true },
      ];

  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    schedules?.data,
    headCells
  );
  return (
    <Box>
      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((schedule, index) => {
            return (
              <>
                {schedules?.data?.length > 0 ? (
                  <TableRow hover key={index}>
                    {!userView && (
                      <TableCell>
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          to={`/dashboard/scheduling/member/${schedule.user_id}`}
                          underline="none"
                          variant="subtitle2"
                        >
                          {schedule.member_name}
                        </Link>
                      </TableCell>
                    )}
                    {userView && (
                      <TableCell>
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          to={`/dashboard/projects/view-and-edit-project/${schedule.proj_id}`}
                          underline="none"
                          variant="subtitle2"
                          className="redirect-link"
                        >
                          {schedule.proj_name}
                        </Link>
                      </TableCell>
                    )}
                    {!userView && (
                      <TableCell
                        sx={{
                          color:
                            schedule.total_utilize >= 100 ? "#f00" : "#2D3E56",
                        }}
                      >
                        {schedule.total_utilize} %
                      </TableCell>
                    )}
                    <TableCell>{schedule.estimate_hours}</TableCell>
                    {userView && <TableCell>{schedule.proj_status}</TableCell>}
                    {!userView && (
                      <TableCell>{schedule.total_project}</TableCell>
                    )}
                    <TableCell>{schedule.tasks_in_hand}</TableCell>
                    {!userView && (
                      <TableCell
                        sx={{
                          color:
                            schedule.member_available === 0
                              ? "#f00"
                              : "#2D3E56",
                        }}
                      >
                        {schedule.member_available === 0
                          ? "Over Assigned"
                          : `${schedule.member_available} %`}
                      </TableCell>
                    )}
                    <TableCell align="right" sx={{ p: 1 }}>
                      <ScheduleAction
                        userId={schedule.user_id}
                        projectId={schedule.proj_id}
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  <Typography variant="h6" color="secondary">
                    No Data Available
                  </Typography>
                )}
              </>
            );
          })}
        </TableBody>
      </TblContainer>
    </Box>
  );
};

export default ViewAllSchedules;
