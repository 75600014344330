import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import { Provider as ReduxProvider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";
import { AuthProvider } from "./contexts/JWTContext";
import { PrtfolioProvider } from "./contexts/PortfolioContext";
import { ProjectProvider } from "./contexts/ProjectContext";
import { SettingsProvider } from "./contexts/SettingsContext";
import { LoaderProvider } from "./contexts/LoaderContext";
import { CommonProvider } from "./contexts/Common";
import store from "./store";
ReactDOM.render(
  <>
    <HelmetProvider>
      <ReduxProvider store={store}>
        <StyledEngineProvider injectFirst>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <LoaderProvider>
                  <AuthProvider>
                    <CommonProvider>
                      <PrtfolioProvider>
                        <ProjectProvider>
                          <App />
                        </ProjectProvider>
                      </PrtfolioProvider>
                    </CommonProvider>
                  </AuthProvider>
                </LoaderProvider>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </StyledEngineProvider>
      </ReduxProvider>
    </HelmetProvider>
  </>,
  document.getElementById("root")
);
