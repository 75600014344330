import React from "react";
import { TableRow, TableCell, Button } from "@material-ui/core";
import toast from "react-hot-toast";
import moment from "moment/moment";

const SingleArchiveRow = ({ item, onSuccess, onDelete, type }) => {
  const restore = async (id) => {
    const res = await onDelete(item.id);
    if (res.data) {
      toast.success("Restore successfully");
      onSuccess();
    }
  };
  return (
    <TableRow key={item.id}>
      <TableCell>
        {type === "user" ? `${item.first_name} ${item.last_name}` : item.name}
      </TableCell>
      {type === "task" && (
        <>
          <TableCell>{item.project_name}</TableCell>
          <TableCell>{item.milestone_name}</TableCell>
        </>
      )}
      {type === "milestone" && (
        <>
          <TableCell>{item.project_name}</TableCell>
        </>
      )}
      <TableCell
        sx={{
          width: "10%",
        }}
      >
        {moment(item.deleted_at).format("MM-DD-YYYY")}
      </TableCell>
      <TableCell
        sx={{
          width: "10%",
        }}
      >
        <Button onClick={restore}>Restore</Button>
      </TableCell>
    </TableRow>
  );
};

export default SingleArchiveRow;
