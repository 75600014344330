import React, { useState } from "react";
import {
  FormHelperText,
  TextField,
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { Formik } from "formik";
import useAuth from "../../../hooks/useAuth";
import { addDocumentToAWS } from "../../../api/project/Project";
import FileUploader from "../../FileUploader";
import toast from "react-hot-toast";
import useLoader from "../../../hooks/useLoader";
const AddPhysicalDocument = ({
  selectedProject,
  setShowAddPhysicalDoumentModal,
  showAddPhysicalDoumentModal,
  getProjectDocuments,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const { user } = useAuth();
  const { loaderStart, loaderEnd } = useLoader();
  return (
    <>
      <Dialog
        open={showAddPhysicalDoumentModal}
        onClose={() => setShowAddPhysicalDoumentModal(false)}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <Formik
          initialValues={{
            submit: null,
          }}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            loaderStart();
            let data = new FormData();
            data.append("document", selectedFile);
            data.append("proj_id", selectedProject.id);
            data.append("uploaded_by", user.id);
            data.append(
              "doc_extension",
              selectedFile?.name.split(".").pop() || "unknown"
            );
            try {
              const res = await addDocumentToAWS(data);

              if (res.data.success) {
                toast.success("Document Uploaded Successfully");
                getProjectDocuments();
                setShowAddPhysicalDoumentModal(false);
              }
              setStatus({ success: true });
              setSubmitting(false);
              loaderEnd();
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              loaderEnd();
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>Add New Document</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FileUploader
                      onFileSelectSuccess={(file) => setSelectedFile(file)}
                      onFileSelectError={({ error }) => alert(error)}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={!selectedFile}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowAddPhysicalDoumentModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddPhysicalDocument;
