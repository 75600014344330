import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { dropdownProjects } from "../../../api/budget/Budget";
import { addNewTimeEntry, taskDropDown } from "../../../api/time/Time";
import useAuth from "../../../hooks/useAuth";
import useMounted from "../../../hooks/useMounted";
const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddPreveiousTime = ({
  showPreviousTimeModal,
  setShowPreviousTimeModal,
  selectedUser,
  getUserTimeLogHistory,
  week,
  year,
}) => {
  const [days, setDays] = useState([]);
  const [currentWeek, setCurrentWeek] = useState({
    week: week,
    year: year,
  });
  const [allProjects, setAllProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [allProjectTasks, setAllProjectTasks] = useState([]);
  const mounted = useMounted();
  const { user } = useAuth();
  const classes = useStyles();

  useEffect(() => {
    generateWeekDates(week, year);
  }, []);

  useEffect(() => {
    if (days.length > 0) {
      const startDate = moment(days[0], "YYYY-MM-DD").format("YYYY-MM-DD");
      // add 6 days to start date
      const endDate = moment(startDate).add(6, "days").format("YYYY-MM-DD");
      console.log("startDate", startDate);
      console.log("endDate", endDate);
      getDropdownProjects(startDate, endDate);
    }
  }, [days]);

  useEffect(() => {
    if (selectedProject) {
      const startDate = moment(days[0], "YYYY-MM-DD").format("YYYY-MM-DD");
      const endDate = moment(startDate).add(6, "days").format("YYYY-MM-DD");
      getDropdownTasks(startDate, endDate);
    }
  }, [selectedProject]);

  const generateWeekDates = (weekNumber, year) => {
    const weekArr = [];
    const weekStart = moment()
      .isoWeek(weekNumber)
      .year(year)
      .startOf("isoWeek");
    for (var i = 0; i <= 6; i++) {
      const newDate = moment(weekStart).add(i, "days").format("YYYY-MM-DD");
      weekArr.push(newDate);
    }
    setDays(weekArr);
  };

  const getDropdownProjects = async (startDate, endDate) => {
    const res = await dropdownProjects({
      adminId: user.id,
      portfolioId: "",
      memberId: selectedUser.id,
      startDate: startDate,
      endDate: endDate,
    });
    if (res.data.success) {
      setAllProjects(res.data.data.projectDropdown);
    }
  };

  const getDropdownTasks = async (startDate, endDate) => {
    const res = await taskDropDown({
      userId: selectedUser.id,
      projectId: selectedProject,
      startDate: startDate,
      endDate: endDate,
    });
    if (res.data.success) {
      setAllProjectTasks(res.data.data.userTasks);
    }
  };

  return (
    <>
      <Dialog
        open={showPreviousTimeModal}
        onClose={() => setShowPreviousTimeModal(false)}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <Formik
          initialValues={{
            project: "",
            description: "",
            task: "",
            billable: "",
            isOveride: false,
            overrideRate: "",
            day1: 0,
            day2: 0,
            day3: 0,
            day4: 0,
            day5: 0,
            day6: 0,
            day7: 0,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            project: Yup.string().max(255).required("Project is required"),
            task: Yup.string().max(255).required("Task is required"),
            description: Yup.string()
              .max(255)
              .required("Description is required"),
            day1: Yup.number().max(24).nullable(),
            day2: Yup.number().max(24).nullable(),
            day3: Yup.number().max(24).nullable(),
            day4: Yup.number().max(24).nullable(),
            day5: Yup.number().max(24).nullable(),
            day6: Yup.number().max(24).nullable(),
            day7: Yup.number().max(24).nullable(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const res = await addNewTimeEntry({
                userId: selectedUser.id,
                adminId: user.id,
                project: values.project,
                task: values.task,
                billable: values.billable ? 1 : 0,
                overrideRate: values.overrideRate,
                description: values.description,
                weekCode: `${currentWeek.year}-${currentWeek.week}`,
                logDates: days.join(","),
                logTimes: `${values.day1},${values.day2},${values.day3},${values.day4},${values.day5},${values.day6},${values.day7}`,
                totalTime:
                  values.day1 +
                  values.day2 +
                  values.day3 +
                  values.day4 +
                  values.day5 +
                  values.day6 +
                  values.day7,
              });

              if (res.data.success) {
                toast.success("Time Added successfully");
                getUserTimeLogHistory();
                setShowPreviousTimeModal(false);
              }

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <DialogTitle>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Add New Time </Typography>
                  <Typography variant="h6">
                    Selected User:{" "}
                    <Typography
                      component="span"
                      sx={{
                        color: "#F7B31E",
                        fontWeight: "bold",
                      }}
                    >{`${selectedUser.first_name}  ${selectedUser.last_name}`}</Typography>
                  </Typography>
                </Box>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Box sx={{ width: "100%" }}>
                        <FormControl className={classes.formControl} required>
                          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                            Select Project
                          </InputLabel>
                          <Select
                            labelId="select-status"
                            label="SELECT PROJECT"
                            name="project"
                            value={values.project}
                            fullWidth
                            onChange={(e) => {
                              handleChange(e);
                              setSelectedProject(e.target.value);
                            }}
                          >
                            <MenuItem value="">none</MenuItem>
                            {allProjects?.length > 0 &&
                              allProjects.map((proj) => (
                                <MenuItem key={proj.id} value={proj.id}>
                                  {proj.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <Box sx={{ width: "100%" }}>
                        <FormControl className={classes.formControl} required>
                          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                            Task{" "}
                          </InputLabel>
                          <Select
                            labelId="select-status"
                            label="task *"
                            name="task"
                            value={values.task}
                            error={Boolean(touched.task && errors.task)}
                            fullWidth
                            helperText={touched.task && errors.task}
                            placeholder="task"
                            onBlur={handleBlur}
                            onChange={handleChange}
                          >
                            <MenuItem value="">none</MenuItem>
                            {allProjectTasks?.length > 0 &&
                              allProjectTasks.map((task) => (
                                <MenuItem key={task.id} value={task.id}>
                                  {task.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          ml: -1,
                          mt: 2,
                        }}
                      >
                        <Checkbox
                          checked={values.billable}
                          color="primary"
                          name="billable"
                          onChange={handleChange}
                        />
                        <Typography color="textSecondary" variant="body2">
                          Billable
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} spacing={2}>
                    <Grid item xs={12} sx={{ mt: 2 }}>
                      <TextField
                        label="Description"
                        required
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        fullWidth
                        helperText={touched.description && errors.description}
                        margin="normal"
                        name="description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        variant="outlined"
                        sx={{ mt: 0 }}
                        multiline
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          ml: -1,
                          mt: 2,
                        }}
                      >
                        <Checkbox
                          checked={values.isOveride}
                          color="primary"
                          name="isOveride"
                          onChange={handleChange}
                        />
                        <Typography color="textSecondary" variant="body2">
                          Overide Rate
                        </Typography>
                        {values.isOveride && (
                          <TextField
                            label="Override Rate"
                            required
                            error={Boolean(
                              touched.overrideRate && errors.overrideRate
                            )}
                            fullWidth
                            helperText={
                              touched.overrideRate && errors.overrideRate
                            }
                            margin="normal"
                            name="overrideRate"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.overrideRate}
                            variant="outlined"
                            sx={{ mt: 0, ml: 2 }}
                          />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} sx={{ mt: 2 }}>
                    <Grid xs={12} sm={6} item md={1.5}>
                      <Typography color="textSecondary" variant="body2">
                        MON, {days[0]?.split("-")[2]}
                      </Typography>
                      <TextField
                        required
                        error={Boolean(touched.day1 && errors.day1)}
                        fullWidth
                        helperText={touched.day1 && errors.day1}
                        margin="normal"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.day1}
                        type="number"
                        variant="outlined"
                        name="day1"
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item md={1.5}>
                      <Typography color="textSecondary" variant="body2">
                        TUE,{days[1]?.split("-")[2]}
                      </Typography>
                      <TextField
                        required
                        error={Boolean(touched.day2 && errors.day2)}
                        fullWidth
                        helperText={touched.day2 && errors.day2}
                        margin="normal"
                        name="day2"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.day2}
                        type="number"
                        variant="outlined"
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item md={1.5}>
                      <Typography color="textSecondary" variant="body2">
                        WED,{days[2]?.split("-")[2]}
                      </Typography>
                      <TextField
                        required
                        error={Boolean(touched.day3 && errors.day3)}
                        fullWidth
                        helperText={touched.day3 && errors.day3}
                        margin="normal"
                        name="day3"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.day3}
                        type="number"
                        variant="outlined"
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item md={1.5}>
                      <Typography color="textSecondary" variant="body2">
                        THU, {days[3]?.split("-")[2]}
                      </Typography>
                      <TextField
                        required
                        error={Boolean(touched.day4 && errors.day4)}
                        fullWidth
                        helperText={touched.day4 && errors.day4}
                        margin="normal"
                        name="day4"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.day4}
                        type="number"
                        variant="outlined"
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item md={1.5}>
                      <Typography color="textSecondary" variant="body2">
                        FRI, {days[4]?.split("-")[2]}
                      </Typography>
                      <TextField
                        required
                        error={Boolean(touched.day5 && errors.day5)}
                        fullWidth
                        helperText={touched.day5 && errors.day5}
                        margin="normal"
                        name="day5"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.day5}
                        type="number"
                        variant="outlined"
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item md={1.5}>
                      <Typography color="textSecondary" variant="body2">
                        SAT, {days[5]?.split("-")[2]}
                      </Typography>
                      <TextField
                        required
                        error={Boolean(touched.day6 && errors.day6)}
                        fullWidth
                        helperText={touched.day6 && errors.day6}
                        margin="normal"
                        name="day6"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.day6}
                        type="number"
                        variant="outlined"
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item md={1.5}>
                      <Typography color="textSecondary" variant="body2">
                        SUN, {days[6]?.split("-")[2]}
                      </Typography>
                      <TextField
                        required
                        error={Boolean(touched.day7 && errors.day7)}
                        fullWidth
                        helperText={touched.day7 && errors.day7}
                        margin="normal"
                        name="day7"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.day7}
                        type="number"
                        variant="outlined"
                        sx={{ mt: 1 }}
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item md={1.5}>
                      <Typography
                        color="primary"
                        variant="body2"
                        sx={{ fontWeight: "bold" }}
                      >
                        TOTAL
                      </Typography>
                      <TextField
                        disabled
                        fullWidth
                        margin="normal"
                        name="total"
                        value={
                          values.day1 +
                          values.day2 +
                          values.day3 +
                          values.day4 +
                          values.day5 +
                          values.day6 +
                          values.day7
                        }
                        variant="outlined"
                        sx={{
                          mt: 1,
                          userSelect: "none",
                          input: {
                            color: "#F7B31E !important",
                            fontWeight: "bold",
                            userSelect: "none",
                            "-webkit-text-fill-color": "#F7B31E !important",
                          },
                          ".MuiOutlinedInput-notchedOutline": {
                            borderColor: "#F7B31E !important",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowPreviousTimeModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddPreveiousTime;

// var cuWeekNum = moment("26-12-2022", "DD-MM-YYYY").week();
// console.log(cuWeekNum);

// var cuWeekNum = moment().week();
// console.log("cuWeekNum", cuWeekNum);
