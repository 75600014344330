import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { projectTaskGraph } from "../../../../api/project/ProjectDashboard";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const TaskGraph = ({ selectedProject, authToken = null }) => {
  const [options, setOptions] = useState({
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "",
    },
    tooltip: {
      pointFormat: "<b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %",
        },
      },
    },
    series: [],
  });

  useEffect(() => {
    getGraph();
  }, []);

  const getGraph = async () => {
    const res = await projectTaskGraph(selectedProject, authToken);
    if (res?.data?.data?.values) {
      setOptions({
        ...options,
        series: [{ name: "", colorByPoint: true, data: res.data.data.values }],
      });
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default TaskGraph;
