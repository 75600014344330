import { useState, memo, useCallback, useEffect } from "react";
import MainNavbar from "./MainNavbar";
import MainSidebar from "./MainSidebar";
import { useMediaQuery } from "@material-ui/core";

const ConstantLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(true);

  useEffect(() => {
    const mainContent = document.querySelector("#main-wrapper");
    if (mainContent) {
      mainContent.style.paddingLeft = !isSidebarMobileOpen ? "240px" : "20px";
    }
  }, [isSidebarMobileOpen]);

  const updateSidebar = useCallback(() => {
    setIsSidebarMobileOpen((prevState) => !prevState);
  }, []);

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  useEffect(() => {
    if (isSmallScreen) updateSidebar();
  }, [isSmallScreen]);
  return (
    <>
      <MainNavbar onSidebarMobileOpen={updateSidebar} />
      <MainSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        onSidebarMobileOpen={updateSidebar}
      />
    </>
  );
};

export default memo(ConstantLayout);
