import { Switch, TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  updateCompanyAccess,
  updateCompanyPaymentStatus,
} from "../../../api/user/User";
import PencilAltIcon from "../../../icons/PencilAlt";
import CompanyDetailsAddModal from "./CompanyDetailsAddModal";
const SingleCompanyAccessInfo = ({ companyDetails, getCompanies }) => {
  const [showEditTaskModal, setShowEditTaskModal] = useState(false);

  const handleCompanyAccess = async () => {
    const reportAccess = companyDetails.custom_report === 1 ? 0 : 1;
    const res = await updateCompanyAccess(companyDetails.id, reportAccess);
    if (res?.data?.success) {
      toast.success("Company access updated");
      getCompanies();
    }
  };

  const handleCompanyPaymentStatus = async () => {
    const hasMadePayment = companyDetails.current_state === 1 ? 0 : 1;
    const res = await updateCompanyPaymentStatus(
      companyDetails.id,
      hasMadePayment
    );
    if (res?.data?.success) {
      toast.success("Company state updated");
      getCompanies();
    }
  };

  return (
    <>
      <CompanyDetailsAddModal
        showEditTaskModal={showEditTaskModal}
        setShowEditTaskModal={setShowEditTaskModal}
        companyDetails={companyDetails}
        getCompanies={getCompanies}
      />
      <TableRow hover>
        <TableCell>{companyDetails.company_name}</TableCell>
        <TableCell>{companyDetails.code}</TableCell>
        <TableCell>{companyDetails.valid_from_date || "-"}</TableCell>
        <TableCell>{companyDetails.valid_to_date || "-"}</TableCell>
        <TableCell>
          {companyDetails.current_status === 1
            ? "Subscribed"
            : companyDetails.current_status === 2
            ? "On trial"
            : companyDetails.current_status === 3
            ? "POC"
            : "No Subscription"}
        </TableCell>
        <TableCell>
          <Switch
            color="primary"
            edge="start"
            name="direction"
            checked={companyDetails.current_state !== 0}
            onClick={handleCompanyPaymentStatus}
          />
        </TableCell>
        <TableCell>
          <Switch
            color="primary"
            edge="start"
            name="direction"
            checked={companyDetails.custom_report !== 0}
            onClick={handleCompanyAccess}
          />
        </TableCell>
        <TableCell>{companyDetails.subscription_notes || "-"}</TableCell>
        <TableCell align="center">
          <PencilAltIcon
            onClick={() => setShowEditTaskModal(true)}
            className="action-icon"
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleCompanyAccessInfo;
