import { AppBar, Toolbar } from "@material-ui/core";
import { experimentalStyled } from "@material-ui/core/styles";
import Logo from "./Logo";
const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === "light" && {
    backgroundColor: theme.palette.background.default,
    boxShadow: "0px 2px 5px rgba(45, 62, 86, 0.08)",
    color: theme.palette.text.primary,
  }),
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
  }),
  zIndex: theme.zIndex.drawer + 100,
}));
const SharedNav = () => {
  return (
    <DashboardNavbarRoot>
      <Toolbar sx={{ minHeight: 64 }}>
        <Logo
          sx={{
            display: {
              lg: "inline",
              xs: "none",
            },
            height: 40,
            width: 40,
          }}
        />
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default SharedNav;
