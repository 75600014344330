import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { Add, ChevronRight } from "@material-ui/icons";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import PermissionGuard from "../components/PermissionGuard";
import useSettings from "../hooks/useSettings";

export default function PageLayout(props) {
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>{props?.title || "Projectric"}</title>
      </Helmet>
      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 2 }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          {/* Page breadcrumb details */}
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs separator={<ChevronRight fontSize="small" />}>
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  {props?.currentPageName}
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                {props?.currentPageName}
              </Typography>
              <Box sx={{ mb: 1 }}>
                <Typography
                  color="textPrimary"
                  component="span"
                  variant="dashboardInfo"
                >
                  {props?.subtitle}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <PermissionGuard
                permissionCheck="PORTFOLIO_CREATE"
                returnEmpty={true}
              >
                <Box sx={{ m: -1 }}>
                  <Button
                    color="primary"
                    startIcon={<Add fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    component={RouterLink}
                    to="/dashboard/portfolios/add-new-portfolio"
                  >
                    ADD NEW PORTFOLIO
                  </Button>
                </Box>
              </PermissionGuard>
            </Grid>
          </Grid>
          {props.children}
        </Container>
      </Box>
    </>
  );
}
