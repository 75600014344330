import { handleApiRequest } from "../../hooks/callApi";

export const addStage = async (stages) => {
  const body = {
    stages: stages,
  };
  const res = await handleApiRequest(`/api/project-stage/update`, "post", body);

  return res;
};

export const getStage = async () => {
  const res = await handleApiRequest(`/api/project-stage`, "get");

  return res;
};
