import { Box } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import AddComment from './AddComment'
import ProjectComments from './ProjectComments'
import { getAllComments } from '../../../../api/project/Project';

const Comment = ({projectId}) => {
    const [allComments, setAllComments] = useState([]);

  useEffect(() => {
    getComments();
  }, []);

  const getComments = async () => {
    try {
      const res = await getAllComments(projectId);
      if (res.data.success) {
        setAllComments(res.data.data.items);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Box sx={{mt: 8, mb: 3,p:2, backgroundColor: '#fbfbfb', boxShadow: "1px 6px 13px 3px #00000021"}}>
        <ProjectComments allComments={allComments} projectId={projectId} />
        <AddComment projectId={projectId} getComments={getComments} />
    </Box>
  )
}

export default Comment