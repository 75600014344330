import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getPeopleUtilization } from "../../../../api/project/ProjectDashboard";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useParams } from "react-router-dom";

const PeopleUtilization = ({ selectedProject, shared }) => {
  const [memberUtilization, setMemberUtilization] = useState([]);
  const { shareId } = useParams();

  useEffect(() => {
    getUtilization();
  }, []);

  const getUtilization = async () => {
    const res = await getPeopleUtilization(selectedProject.id, shared, shareId);
    console.log(res);
    if (res.data.data) {
      setMemberUtilization(res.data.data.values);
    }
  };

  return (
    <>
      {Object.keys(memberUtilization).length ? (
        <Table
          sx={{
            th: {
              padding: "8px 10px",
              color: "#222 !important",
            },
            td: {
              padding: "8px 10px",
              color: "#444 !important",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              {/* <TableCell>Daily Hours</TableCell> */}
              {/* <TableCell>Utilization</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(memberUtilization).map((key, index) => (
              <SingleUtilizationComponent
                key={key}
                member={memberUtilization[key]}
              />
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="h6" sx={{ textAlign: "center", py: 2 }}>
          None of the members have been assigned to any task today.
        </Typography>
      )}
    </>
  );
};

const SingleUtilizationComponent = ({ member }) => {
  const [showChildren, setShowChildren] = useState(false);
  return (
    <>
      <TableRow>
        <TableCell
          onClick={() => setShowChildren((x) => !x)}
          sx={{ cursor: "pointer" }}
        >
          {showChildren ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </TableCell>
        <TableCell
          sx={{
            width: "80%",
          }}
        >
          {member.first_name} {member.last_name}
        </TableCell>
        {/* <TableCell>{member.time_on_proj}</TableCell> */}
        {/* <TableCell>
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              variant="determinate"
              value={member.utilizationPercentage}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >
                {`${member.utilizationPercentage}%`}
              </Typography>
            </Box>
          </Box>
        </TableCell> */}
      </TableRow>
      {showChildren && (
        <TableRow>
          <TableCell colSpan={4}>
            <Table
              sx={{
                th: {
                  padding: "8px 0px",
                  color: "#8e8b8b !important",
                },
                td: {
                  padding: "8px 0px",
                  color: "#8e8b8b !important",
                },
              }}
            >
              {member.child.length > 0 ? (
                <>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Task</TableCell>
                      <TableCell>Estimated Hours</TableCell>
                      <TableCell>Actual Hours</TableCell>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {member.child.map((task) => (
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>{task.task_name}</TableCell>
                        <TableCell>{task.estimate_hours}</TableCell>
                        <TableCell>{task.actual_hours}</TableCell>
                        <TableCell>{task.start_date}</TableCell>
                        <TableCell>{task.end_date}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              ) : (
                <Typography variant="h6" sx={{ textAlign: "center", py: 2 }}>
                  Member has not been assigned to any tasks
                </Typography>
              )}
            </Table>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default PeopleUtilization;
