export const getPhoneNumber = (num) => {
  let number = num.toString();

  const phone = number.replace(/[^0-9]/g, "");

  if (phone.toString().length === 0) {
    return "";
  }

  return +phone;
};
