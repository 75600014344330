import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSharedTaskList } from "../../api/project/ProjectDashboard";
import { getProjectID } from "../../api/share/Share";
import SharedNav from "../../components/SharedNav";

const SharedTaskList = () => {
  const { shareId } = useParams();
  const [projectDetails, setProjectDetails] = useState(null);
  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    handleGetProjectId();
  }, []);

  useEffect(() => {
    if (projectDetails?.id) {
      getGraph();
    }
  }, [projectDetails]);

  const handleGetProjectId = async () => {
    const res = await getProjectID(shareId);
    if (res.data.data.item) {
      setProjectDetails(res.data.data.item);
    }
  };

  const getGraph = async () => {
    const res = await getSharedTaskList(projectDetails?.id, shareId);
    if (res?.data?.data?.projectTasks) {
      setTasks(res.data.data.projectTasks);
    }
  };

  return (
    <>
      <SharedNav />
      <Box sx={{ pt: 4, mt: 6 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
              display: "block",
              fontSize: "28px",
              fontWeight: "bold",
              pl: 2,
              textAlign: "center",
            }}
          >
            {projectDetails?.name}
          </Typography>
        </Box>
        <Typography sx={{ pl: 2, fontSize: "16px", textAlign: "center" }}>
          Status:{" "}
          {projectDetails?.status === 3
            ? "Delayed"
            : projectDetails?.status === 2
            ? "Completed"
            : "Work in progress"}
        </Typography>
      </Box>
      <Box
        sx={{
          m: 2,
        }}
      >
        {tasks.length > 0 ? (
          <Table
            sx={{
              p: 2,
              border: "2px solid #000",
            }}
          >
            <TableHead
              sx={{
                borderBottom: "2px solid #000",
              }}
            >
              <TableRow>
                <TableCell>Task Name</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Assigned To</TableCell>
                <TableCell>Status</TableCell>
                <TableCell align="right">Estimated Hours</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((task) => (
                <TableRow key={task.id}>
                  <TableCell>{task.name}</TableCell>
                  <TableCell>{task.start_date}</TableCell>
                  <TableCell>{task.end_date}</TableCell>
                  <TableCell>
                    {task?.get_assigned_to?.first_name}{" "}
                    {task?.get_assigned_to?.last_name}
                  </TableCell>
                  <TableCell>{task?.get_status?.status}</TableCell>
                  <TableCell align="right">{task.estimate_hours}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No tasks found</Typography>
        )}
      </Box>
    </>
  );
};

export default SharedTaskList;
