import React, { useState, useRef } from "react";
import { Link as BrowserLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Container,
  Divider,
  Link,
  Typography,
  Button,
} from "@material-ui/core";
import Logo from "../../components/Logo";
import ArrowRight from "../../icons/ArrowRight";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import XIcon from "../../icons/X";
import RegistrationSuccessfulModal from "../../components/Authentication/RegistrationSuccessfulModal";
import RegisterForm from "../../components/Authentication/RegisterForm";
const aboutText = [
  {
    id: 0,
    text: "A complete PPM solution available via the Cloud to unlimited users within your organization.",
  },
  {
    id: 1,
    text: "Identify the most valuable projects with out of the box intake, scoring and selection.",
  },
  {
    id: 2,
    text: "Proactively manage issues, resources and project milestones to make better business decisions.",
  },
  {
    id: 3,
    text: "Support your stakeholders and decision makers with portfolio-level KPIs and metrics via dashboards and graphs.",
  },
  {
    id: 4,
    text: "Ensure that your strategic initiatives have the transparency and governance necessary for your company to adapt and succeed.",
  },
];

const Register = (props) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [registrationModalState, setRegistrationModalState] = useState(false);

  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const companyId = parameters.get("cid");

  const handleClickOpen = (scrollType) => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const navigate = useNavigate();

  const handleRegistrationModalClose = () => {
    setRegistrationModalState(false);
    navigate("/");
  };

  return (
    <>
      <Helmet>
        <title>Register | Projectric</title>
      </Helmet>
      <div>
        <RegistrationSuccessfulModal
          registrationModalState={registrationModalState}
          handleRegistrationModalClose={handleRegistrationModalClose}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    lineHeight: "28px",
                  }}
                >
                  Terms and Conditions
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#8A94A6",
                  }}
                >
                  Please read the terms and conditions
                </Typography>
              </Box>
              <Box>
                <XIcon
                  sx={{ color: "#C0CBDE", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <DialogContentText
              id="scroll-dialog-description"
              ref={descriptionElementRef}
              tabIndex={-1}
            >
              <Typography sx={{ pt: 2 }}>
                The website located at www.projectric.com (the “Site”) is a
                copyrighted work belonging to Projectric, LLC (“Company”, “us”,
                “our”, and “we”). Certain features of the Site may be subject to
                additional guidelines, terms, or rules, which will be posted on
                the Site in connection with such features. All such additional
                terms, guidelines, and rules are incorporated by reference into
                these Terms. These Terms of Use (these “Terms”) set forth the
                legally binding terms and conditions that govern your use of the
                Site
              </Typography>

              <Typography sx={{ pt: 2 }}>
                By accessing or using the Site, you are accepting these Terms
                (on behalf of yourself or the entity that you represent), and
                you represent and warrant that you have the right, authority,
                and capacity to enter into these Terms (on behalf of yourself or
                the entity that you represent).
              </Typography>
              <Typography sx={{ pt: 2 }}>
                you may not access or use the Site or accept the Terms if you
                are not at least 18 years old. If you do not agree with all of
                the provisions of these Terms, do not access and/or use the
                Site. These terms require the use of arbitration (Section 8.2)
                on an individual basis to resolve disputes, rather than jury
                trials or class actions, and also limit the remedies available
                to you in the event of a dispute.
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ py: 2 }}>
            <Button
              onClick={handleClose}
              color="primary"
              variant="contained"
              size="large"
            >
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Box>
        <Container
          maxWidth="lg"
          sx={{
            pt: 6,
            pl: {
              lg: 15,
              md: 10,
              sm: 6,
              xs: 2,
            },
            ml: 0,
            mr: 0,
          }}
        >
          <Link component={BrowserLink} to="/">
            <Logo
              sx={{
                height: 33,
                width: 42,
              }}
            />
          </Link>
        </Container>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            lg: "repeat(2, 1fr)",
            xs: "repeat(1, 1fr)",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.default",
            pt: 8,
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              pl: {
                lg: 15,
                md: 10,
                sm: 6,
                xs: 2,
              },
            }}
          >
            <Typography
              color="textPrimary"
              sx={{ fontWeight: "bold", fontSize: "32px" }}
              variant="h5"
            >
              One stop solution for your
            </Typography>
            <Typography
              color="primary.orange"
              sx={{ fontWeight: "bold", mt: 0.5, fontSize: "32px", mb: 4 }}
              variant="h5"
            >
              Project Portfolio Management
            </Typography>
            {aboutText.map((content) => (
              <Box key={content.id} sx={{ display: "flex", mt: 2 }}>
                <Box sx={{ pr: 2, mt: 0.5 }}>
                  <ArrowRight fontSize="medium" />
                </Box>
                <Box>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                  >
                    {content.text}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Container>
        </Box>
        <Box
          sx={{
            pt: 8,
          }}
        >
          <Container
            maxWidth="ls"
            sx={{
              pr: {
                lg: 15,
              },
            }}
          >
            <Box
              sx={{
                px: { lg: 6, md: 4, sm: 3, xs: 2 },
                py: { lg: 4, md: 4, sm: 3, xs: 2 },
                backgroundColor: "background.paper",
                boxShadow: "0px 3px 4px rgba(45, 62, 86, 0.1)",
              }}
            >
              <Typography
                color="textPrimary"
                variant="h6"
                sx={{
                  pb: 2,
                  fontSize: "24px",
                  fontWeight: "bold",
                  lineHeight: "36px",
                }}
              >
                Sign Up
              </Typography>
              <Box>
                <RegisterForm
                  setRegistrationModalState={setRegistrationModalState}
                  handleClickOpen={handleClickOpen}
                  companyId={companyId}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container
        maxWidth="lg"
        sx={{
          mt: 6,
          px: {
            lg: 15,
          },
          py: 3,
        }}
      >
        <Divider />
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "20px",
            textAlign: "justify",
            pt: 1,
          }}
        >
          Projectric is a leading Project Portfolio Management solution that
          organizes project and program data into relevant, actionable
          information for making business decisions. We are a trusted partner
          for organizations that need to perform meaningful analysis, forecast
          outcomes, collaborate in a centralized resource and adapt quickly to
          changing business needs.
        </Typography>
      </Container>
    </>
  );
};

export default Register;
