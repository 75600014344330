import { handleApiRequest } from "../../hooks/callApi";

export const getCalenderData = async ({ userId, startDate, endDate }) => {
  const res = await handleApiRequest(
    `/api/calendar/${userId}?start_date=${startDate}&end_date=${endDate}`,
    "get"
  );

  return res;
};

export const createSharedCalendar = async (data) => {
  const res = await handleApiRequest(`/api/shared-calendar`, "post", data);
  return res;
};

export const validateToken = async (token) => {
  const body = {
    token: token,
  };
  const res = await handleApiRequest(
    `/api/shared-calendar/validate`,
    "post",
    body
  );
  return res;
};

export const createReminder = async (data) => {
  const res = await handleApiRequest(`/api/reminder`, "post", data);
  return res;
};

export const getCalendarReminders = async (userId, date) => {
  const res = await handleApiRequest(`/api/reminder/${userId}/${date}`, "get");
  return res;
};

export const deleteReminder = async (reminderId) => {
  const res = await handleApiRequest(`/api/reminder/${reminderId}`, "delete");
  return res;
};

export const getAllUsersDailyUtilization = async (
  startDate,
  endDate,
  isWeekly,
  department,
  designation,
  memberNameFilter,
  projectFilter
) => {
  const res = await handleApiRequest(
    `/api/calendar/get-all-users-daily-utilization?start_date=${startDate}&end_date=${endDate}&isWeekly=${
      isWeekly ? 1 : 0
    }&department_id=${department}&designation=${designation}&user_name=${memberNameFilter}&project_id=${projectFilter}`,
    "get"
  );

  return res;
};
export const getAllDesignationUtilization = async () => {
  const res = await handleApiRequest(
    `/api/calendar/get-designation-utilization`,
    "get"
  );

  return res;
};
