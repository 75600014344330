import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Grid,
  Typography,
  TextField,
  InputLabel,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import {
  fetchMemberScheduling,
  fetchScheduling,
} from "../../../api/scheduling/Scheduling";
import moment from "moment";
import ViewAllSchedules from "./ViewAllSchedules";
import useLoader from "../../../hooks/useLoader";
import DatePicker from "@material-ui/lab/DatePicker";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(5),
  },
}));

const DateRangeView = ({ memberId, setUtilizationDetails }) => {
  let currentDate = moment();
  let weekStart = currentDate.clone().startOf("isoWeek");
  const weekEnd = moment(weekStart).add(6, "days");
  const [startDate, setstartDate] = useState(weekStart);
  const [endDate, setEndDate] = useState(weekEnd);
  const [schedules, setSchedules] = useState([]);
  const { loaderState, loaderStart, loaderEnd } = useLoader();

  const classes = useStyles();

  useEffect(() => {
    getSchedulingData();
    return () => {
      setSchedules([]);
    };
  }, [startDate, endDate]);

  const getSchedulingData = async () => {
    loaderStart();
    let res;
    if (memberId) {
      res = await fetchMemberScheduling({
        startDate: startDate,
        endDate: endDate,
        userId: memberId || "",
      });
      if (res?.data?.data?.daySchedules?.data?.length > 0) {
        setUtilizationDetails({
          utilization: res?.data?.data?.utilization,
          availability: res?.data?.data?.availability,
        });
      } else {
        setUtilizationDetails({
          utilization: 0,
          availability: 100,
        });
      }
    } else {
      res = await fetchScheduling({
        startDate: startDate,
        endDate: endDate,
        userId: memberId || "",
      });
    }
    if (res?.data?.success) {
      if (memberId) {
        setSchedules(res.data.data.daySchedules);
      } else {
        setSchedules(res.data.data);
      }
    }
    loaderEnd();
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Week View</title>
      </Helmet>
      <Box>
        <Box>
          <Grid container spacing={1}>
            <Grid item sm={4} md={6}></Grid>
            <Grid item xs={12} sm={4} md={3}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label" shrink>
                  Start Date
                </InputLabel>
                <DatePicker
                  autoOk={true}
                  className="pt-dtpicker"
                  label={"Start Date"}
                  fullWidth
                  onChange={(date) => setstartDate(date)}
                  value={startDate}
                  name="startDate"
                  error={false}
                  helperText=""
                  renderInput={(inputProps) => (
                    <TextField
                      className="pt-datepicker-ip"
                      fullWidth
                      variant="outlined"
                      {...inputProps}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-label" shrink>
                  End Date
                </InputLabel>
                <DatePicker
                  autoOk={true}
                  className="pt-dtpicker"
                  label={"End Date"}
                  fullWidth
                  onChange={(date) => setstartDate(date)}
                  value={endDate}
                  name="endDate"
                  error={false}
                  helperText=""
                  minDate={startDate}
                  renderInput={(inputProps) => (
                    <TextField
                      className="pt-datepicker-ip"
                      fullWidth
                      variant="outlined"
                      {...inputProps}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item md={3} sm={4} xs={12}></Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 1, p: 2 }}>
          {schedules?.data?.length > 0 ? (
            <ViewAllSchedules
              schedules={schedules}
              userView={memberId ? true : false}
            />
          ) : (
            <Typography
              variant="h6"
              color="secondary"
              align="center"
              sx={{ p: 4 }}
            >
              {loaderState ? "Loading..." : "No Records Available"}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DateRangeView;
