import React, { useState } from "react";
import { TableCell, TableRow, Typography } from "@material-ui/core";

import PencilAltIcon from "../../../../icons/PencilAlt";
import TrashIcon from "../../../../icons/Trash";
import WarningModal from "./WarningModal";
import EditFilterModal from "./EditFilterModal";
import { deleteSavedReport } from "../../../../api/reports/CustomReport";
import toast from "react-hot-toast";
const SingleSavedFilter = ({
  savedFilter,
  updateFilters,
  getTaskSavedReports,
}) => {
  const [open, setOpen] = useState(false);
  const [editFilterModalOpen, setEditFilterModalOpen] = useState(false);

  const deleteReport = async () => {
    const res = await deleteSavedReport(savedFilter.id);
    if (res.data.success) {
      toast.success(res.data.data.message);
      getTaskSavedReports();
    }
  };

  const onDelete = () => {
    deleteReport();
    setOpen(false);
  };

  const openDeleteModal = () => {
    setOpen(true);
  };

  const openEditModal = () => {
    setEditFilterModalOpen(true);
  };

  return (
    <>
      {editFilterModalOpen && (
        <EditFilterModal
          editFilterModalOpen={editFilterModalOpen}
          setEditFilterModalOpen={setEditFilterModalOpen}
          initialValues={savedFilter}
          getTaskSavedReports={getTaskSavedReports}
        />
      )}
      {open && (
        <WarningModal
          open={open}
          setOpen={setOpen}
          actionFn={onDelete}
          text="Are you sure, you want to delete this filter."
        />
      )}
      <TableRow hover>
        <TableCell>
          <>
            <Typography
              onClick={() => updateFilters(savedFilter)}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: "text.blue",
                },
              }}
              variant="h6"
            >
              {savedFilter.title}
            </Typography>
            <Typography variant="p">{savedFilter.description}</Typography>
          </>
        </TableCell>
        <TableCell align="center">
          {/* {(user.role === 3 && savedFilter.level === 3) || (user.role === 2 && savedFilter.level !== 1) && ( */}
          <>
            <PencilAltIcon
              title="Edit"
              className="action-icon"
              onClick={openEditModal}
            />
            <TrashIcon
              title="delete"
              className="action-icon"
              onClick={openDeleteModal}
            />
          </>
          {/* )} */}
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleSavedFilter;
