import React, { useState, useEffect, useRef } from "react";
import { Box, Typography } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  getProjectTasks,
  getTreeViewData,
  updateMilestoneOrder,
  updateTaskMilestone,
} from "../../../api/project/Project";
import useLoader from "../../../hooks/useLoader";
import AddNewMilestoneModal from "./AddNewMilestoneModal";
import AddNewTaskModal from "./AddNewTaskModal";
import SingleMilestone from "./treeview/SingleMilestone";
import ImportTaskModal from "./ImportTaskModal";

const AllTasksTreeView = ({
  selectedProject,
  showAddMilestoneModal,
  setShowAddMilestoneModal,
  showImportTaskModal,
  setShowImportTaskModal,
}) => {
  const { loaderStart, loaderEnd } = useLoader();
  const [list, setList] = useState([]);
  useEffect(() => {
    getTreeView();
  }, []);

  const getTreeView = async () => {
    loaderStart();
    const res = await getTreeViewData({
      projectId: selectedProject.id,
    });
    setList(res.data.data.values);
    loaderEnd();
  };

  const reorderMilestone = async (milestoneId, order) => {
    loaderStart();
    const res = await updateMilestoneOrder({ milestoneId, order });
    if (res.data.success) {
      getTreeView();
    }
    loaderEnd();
  };

  const changeTaskMilestone = async (taskId, milestoneId) => {
    loaderStart();
    const res = await updateTaskMilestone({ taskId, milestoneId });
    if (res.data.success) {
      getTreeView();
    }
    loaderEnd();
  };

  return (
    <Box>
      {showAddMilestoneModal && (
        <AddNewMilestoneModal
          selectedProject={selectedProject}
          showAddMilestoneModal={showAddMilestoneModal}
          setShowAddMilestoneModal={setShowAddMilestoneModal}
        />
      )}
      {showImportTaskModal && (
        <ImportTaskModal
          selectedProject={selectedProject}
          showImportTaskModal={showImportTaskModal}
          setShowImportTaskModal={setShowImportTaskModal}
          onSuccess={getTreeView}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 1,
        }}
      >
        <Box>
          <Typography
            sx={{
              minWidth: "140px",
              maxWidth: "200px",
              width: "30%",
            }}
            className="treeview-header"
          >
            NAME
          </Typography>
        </Box>
        <Box sx={{ width: "12%" }}>
          <Typography className="treeview-header">TYPE</Typography>
        </Box>
        <Box sx={{ width: "14%" }}>
          <Typography className="treeview-header">STATUS</Typography>
        </Box>
        <Box sx={{ width: "12%" }}>
          <Typography className="treeview-header">START DATE</Typography>
        </Box>
        <Box sx={{ width: "12%" }}>
          <Typography className="treeview-header">END DATE</Typography>
        </Box>
        <Box sx={{ width: "14%" }}>
          <Typography className="treeview-header">ASSIGNED TO</Typography>
        </Box>
        <Box sx={{ width: "6%" }}>
          <Typography className="treeview-header">ACTION</Typography>
        </Box>
      </Box>
      {list.length > 0 && (
        <DragDropContext
          onDragEnd={(param) => {
            const srcI = param.source.index + 1;
            const desI = param.destination?.index + 1;
            const draggableId = param.draggableId.split("#");

            if (draggableId[0] === "m" && desI) {
              if (srcI !== desI) {
                reorderMilestone(draggableId[1], desI);
              }
              // console.log("Milestone " + draggableId[1]);
              // console.log("Prev Order = " + srcI);
              // console.log("Curr Order = " + desI);
            }

            if (draggableId[0] === "t") {
              const destinationMilestoneId =
                param.destination?.droppableId.split("#");
              if (destinationMilestoneId[1] !== "undefined") {
                changeTaskMilestone(draggableId[1], destinationMilestoneId[1]);
              } else {
                changeTaskMilestone(draggableId[1], null);
              }
            }
          }}
        >
          <Box>
            <Droppable droppableId="droppable-1" type="droppableItem">
              {(provided, _) => (
                <Box ref={provided.innerRef}>
                  {list.map((item, i) => (
                    <SingleMilestone
                      key={item.id}
                      item={item}
                      i={i}
                      selectedProject={selectedProject}
                      getTaskList={getTreeView}
                    />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </Box>
        </DragDropContext>
      )}
    </Box>
  );
};

export default AllTasksTreeView;
