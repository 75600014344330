const issueStatus = [
  {
    id: 1,
    type: "Assigned",
  },
  {
    id: 2,
    type: "In Progress",
  },
  {
    id: 3,
    type: "Mark As Resolved",
  },
  {
    id: 4,
    type: "Closed",
  },
];

export default issueStatus;
