import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  getAllDesignationUtilization,
  getAllUsersDailyUtilization,
} from "../../../api/calender/Calender";
import UtilizationRow from "./UtilizationRow";

const formatWeekViewDate = (date) => {
  return moment(date).format("MM-DD-YY");
};

const formatMonthViewDate = (date) => {
  return moment(date).format("MM-YY");
};

const calculateHours = (date, percentage, view) => {
  if (view === "week") {
    return Math.round((percentage * 40) / 100);
  } else if (view === "month") {
    return Math.round((percentage * 160) / 100);
  }
};

const Utilization = ({
  view,
  viewPercentage,
  selectedDepartment,
  selectedDesignation,
  memberNameFilter,
  selectedRole,
  steps,
  projectFilter,
}) => {
  const [userUtilization, setUserUtilization] = useState({});
  const [allDateKeys, setAllDateKeys] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("week").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("week").format("YYYY-MM-DD")
  );

  useEffect(() => {
    getUtilization();
  }, [startDate, selectedDesignation, memberNameFilter, projectFilter]);

  useEffect(() => {
    console.log("steps", steps);
    if (view === "week") {
      if (steps === 0) {
        setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
        setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
      } else {
        const numberOfWeekToBeAdded = steps * 12;
        setStartDate(
          moment()
            .startOf("week")
            .add(numberOfWeekToBeAdded, "week")
            .format("YYYY-MM-DD")
        );
      }
    } else if (view === "month") {
      if (steps === 0) {
        setStartDate(moment().startOf("year").format("YYYY-MM-DD"));
        setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
      } else {
        setStartDate(
          moment().startOf("year").add(steps, "year").format("YYYY-MM-DD")
        );
      }
    }
  }, [steps]);

  useEffect(() => {
    if (Object.values(userUtilization)[0]) {
      setAllDateKeys(Object.keys(Object.values(userUtilization)[0].dates));
    }
  }, [userUtilization]);

  useEffect(() => {
    if (view === "week") {
      setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
    } else if (view === "month") {
      setStartDate(moment().startOf("year").format("YYYY-MM-DD"));
      setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
    }
  }, [view]);

  const getUtilization = async () => {
    const isWeekly = view === "week" ? true : false;
    const res2 = await getAllDesignationUtilization();
    const res = await getAllUsersDailyUtilization(
      startDate,
      endDate,
      isWeekly,
      "",
      selectedDesignation,
      memberNameFilter,
      projectFilter
    );
    setUserUtilization(res.data.data.values || []);
  };

  const checkFilter = (key) => {
    if (selectedDepartment !== "" && selectedRole !== "") {
      return (
        userUtilization[key].department === selectedDepartment &&
        userUtilization[key].role === selectedRole
      );
    } else if (selectedDepartment !== "") {
      return userUtilization[key].department === selectedDepartment;
    } else if (selectedRole !== "") {
      return userUtilization[key].role === selectedRole;
    } else {
      return true;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflowX: "auto",
      }}
    >
      {Object.values(userUtilization)[0] ? (
        <>
          <Table>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center">
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {view === "week"
                    ? `Weekly Load by ${viewPercentage ? "%" : "hours"}`
                    : `Monthly Load by ${viewPercentage ? "%" : "hours"}`}
                </Typography>
              </TableCell>
            </TableRow>
          </Table>
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "sticky",
                top: "200px",
                maxHeight: "calc(100vh - 350px)",
              }}
            >
              <Table
                sx={{
                  position: "relative",
                }}
                hover
              >
                <TableHead
                  sx={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "#eee",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        px: 0,
                      }}
                    ></TableCell>
                    <TableCell
                      sx={{
                        width: "25%",
                        minWidth: "200px",
                        px: 0,
                      }}
                    >
                      Name
                    </TableCell>
                    {Object.keys(Object.values(userUtilization)[0].dates).map(
                      (key, index) => (
                        <TableCell
                          sx={{
                            minWidth: "50px",
                            whiteSpace: "nowrap",
                            px: 0,
                          }}
                        >
                          {view === "week"
                            ? formatWeekViewDate(key)
                            : formatMonthViewDate(key)}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& td": {
                      py: 0.5,
                      px: 0,
                    },
                  }}
                >
                  {Object.keys(userUtilization).map((key, index) => (
                    <>
                      {checkFilter(key) && (
                        <UtilizationRow
                          userUtilization={userUtilization}
                          id={key}
                          key={key}
                          viewPercentage={viewPercentage}
                          view={view}
                          calculateHours={calculateHours}
                          allDateKeys={allDateKeys}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      )}
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: "bold",
            color: "secondary.main",
            py: 2,
          }}
        >
          No Data Found
        </Typography>
      )}
    </Box>
  );
};

export default Utilization;
