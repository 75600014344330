const currencyMaskOptions = {
  prefix: "$",
  suffix: "",
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowDecimal: true,
  decimalSymbol: ".",
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 11, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export default currencyMaskOptions;
