import axios from "axios";
import { useCallback, useEffect, useState } from "react";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;

export function useFetch(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const accessToken = window.localStorage.getItem("accessToken") || null;
      const header = { headers: { Authorization: `Bearer ${accessToken}` } };
      const res = await axios["get"](url, header);
      const json = await res?.data;
      setData(json);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }, [url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loading, error, refetch: fetchData };
}
