import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@material-ui/core";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import roles from "../@const/roles";
import useAuth from "../hooks/useAuth";
import ShareIcon from "../icons/Share";

const getRole = (role) => {
  const r = roles.find((r) => r.roleType === role);
  if (r) return r.roleValue;
  return "";
};

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const { user, isAuthenticated } = useAuth();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const copyInviteLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_FRONTEND_URL}/authentication/register?cid=${user?.company_code}`
    );
    toast.success("Invite link copied");
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{ alignItems: "center", display: "flex" }}
      >
        <Avatar src={user?.avatar} sx={{ height: 32, width: 32 }} />
        <Box
          sx={{
            pl: 1,
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            alignItems: "baseline",
          }}
        >
          {isAuthenticated && (
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "14px",
                lineHeight: "24px",
              }}
            >
              {`${user?.first_name} ${user?.last_name}`}
            </Typography>
          )}
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontSize: "14px",
              lineHeight: "24px",
            }}
          >
            {isAuthenticated && getRole(user?.role)}
          </Typography>
        </Box>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Divider />
        <Box sx={{ mt: 2, display: { xs: "block", md: "none" } }}>
          <MenuItem>
            <ListItemText
              primary={
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  sx={{ fontWeight: "bold" }}
                >
                  Name: {`${user?.first_name} ${user?.last_name}`}
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem>
            <ListItemText
              primary={
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  sx={{ fontWeight: "bold" }}
                >
                  Role: {isAuthenticated && roles[user?.role - 1].roleValue}
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Divider />
        <Box sx={{ mt: 2 }}>
          <MenuItem>
            <ListItemText
              primary={
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  sx={{ fontWeight: "bold" }}
                >
                  Company Name: {user?.company_name}
                </Typography>
              }
            />
          </MenuItem>
          <MenuItem>
            <ListItemText
              primary={
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  sx={{ fontWeight: "bold" }}
                >
                  Company Code: {user?.company_code}
                </Typography>
              }
            />
          </MenuItem>
        </Box>
        <Box sx={{ p: 1 }}>
          <Button
            startIcon={<ShareIcon fontSize="small" />}
            sx={{
              backgroundColor: "#092bb1",
              ":hover": {
                backgroundColor: "#071a62",
              },
            }}
            variant="contained"
            fullWidth
            focusRipple
            onClick={copyInviteLink}
          >
            INVITE
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
