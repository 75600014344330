import { createContext, useReducer } from "react";
const initialState = {
  loaderState: false,
};

const handlers = {
  LOADERSTART: (state, action) => {
    return {
      loaderState: true,
    };
  },
  LOADEREND: (state, action) => {
    return {
      loaderState: false,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const LoaderContext = createContext({
  ...initialState,
  loaderStart: () => Promise.resolve(),
  loaderEnd: () => Promise.resolve(),
});

export const LoaderProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const loaderStart = () => {
    dispatch({
      type: "LOADERSTART",
    });
    setTimeout(() => {
      dispatch({
        type: "LOADEREND",
      });
    }, 3000);
  };

  const loaderEnd = () => {
    dispatch({
      type: "LOADEREND",
    });
  };

  return (
    <LoaderContext.Provider
      value={{
        ...state,
        loaderStart,
        loaderEnd,
      }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

export default LoaderContext;
