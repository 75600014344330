import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  deleteAllNotification,
  getAllNotification,
  markAsRead,
} from "../api/project/Notification";
import BellIcon from "../icons/Bell";
import ChatAltIcon from "../icons/ChatAlt";
import CreditCardIcon from "../icons/CreditCard";
import ShoppingCartIcon from "../icons/ShoppingCart";

const iconsMap = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon,
};

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState({
    Notifications: [],
    NotificationsCount: 0,
  });

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getNotifications();
    }, 200000);
    return () => clearInterval(intervalId);
  }, []);

  const getNotifications = async () => {
    const res = await getAllNotification();
    if (res?.data.success) {
      setNotifications(res.data.data);
    }
  };

  const handleOpen = () => {
    markAllAsRead();
    setOpen(true);
  };

  const markAllAsRead = async () => {
    const res = await markAsRead();
    if (res?.data.success) {
      setNotifications({ ...notifications, NotificationsCount: 0 });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const delteNotifications = async () => {
    const res = await deleteAllNotification();
    if (res?.data.success) {
      setNotifications({ Notifications: [], NotificationsCount: 0 });
    }
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          size="small"
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge color="error" badgeContent={notifications.NotificationsCount}>
            <BellIcon fontSize="medium" sx={{ color: "#A0AABB" }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 370, maxHeight: "600px" },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="h6">
            Notifications
          </Typography>
        </Box>
        {notifications.Notifications.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography color="textPrimary" variant="subtitle2">
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notifications.Notifications.map((notification) => {
                const Icon = iconsMap["new_message"];
                return (
                  <ListItem divider key={notification.id}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          backgroundColor: notification.readAt
                            ? "#8A94A6"
                            : "primary.main",
                          color: "primary.contrastText",
                        }}
                      >
                        <Icon fontSize="small" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Link
                          component={RouterLink}
                          underline="none"
                          to={notification.link}
                        >
                          <Typography
                            sx={{
                              color: notification.readAt
                                ? "#8A94A6"
                                : "primary",
                              fontWeight: "bold",
                            }}
                            variant="body2"
                          >
                            {notification.title}
                          </Typography>
                        </Link>
                      }
                      secondary={
                        <Typography
                          sx={{
                            color: notification.readAt ? "#8A94A6" : "primary",
                          }}
                          variant="body2"
                        >
                          {notification.message}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              })}
            </List>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={delteNotifications}
              >
                Clear All
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
