import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import {
  getArchivedMilestones,
  restoreArchivedMilestone,
} from "../../../../api/archive/Archive";
import useLoader from "../../../../hooks/useLoader";
import SingleArchiveRow from "./SingleArchiveRow";
const ArchiveMilestone = () => {
  const [archivedMilestones, setArchivedMilestones] = useState([]);
  const { loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    getAllArchivedMilestones();
  }, []);

  const getAllArchivedMilestones = async () => {
    loaderStart();
    const res = await getArchivedMilestones();
    if (res.data.data.items) {
      setArchivedMilestones(res.data.data.items);
    }
    loaderEnd();
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              width: "35%",
            }}
          >
            Name
          </TableCell>
          <TableCell>Project Name</TableCell>
          <TableCell>Deleted At</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {archivedMilestones.map((project) => (
          <SingleArchiveRow
            key={project.id}
            item={project}
            type="milestone"
            onSuccess={getAllArchivedMilestones}
            onDelete={restoreArchivedMilestone}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ArchiveMilestone;
