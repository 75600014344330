import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";
import { createTemplate } from "../../../api/admin/Template";

const CreateTemplateModal = ({ open, onClose, fetchTemplates }) => {
  const [template, setTemplate] = useState({
    name: "",
    description: "",
    url: "",
  });

  const handleChange = (e) => {
    setTemplate({
      ...template,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    await createTemplate(template);
    fetchTemplates();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Template</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Template Name"
          name="name"
          value={template.name}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="Description"
          name="description"
          value={template.description}
          onChange={handleChange}
          fullWidth
        />
        <TextField
          margin="dense"
          label="URL"
          name="url"
          value={template.url}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTemplateModal;
