import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import toast from "react-hot-toast";
import { generatePortfolioShareLink } from "../../../api/share/Share";
import useLoader from "../../../hooks/useLoader";
import CostGraph from "./CostGraph";
import DashboardGanttChart from "./DashboardGanttChart";
import DashboardProgress from "./DashboardProgress";
import ProjectRevenue from "./ProjectRevenue";
import MilestoneReport from "./MilestoneReport";
const Dashboard = ({ selectedPortfolio }) => {
  const { loaderState, loaderStart, loaderEnd } = useLoader();
  const generateLink = async () => {
    loaderStart();
    const res = await generatePortfolioShareLink(selectedPortfolio.id);
    if (res.data.data.token) {
      const link = `${process.env.REACT_APP_FRONTEND_URL}/share/portfolio/${res.data.data.token}`;
      navigator.clipboard.writeText(link);
      toast.success(`Link copied ${link}`, {
        duration: 20000,
        style: {
          maxWidth: 450,
          wordBreak: "break-all",
        },
      });
    }
    loaderEnd();
  };
  return (
    <>
      <Box sx={{ float: "right" }}>
        <Tooltip title="Share portfolio dashboard">
          <Button onClick={generateLink} variant="contained" color="primary">
            Share
          </Button>
        </Tooltip>
      </Box>
      {selectedPortfolio?.project_count > 0 ? (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <SinglePortfolioDashboard title="Project Gantt Chart">
              <DashboardGanttChart id={selectedPortfolio.id} />
            </SinglePortfolioDashboard>
            <SinglePortfolioDashboard title="Progress" grid={{ md: 6, sm: 6 }}>
              <DashboardProgress id={selectedPortfolio.id} />
            </SinglePortfolioDashboard>
            <SinglePortfolioDashboard title="Cost" grid={{ md: 6, sm: 6 }}>
              <CostGraph id={selectedPortfolio.id} />
            </SinglePortfolioDashboard>
            {selectedPortfolio.id === 202 && (
              <SinglePortfolioDashboard title="Revenue Chart">
                <ProjectRevenue />
              </SinglePortfolioDashboard>
            )}
            <SinglePortfolioDashboard
              title="Milestone Report"
              grid={{ md: 12, sm: 12 }}
            >
              <MilestoneReport />
            </SinglePortfolioDashboard>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
          <Typography variant="h6" color="secondary">
            {loaderState
              ? "Loading..."
              : "Insufficient data to generate dashboard"}
          </Typography>
        </Box>
      )}
    </>
  );
};

const SinglePortfolioDashboard = ({
  title,
  styles = {},
  grid = {},
  children,
}) => {
  return (
    <>
      <Grid item xs={12} {...grid}>
        <Card
          sx={{
            boxShadow: "4px 4px 5px #00000050 !important",
            ...styles,
          }}
        >
          <CardHeader title={title} />
          {children}
        </Card>
      </Grid>
    </>
  );
};
export default Dashboard;
