import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Pagination,
  TableRow,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  TableContainer,
  Paper,
  Tooltip,
} from "@material-ui/core";
import SingleTask from "./SingleTask";
import AddNewTaskModal from "./AddNewTaskModal";
import { getProjectTasks } from "../../../api/project/Project";
import useLoader from "../../../hooks/useLoader";
import ImportTaskModal from "./ImportTaskModal";
import AddAssignedToProjectMemberModal from "./AddAssignedToProjectMemberModal";
import RearrangeModal from "./rearrange-tasks/RearrangeModal";
import AllTaskGanttTimelineChart from "./AllTaskGanttTimelineChart";
import toast from "react-hot-toast";
import { generateShareTaskLink } from "../../../api/share/Share";
import { gantt } from "dhtmlx-gantt/codebase/dhtmlxgantt";
import { Undo } from "@material-ui/icons";
import {
  checkUndoProjectTaskAvailability,
  undoProjectTask,
} from "../../../api/budget/Budget";
import { checkIfProduction } from "../../../utils/checkIfProduction";
import StatusPopover from "./StatusPopover";
// import VideoFloatButton from "../../VideoFloatButton";

const AllTasks = ({
  selectedProject,
  showAddTaskModal,
  setShowAddTaskModal,
  showImportTaskModal,
  setShowImportTaskModal,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [allTasks, setAllTasks] = useState([]);
  const [rearrangeTaskModal, setRearrangeTaskModal] = useState(false);
  const [filter, setFilter] = useState("");
  const [showUndo, setShowUndo] = useState(false);

  const { loaderState, loaderStart, loaderEnd } = useLoader();
  const [addAssignedToProjectMembers, setaddAssignedToProjectMembers] =
    useState(null);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const generateLink = async () => {
    loaderStart();
    const res = await generateShareTaskLink(selectedProject.id);
    if (res.data.data.token) {
      const link = `${process.env.REACT_APP_FRONTEND_URL}/share/task/${res.data.data.token}`;
      navigator.clipboard.writeText(link);
      toast.success(`Link copied ${link}`, {
        duration: 8000,
        style: {
          maxWidth: 450,
          wordBreak: "break-all",
        },
      });
    }
    loaderEnd();
  };

  useEffect(() => {
    getTaskList();
  }, [currentPage, filter]);

  useEffect(() => {
    checkUndoActive();
  }, []);

  const getTaskList = async () => {
    loaderStart();
    const res = await getProjectTasks({
      projectId: selectedProject.id,
      page: currentPage,
      sortBy: filter,
    });
    if (res.data.success) {
      setAllTasks(res.data.data.tasks);
    }
    loaderEnd();
  };

  const checkUndoActive = async () => {
    const res = await checkUndoProjectTaskAvailability(selectedProject.id);
    if (res?.data) {
      setShowUndo(res?.data?.data?.value);
    }
  };

  const startUndo = async () => {
    loaderStart();
    const res = await undoProjectTask(selectedProject.id);
    if (res?.data) {
      window.location.reload();
    }
  };

  return (
    <Box sx={{ pt: 1 }}>
      {showAddTaskModal && (
        <AddNewTaskModal
          selectedProject={selectedProject}
          showAddTaskModal={showAddTaskModal}
          setShowAddTaskModal={setShowAddTaskModal}
          getTaskList={getTaskList}
          setaddAssignedToProjectMembers={setaddAssignedToProjectMembers}
        />
      )}
      {addAssignedToProjectMembers && (
        <AddAssignedToProjectMemberModal
          addAssignedToProjectMembers={addAssignedToProjectMembers}
          setaddAssignedToProjectMembers={setaddAssignedToProjectMembers}
          selectedProject={selectedProject}
        />
      )}
      {showImportTaskModal && (
        <ImportTaskModal
          selectedProject={selectedProject}
          showImportTaskModal={showImportTaskModal}
          setShowImportTaskModal={setShowImportTaskModal}
          onSuccess={getTaskList}
        />
      )}
      {rearrangeTaskModal && (
        <RearrangeModal
          selectedProject={selectedProject}
          setShowModal={setRearrangeTaskModal}
          showModal={rearrangeTaskModal}
          onSuccess={getTaskList}
        />
      )}
      <Box sx={{ mb: 3 }}>
        <FormControl>
          <InputLabel id="select-status" sx={{ color: "#bbb" }}>
            Sort By{" "}
          </InputLabel>
          <Select
            label="Sort By"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            sx={{
              width: "200px",
              // height: "40px",
            }}
          >
            <MenuItem selected value={""}>
              None
            </MenuItem>
            <MenuItem value={"start_date"}>Start Date</MenuItem>
            <MenuItem value={"end_date"}>End Date</MenuItem>
            <MenuItem value={"status"}>Status</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ ml: "auto", float: "right", display: "flex" }}>
          <Tooltip title="Undo">
            <Button
              variant="contained"
              size="large"
              onClick={() => startUndo()}
              sx={{
                mr: 2,
                backgroundColor: "#6e6e6e",
                transition: "all 0.3s ease-in-out",
                ":hover": {
                  backgroundColor: "#7a7676",
                  transform: "scale(1.05)",
                },
              }}
            >
              <Undo />
            </Button>
          </Tooltip>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => generateLink()}
          >
            Share
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setRearrangeTaskModal(true)}
            sx={{ ml: 2 }}
          >
            Re-arrange
          </Button>
          <StatusPopover />
        </Box>
      </Box>
      {!checkIfProduction() && allTasks?.data?.length > 0 && (
        <AllTaskGanttTimelineChart
          allTasks={allTasks?.data}
          selectedProject={selectedProject}
          getTaskList={getTaskList}
          setaddAssignedToProjectMembers={setaddAssignedToProjectMembers}
        />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                sx={{
                  mt: 2.5,
                }}
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell>#</TableCell> */}
                    <TableCell>TASK NAME</TableCell>
                    <TableCell>ASSIGNED TO </TableCell>
                    {/* <TableCell>TYPE</TableCell> */}
                    <TableCell align="center">STATUS</TableCell>
                    <TableCell>START DATE</TableCell>
                    <TableCell>END DATE </TableCell>
                    {/* <TableCell>%</TableCell> */}
                    <TableCell align="center">ACTION</TableCell>
                  </TableRow>
                </TableHead>
                {allTasks?.data?.length > 0 && (
                  <TableBody>
                    {allTasks?.data.map((task, index) => {
                      return (
                        <SingleTask
                          task={task}
                          key={index}
                          index={index}
                          selectedProject={selectedProject}
                          allTasks={allTasks}
                          getTaskList={getTaskList}
                          setaddAssignedToProjectMembers={
                            setaddAssignedToProjectMembers
                          }
                        />
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          {!loaderState && allTasks?.data?.length > 0 && (
            <AllTaskGanttTimelineChart
              allTasks={allTasks?.data}
              selectedProject={selectedProject}
              getTaskList={getTaskList}
            />
          )}
        </Grid> */}
      </Grid>

      <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
        {allTasks?.data?.length > 0 ? (
          <Pagination
            page={currentPage}
            onChange={handleChange}
            count={allTasks.last_page}
          />
        ) : (
          <Typography variant="h6" color="secondary">
            {loaderState ? "Loading..." : "No Records Available"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AllTasks;
