import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  TextField,
  Typography,
  Link,
  Grid
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import useMounted from '../../hooks/useMounted';
import { Link as BrowserRouter } from "react-router-dom";

const RegisterForm = (props) => {
  const { handleClickOpen, setRegistrationModalState, companyId } = props;
  const mounted = useMounted();
  const { register } = useAuth();

  return (
    <Formik
      initialValues={{
        email: "",
        first_name: "",
        last_name: "",
        company: companyId !== null ? companyId : "",
        password: "",
        c_password: "",
        policy: false,
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        first_name: Yup.string().max(255).required("First Name is required"),
        company: Yup.string().max(255).required("Company is required"),
        last_name: Yup.string().max(255).required("Last Name is required"),
        password: Yup.string()
          .min(7)
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/,
            "Must Contain One Uppercase, One Lowercase, One Number and one special case Character"
          )
          .required("Password is required"),
        c_password: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),
        policy: Yup.boolean().oneOf([true], "This field must be checked"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const res = await register(
            values.email,
            values.first_name,
            values.last_name,
            values.company,
            values.password,
            values.c_password
          );
          if (res.data.success && !res.data?.data?.error) {
            setRegistrationModalState(true);
          }
          if (res?.data?.data?.error) {
            setStatus({ success: false });
            setErrors({
              submit: res.data.data.message,
            });
          }
          // setTimeout(() => {
          //   setRegistrationModalState(false);
          //   navigate("/");
          // }, 2000);
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({
            submit: "Please contact support@projectric.com for login access! ",
          });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autocomplete="new-password"
                error={Boolean(touched.first_name && errors.first_name)}
                fullWidth
                helperText={touched.first_name && errors.first_name}
                margin="normal"
                label="First Name *"
                name="first_name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.first_name}
                variant="outlined"
                sx={{ mt: 0 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autocomplete="new-password"
                error={Boolean(touched.last_name && errors.last_name)}
                fullWidth
                helperText={touched.last_name && errors.last_name}
                margin="normal"
                label="Last Name *"
                name="last_name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.last_name}
                variant="outlined"
                sx={{ mt: 0 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autocomplete="new-password"
                label="Email *"
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
                sx={{ mt: 0 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autocomplete="new-password"
                label="Company or Company Code *"
                error={Boolean(touched.company && errors.company)}
                fullWidth
                helperText={touched.company && errors.company}
                margin="normal"
                name="company"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company}
                variant="outlined"
                sx={{ mt: 0 }}
                disabled={companyId !== null}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autocomplete="new-password"
                label="Password *"
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
                sx={{ mt: 0 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autocomplete="new-password"
                label="Confirm Password*"
                error={Boolean(touched.c_password && errors.c_password)}
                fullWidth
                helperText={touched.c_password && errors.c_password}
                margin="normal"
                name="c_password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.c_password}
                variant="outlined"
                sx={{ mt: 0 }}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              ml: -1,
              mt: 2,
            }}
          >
            <Checkbox
              checked={values.policy}
              color="primary"
              name="policy"
              onChange={handleChange}
            />
            <Typography color="textSecondary" variant="body2">
              I have read the{" "}
              <Link
                variant="body2"
                color="primary"
                component="button"
                onClick={(e) => {
                  e.preventDefault();
                  handleClickOpen("paper");
                }}
              >
                Terms and Conditions
              </Link>
            </Typography>
          </Box>
          {Boolean(touched.policy && errors.policy) && (
            <FormHelperText error>{errors.policy}</FormHelperText>
          )}
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Register
            </Button>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              fullWidth
              size="large"
              variant="outlined"
              component={BrowserRouter}
              to="/authentication/login"
            >
              Login
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default RegisterForm;
