import { useState, useRef, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import timelinePlugin from "@fullcalendar/timeline";
import {
  Badge,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Dialog,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { alpha, experimentalStyled } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  CalendarEventForm,
  CalendarToolbar,
} from "../../components/dashboard/calendar";
import ChevronRightIcon from "../../icons/ChevronRight";
import {
  closeModal,
  getEvents,
  openModal,
  selectEvent,
  selectRange,
  updateEvent,
} from "../../slices/calendar";
import { useDispatch, useSelector } from "../../store";
import moment from "moment";
import SelectUserDropdown from "../../components/SelectUserDropdown";
import useAuth from "../../hooks/useAuth";
import { Add, AlarmAdd } from "@material-ui/icons";
import AddReminderModal from "../../components/dashboard/calendar/AddReminder";
import ShareCalendar from "../../components/dashboard/calendar/ShareCalendar";

const selectedEventSelector = (state) => {
  const { events, selectedEventId } = state.calendar;

  if (selectedEventId) {
    return events.find((_event) => _event.id === selectedEventId);
  }

  return null;
};

const FullCalendarWrapper = experimentalStyled("div")(({ theme }) => ({
  "& .fc-license-message": {
    display: "none",
  },
  "& .fc": {
    "--fc-bg-event-opacity": 1,
    "--fc-border-color": theme.palette.divider,
    "--fc-daygrid-event-dot-width": "10px",
    "--fc-event-text-color": theme.palette.text.primary,
    "--fc-list-event-hover-bg-color": theme.palette.background.default,
    "--fc-neutral-bg-color": theme.palette.background.default,
    "--fc-page-bg-color": theme.palette.background.default,
    "--fc-today-bg-color": alpha(theme.palette.primary.main, 0.25),
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
  "& .fc .fc-col-header-cell-cushion": {
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  "& .fc .fc-day-other .fc-daygrid-day-top": {
    color: theme.palette.text.secondary,
  },
  "& .fc-daygrid-event": {
    padding: "10px",
  },
  "& .fc-timeGridWeek-view": {
    "& .fc-scrollgrid-section-liquid": {
      display: "none",
    },
    "& .projectric-all-day": {
      height: "600px",
    },
  },
  "& .fc-timeGridDay-view": {
    "& .fc-scrollgrid-section-liquid": {
      display: "none",
    },
    "& .projectric-all-day": {
      height: "600px",
    },
  },
  "& .fc-dayGridMonth-view": {
    "& .fc-daygrid-day-number": {
      width: "100%",
    },
  },
}));

const Calendar = () => {
  const dispatch = useDispatch();
  const calendarRef = useRef(null);
  const mobileDevice = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { events, isModalOpen, selectedRange } = useSelector(
    (state) => state.calendar
  );
  const selectedEvent = useSelector(selectedEventSelector);
  const [date, setDate] = useState(new Date());
  const [selectedUser, setSelectedUser] = useState(null);
  const [reminderDate, setReminderDate] = useState(null);
  const [view, setView] = useState(mobileDevice ? "listWeek" : "dayGridMonth");
  const { user } = useAuth();

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const startDate = moment(calendarApi.view.activeStart).format(
        "YYYY-MM-DD"
      );
      const endDate = moment(calendarApi.view.activeEnd).format("YYYY-MM-DD");
      if (user?.role === 1 || user?.role === 2) {
        dispatch(getEvents(selectedUser?.id || user.id, startDate, endDate));
      } else {
        dispatch(getEvents(user.id, startDate, endDate));
      }
    }
  }, [date, selectedUser]);

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = mobileDevice ? "listWeek" : "dayGridMonth";

      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [mobileDevice]);

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      if (view === "timeGridWeek") {
        calendarApi.today();
        const startDate = calendarApi.getDate();
        startDate.setDate(startDate.getDate() - startDate.getDay());
        setDate(startDate);
      }
      // calendarApi.gotoDate(startDate);
      if (view === "timeGridDay") {
        calendarApi.today();
        setDate(calendarApi.getDate());
      }
    }
  }, [view]);

  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };

  const handleAddClick = () => {
    dispatch(openModal());
  };

  const handleRangeSelect = (arg) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }

    dispatch(selectRange(arg.start.getTime(), arg.end.getTime()));
  };

  const handleEventSelect = (arg) => {
    dispatch(selectEvent(arg.event.id));
  };

  const handleAddReminder = (date) => {
    setReminderDate(date);
  };

  const handleEventResize = async ({ event }) => {
    try {
      await dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleEventDrop = async ({ event }) => {
    try {
      await dispatch(
        updateEvent(event.id, {
          allDay: event.allDay,
          start: event.start,
          end: event.end,
        })
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleModalClose = () => {
    dispatch(closeModal());
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Calendar</title>
      </Helmet>
      {reminderDate ? (
        <AddReminderModal
          date={reminderDate}
          onClose={() => setReminderDate(null)}
          showAddReminderModal={reminderDate !== null}
          userId={selectedUser?.id || user.id}
        />
      ) : (
        ""
      )}
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 8,
        }}
      >
        <Container maxWidth={false}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography color="textPrimary" variant="h5">
                Calendar
              </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/"
                  variant="subtitle2"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="subtitle2">
                  Calendar
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  display: "flex",
                }}
              >
                <ShareCalendar userId={selectedUser?.id || user.id} />
                <Box sx={{ mr: 1 }}>
                  {user.role === 1 || user.role === 2 ? (
                    <SelectUserDropdown setSelectedUser={setSelectedUser} />
                  ) : (
                    <></>
                  )}
                </Box>
                {/* <Box sx={{ ml: 1 }}>
                  {user.role === 1 || user.role === 2 ? (
                    <SelectProjectDropdown
                      setSelectedProject={setSelectedProject}
                    />
                  ) : (
                    <></>
                  )}
                </Box> */}
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <CalendarToolbar
              date={date}
              onDateNext={handleDateNext}
              onDatePrev={handleDatePrev}
              onDateToday={handleDateToday}
              onViewChange={handleViewChange}
              view={view}
            />
          </Box>
          <Card
            sx={{
              mt: 3,
              p: 2,
            }}
          >
            <FullCalendarWrapper>
              <FullCalendar
                // all Day row class
                allDayClassNames="projectric-all-day"
                dayMaxEventRows={4}
                // droppable
                // editable
                eventClick={handleEventSelect}
                eventDisplay="block"
                eventDrop={handleEventDrop}
                eventResizableFromStart
                eventResize={handleEventResize}
                displayEventTime={false}
                events={events}
                headerToolbar={false}
                height={view === "listWeek" ? "auto" : 800}
                initialDate={date}
                initialView={view}
                weekends={true}
                plugins={[
                  dayGridPlugin,
                  interactionPlugin,
                  listPlugin,
                  timeGridPlugin,
                  timelinePlugin,
                ]}
                // Customize cell in Month view to show number of events
                dayCellContent={(arg) => {
                  const events = arg.view.calendar.getEvents();
                  console.log(arg);
                  const dayEvents = events.filter((event) => {
                    return (
                      event.start.getDate() === arg.date.getDate() &&
                      event.start.getMonth() === arg.date.getMonth() &&
                      event.start.getFullYear() === arg.date.getFullYear()
                    );
                  });
                  const totalUtilization = dayEvents.reduce(
                    (acc, event) => acc + event._def.extendedProps.utilization,
                    0
                  );

                  const totalReminderCount = dayEvents.reduce(
                    (acc, event) =>
                      acc + event._def.extendedProps.reminder_count,
                    0
                  );
                  return (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{arg.dayNumberText}</div>
                      </div>
                      <Tooltip title={`Add`}>
                        <IconButton
                          className="add-reminder-btn"
                          onClick={() => handleAddReminder(arg.date)}
                          size="small"
                          sx={{
                            position: "absolute",
                            right: 1,
                            top: 1,
                            color: "primary.blue",
                            opacity: 0.5,
                            "&:hover": {
                              opacity: 1,
                            },
                          }}
                        >
                          <Badge
                            badgeContent={totalReminderCount}
                            color="secondary"
                          >
                            <AlarmAdd
                              fontSize="small"
                              sx={{
                                color:
                                  totalReminderCount > 0
                                    ? "red"
                                    : "primary.blue",
                              }}
                            />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    </div>
                  );
                }}
                ref={calendarRef}
                rerenderDelay={10}
                select={handleRangeSelect}
                selectable
              />
            </FullCalendarWrapper>
          </Card>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={selectedEvent && isModalOpen}
          >
            {/* Dialog renders its body even if not open */}
            {isModalOpen && (
              <CalendarEventForm
                event={selectedEvent}
                onAddComplete={handleModalClose}
                onCancel={handleModalClose}
                onDeleteComplete={handleModalClose}
                onEditComplete={handleModalClose}
                range={selectedRange}
              />
            )}
          </Dialog>
        </Container>
      </Box>
    </>
  );
};

export default Calendar;
