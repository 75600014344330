import React, { useState } from "react";
import { Box, Typography, Tooltip, Link } from "@material-ui/core";
import toast from "react-hot-toast";
import { updateProjectStage } from "../../../api/project/Project";
import useCommons from "../../../hooks/useCommons";
import { Link as RouterLink } from "react-router-dom";
import PencilIcon from "../../../icons/PencilAlt";

const ProjectStageGraphic = ({ selectedProject, getProject }) => {
  const { stages } = useCommons();
  const [hover, setHover] = useState(false);

  const updateStage = async (stage) => {
    try {
      const res = await updateProjectStage(selectedProject.id, stage);
      if (res.data.success) {
        toast.success("Stage Updated Successfully");
        getProject();
      }
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong cann't update stage");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        pb: 4,
        pt: 1,
        ml: 3,
        mr: 3,
      }}
    >
      <Tooltip title="Edit Workflow">
        <Box
          className="stage-arrow stage-left-arrow"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Link
            component={RouterLink}
            underline="none"
            to="/dashboard/admin-setting/stages-setting"
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              justifyContent: "center",
            }}
          >
            <PencilIcon
              size="small"
              sx={{ fontSize: "14px", mr: 0.5, mb: -0.2 }}
            />
            Project Flow{" "}
          </Link>
        </Box>
      </Tooltip>
      {stages.map((stage, index) => (
        <Box
          title="Click to update project stage"
          key={stage.id}
          onClick={() => updateStage(stage.id)}
          className={`stage-arrow stage-right-arrow
          } ${selectedProject?.stage === stage.id ? "active" : null}`}
        >
          <Typography>{stage.stage}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default ProjectStageGraphic;
