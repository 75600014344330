const projectStatus = [
  {
    label: "Work In Progress",
    color: "#FFBF00",
    value: 1,
  },
  {
    label: "Completed",
    color: "green",
    value: 2,
  },
  {
    label: "Delayed",
    color: "#ff0000",
    value: 3,
  },
];

export default projectStatus;
