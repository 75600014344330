import { Box, CircularProgress, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { getAllDesignationUtilization } from "../../../api/calender/Calender";

const AvailabilityChart = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getAllDesignationUtilization();
        const allData = res.data.data.values || [];

        // Filter the data to keep only the relevant designations
        const relevantDesignations = [
          "Manager",
          "CEO",
          "Developer",
          "QA",
          "Consultant",
        ];
        const filteredData = allData.filter((item) =>
          relevantDesignations.includes(item.designation)
        );

        setData(allData);
      } catch (err) {
        setError(err.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  const options = {
    chart: {
      id: "utilization-bar",
    },
    xaxis: {
      categories: data.map((item) => item.designation),
      title: { text: "Designation" },
      labels: { style: { fontSize: "8px" } },
    },
    yaxis: {
      title: { text: "Availability %" },
      labels: { formatter: (val) => Math.round(val) },
    },
    title: {
      text: "Availability % by Designation",
      align: "left",
    },
  };

  const series = [
    {
      name: "Availability %",
      data: data.map((item) =>
        100 - item.utilization_percentage > 0
          ? 100 - item.utilization_percentage
          : 0
      ),
    },
  ];

  return (
    <Box style={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={400}
      />
    </Box>
  );
};

export default AvailabilityChart;
