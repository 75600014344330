import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, TableCell, TableRow } from "@material-ui/core";
import TrashIcon from "../../../icons/Trash";
import { deletePortfolio } from "../../../api/portfolio/Portfolio";
import toast from "react-hot-toast";
import PermissionGuard from "../../PermissionGuard";
import DeleteModal from "../../DeleteModal";

const SingleCredentialInfo = ({ portfolio, getAllPortfolios }) => {
  const [open, setOpen] = useState(false);
  const removePortfolio = async () => {
    const res = await deletePortfolio(portfolio.id);
    if (res.data.success) {
      toast.success("Project removed successfully");
      getAllPortfolios();
    }
  };

  return (
    <>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        onYesClick={removePortfolio}
        warningMessage="Are you sure you want to delete this portfolio?"
      />
      <TableRow hover>
        <TableCell>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/dashboard/portfolios/view-and-edit-portfolio/${portfolio.id}`}
            underline="none"
            variant="subtitle2"
            className="redirect-link"
          >
            {portfolio.name}
          </Link>
        </TableCell>
        <TableCell>
          {portfolio.get_status ? portfolio.get_status.status : "-"}
        </TableCell>
        {/* <TableCell>10</TableCell> */}
        <TableCell>
          {portfolio.get_contact_person
            ? portfolio.get_contact_person.first_name
            : "-"}
        </TableCell>
        <TableCell>
          {portfolio.get_contact_person
            ? portfolio.get_contact_person.email
            : "-"}
        </TableCell>
        <TableCell align="right">
          {portfolio.created_on ? portfolio.created_on : "-"}
        </TableCell>
        <PermissionGuard permissionCheck="PORTFOLIO_DELETE" returnEmpty={true}>
          <TableCell align="center">
            <TrashIcon
              title="Delete"
              onClick={() => {
                setOpen(true);
              }}
              className="action-icon"
            />
          </TableCell>
        </PermissionGuard>
      </TableRow>
    </>
  );
};

export default SingleCredentialInfo;
