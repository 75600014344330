import { handleApiRequest } from "../../hooks/callApi";

export const addNewDepartment = async ({ departmentName, departmentHead }) => {
  const body = {
    name: departmentName,
    dept_head: departmentHead,
  };
  const res = await handleApiRequest(`/api/department`, "post", body);

  return res;
};

// Get all department
export const getAlldepartment = async ({ page }) => {
  const res = await handleApiRequest(
    `/api/departments?page=${page || 1}`,
    "get"
  );

  return res;
};

// Edit department
export const editDepartment = async ({
  departmentId,
  departmentName,
  departmentHead,
}) => {
  const body = {
    name: departmentName,
    dept_head: departmentHead,
  };
  const res = await handleApiRequest(
    `/api/department/${departmentId}`,
    "put",
    body
  );

  return res;
};

// remove department
export const removeDepartment = async (departmentId) => {
  const res = await handleApiRequest(
    `/api/department/${departmentId}`,
    "delete"
  );
  return res;
};

// Get departments for dropdown
export const getDepartmentsForDropdown = async () => {
  const res = await handleApiRequest(`/api/department/dropdown`, "get");
  return res;
};

export const getDesignationsForDropdown = async () => {
  const res = await handleApiRequest(`/api/designation/dropdown`, "get");
  return res;
};
