const skills = [
  {
    value: 1,
    title: "Communication",
  },
  {
    value: 2,
    title: "Cost Management",
  },
  {
    value: 3,
    title: "Critical Thinking",
  },
  {
    value: 4,
    title: "Data analysis",
  },
  {
    value: 5,
    title: "Designing",
  },
  {
    value: 6,
    title: "Documentation",
  },
  {
    value: 7,
    title: "Planning & forecasting",
  },
  {
    value: 8,
    title: "Problem solving",
  },
  {
    value: 9,
    title: "Quality Management",
  },
  {
    value: 10,
    title: "Reporting",
  },
  {
    value: 11,
    title: "Research",
  },
  {
    value: 12,
    title: "Risk management",
  },
  {
    value: 13,
    title: "Software skills",
  },
  {
    value: 14,
    title: "Time management",
  },
  {
    value: 15,
    title: "Tracking & monitoring",
  },
];

export default skills;
