import ViewAllPortfoliosListTable from "../../components/dashboard/portfolios/ViewAllPortfoliosListTable";
import usePortfolio from "../../hooks/usePortfolio";
import PageLayout from "../../layouts/PageLayout";

const ViewAllPortfolios = () => {
  const { getAllPortfolio, allPortfolio, totalPortfolioCount } = usePortfolio();
  const handleAllPortfolio = (pageNum, portfolioType, portfolioName) =>
    getAllPortfolio(pageNum, portfolioType, portfolioName);

  return (
    <PageLayout
      title={"Dashboard: View All Portfolios"}
      currentPageName={"Portfolios"}
      subtitle={`Total Portfolio: ${totalPortfolioCount}`}
    >
      <ViewAllPortfoliosListTable
        allPortfolio={allPortfolio}
        handleAllPortfolio={handleAllPortfolio}
      />
    </PageLayout>
  );
};

export default ViewAllPortfolios;
