import { Box, Container, Divider, Typography } from "@material-ui/core";
const Footer = (props) => (
  <Box
    sx={{
      backgroundColor: "background.default",
      pb: 2,
      pt: {
        md: 4,
        xs: 2,
      },
    }}
    {...props}
  >
    <Container maxWidth="lg">
      <Divider />
      <Typography color="textSecondary" variant="caption">
        Copyright © 2023 Projectric. All rights reserved.
      </Typography>
    </Container>
  </Box>
);

export default Footer;
