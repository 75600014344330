import { handleApiRequest } from "../../hooks/callApi";

export const projectTimeGraph = async (id, authToken) => {
  const URL = authToken
    ? `/api/shared/time/${id}`
    : `/api/projectDashboard/time/${id}`;
  const res = await handleApiRequest(URL, "get");
  return res;
};

export const projectProgressGraph = async (id, authToken) => {
  const URL = authToken
    ? `/api/shared/progress/${id}`
    : `/api/projectDashboard/progress/${id}`;
  const res = await handleApiRequest(URL, "get");
  return res;
};

export const projectTaskGraph = async (id, authToken) => {
  const URL = authToken
    ? `/api/shared/task/${id}`
    : `/api/projectDashboard/task/${id}`;
  const header = authToken ? { sharedKey: authToken } : {};

  const res = await handleApiRequest(URL, "get", null, header);
  return res;
};

export const projectWorkloadGraph = async (id, authToken) => {
  const URL = authToken
    ? `/api/shared/members/${id}`
    : `/api/projectDashboard/members/${id}`;
  const res = await handleApiRequest(URL, "get");
  return res;
};

export const projectCostGraph = async (id, authToken) => {
  const URL = authToken
    ? `/api/shared/budget/${id}`
    : `/api/projectDashboard/budget/${id}`;
  const res = await handleApiRequest(URL, "get");
  return res;
};

export const projectHealthGraph = async (id, authToken) => {
  const URL = authToken
    ? `/api/shared/health/${id}`
    : `/api/projectDashboard/health/${id}`;
  const header = authToken ? { sharedKey: authToken } : {};
  const res = await handleApiRequest(URL, "get", null, header);
  return res;
};

export const getPeopleUtilization = async (projectId, shared, authToken) => {
  const header = authToken ? { sharedKey: authToken } : {};
  const URL = shared
    ? `/api/shared/people-utilization`
    : `/api/people-utilization`;
  const res = await handleApiRequest(
    `${URL}/${projectId}`,
    "get",
    null,
    header
  );
  return res;
};

export const getSharedTaskList = async (projectId, authToken) => {
  const header = authToken ? { sharedKey: authToken } : {};
  const res = await handleApiRequest(
    `/api/shared/project-tasks/${authToken}`,
    "get",
    null,
    header
  );
  return res;
};
