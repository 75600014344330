import React from "react";
import useAuth from "../hooks/useAuth";
// import permissions from "../@const/permissions";

const PermissionGuard = ({ permissionCheck, returnEmpty, children }) => {
  const { permissions, user } = useAuth();
  if (permissionCheck && !permissions[permissionCheck] && user.role !== 1) {
    return <></>;
  }
  return <>{children}</>;
};

export default PermissionGuard;

// if (returnEmpty) {
//   return <></>;
// } else {
//   window.location.replace(
//     `${process.env.REACT_APP_FRONTEND_URL}/un-authenticated`
//   );
//   return;
// }
