import React, { useEffect } from "react";
import { getMilestoneReport } from "../api/portfolio/Portfolio";

const Test = () => {
  useEffect(() => {
    const getMilestones = async () => {
      const res = await getMilestoneReport(232);
      console.log(res);
    };
    getMilestones();
  }, []);

  return <div>Test</div>;
};

export default Test;
