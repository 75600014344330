import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getMilestoneReport } from "../../../api/portfolio/Portfolio";

const MilestoneReport = () => {
  const [reportData, setReportData] = useState([]);
  useEffect(() => {
    getMilestones();
  }, []);

  const getMilestones = async () => {
    const res = await getMilestoneReport(230);
    console.log(res);
    setReportData(res?.data?.data?.values);
  };
  return (
    <>
      {reportData?.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Project</TableCell>
              <TableCell>Stage</TableCell>
              <TableCell>Milestone</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Budget</TableCell>
              <TableCell>Actual</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{reportData[0].project.name}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            {reportData.map((stage, index) => (
              <>
                <TableRow key={index}>
                  <TableCell></TableCell>
                  <TableCell>{stage.stage}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {stage.milestones.map((milestone, index) => (
                  <TableRow key={index}>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>{milestone.name}</TableCell>
                    <TableCell>{milestone.start_date}</TableCell>
                    <TableCell>{milestone.end_date}</TableCell>
                    <TableCell>{milestone.budget}</TableCell>
                    <TableCell>{milestone.actual}</TableCell>
                  </TableRow>
                ))}
              </>
            ))}

            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Year 1</TableCell>
              <TableCell>Year 2</TableCell>
              <TableCell>Year 3</TableCell>
              <TableCell>Year 4</TableCell>
              <TableCell>Year 5</TableCell>
            </TableRow>
            <TableRow
              sx={{
                "&:nth-of-type(odd)": {
                  backgroundColor: "#f8c787d6",
                },
              }}
            >
              <TableCell>Financial</TableCell>
              <TableCell>Required Investment</TableCell>
              <TableCell>$2,000,000</TableCell>
              <TableCell>$1,000,000</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow
              sx={{
                td: {
                  backgroundColor: "#5ad274d6",
                },
              }}
            >
              <TableCell></TableCell>
              <TableCell>Projected Revenue</TableCell>
              <TableCell></TableCell>
              <TableCell>$1,200,000</TableCell>
              <TableCell>$12,000,000</TableCell>
              <TableCell>$58,500,000</TableCell>
              <TableCell>$151,500,000</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        ""
      )}
    </>
  );
};

export default MilestoneReport;
