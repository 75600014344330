import { Autocomplete, CircularProgress, TextField } from "@material-ui/core";
import React from "react";
import { useFetch } from "../../hooks/useAPI";

export default function TaskPointsAutocomplete({
  points,
  onChange,
  point_score,
  variant,
}) {
  const { data: taskPoints, loading } = useFetch(`/api/sprint-point-scores`);

  if (!taskPoints?.length) {
    return <CircularProgress />;
  }
  return (
    <>
      <Autocomplete
        loading={loading}
        options={taskPoints}
        getOptionLabel={(option) => `${option.points}`}
        value={points ? point_score : null}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Points"
            name="points"
            required
            fullWidth
            variant={variant}
          />
        )}
      />
    </>
  );
}
