import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { editTemplate } from "../../../api/project/Project";
import useMounted from "../../../hooks/useMounted";

const EditTemplateModal = ({
  showEditTemplateModal,
  setShowEditTemplateModal,
  template,
  getAllTemplates,
}) => {
  const mounted = useMounted();
  return (
    <Dialog
      open={showEditTemplateModal}
      onClose={() => setShowEditTemplateModal(false)}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <Formik
        initialValues={{
          title: template.title,
          description: template.description,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required("Title is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const res = await editTemplate(
              template.id,
              values.title,
              values.description
            );

            if (res.data.success) {
              toast.success("Template edited");
              getAllTemplates();
              setShowEditTemplateModal(false);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            toast.error("Something went wrong");
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <DialogTitle>Save As Template</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Title"
                    required
                    error={Boolean(touched.title && errors.title)}
                    fullWidth
                    helperText={touched.title && errors.title}
                    margin="normal"
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.title}
                    variant="outlined"
                    sx={{ mt: 0 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="description"
                    error={Boolean(touched.description && errors.description)}
                    fullWidth
                    helperText={touched.description && errors.description}
                    margin="normal"
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                    variant="outlined"
                    sx={{ mt: 0 }}
                  />
                </Grid>
              </Grid>

              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                <Box>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Button
                    disabled={isSubmitting}
                    size="large"
                    variant="contained"
                    onClick={() => setShowEditTemplateModal(false)}
                    sx={{ backgroundColor: "#C0CBDE" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default EditTemplateModal;
