import { Box, Link, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
const SingleTaskBoardView = ({ task, index }) => {
  return (
    <>
      <TableRow hover>
        <TableCell>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/dashboard/projects/view-and-edit-project/${task.id}`}
            underline="none"
            variant="subtitle2"
          >
            {task.name}
          </Link>
        </TableCell>
        <TableCell>
          {task.workInProgress ? (
            <Box
              sx={{
                height: "18px",
                width: "18px",
                borderRadius: "18px",
                backgroundColor: "#7BDFE5",
                mx: "auto",
              }}
            ></Box>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>
          {task.todo ? (
            <Box
              sx={{
                height: "18px",
                width: "18px",
                borderRadius: "18px",
                backgroundColor: "#ffbf00",
                mx: "auto",
              }}
            ></Box>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>
          {task.complete ? (
            <Box
              sx={{
                height: "18px",
                width: "18px",
                borderRadius: "18px",
                backgroundColor: "#00ff00",
                mx: "auto",
              }}
            ></Box>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>
          {task.onHold ? (
            <Box
              sx={{
                height: "18px",
                width: "18px",
                borderRadius: "18px",
                backgroundColor: "#ff0000",
                mx: "auto",
              }}
            ></Box>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell>
          {task.closed ? (
            <Box
              sx={{
                height: "18px",
                width: "18px",
                borderRadius: "18px",
                backgroundColor: "#00ff00",
                mx: "auto",
              }}
            ></Box>
          ) : (
            ""
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleTaskBoardView;
