import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import { Link as BrowserRouter } from "react-router-dom";
import * as Yup from "yup";
import useAuth from "../../hooks/useAuth";
import useMounted from "../../hooks/useMounted";

const LogInForm = (props) => {
  const { setLoginErrorModalState, setErrorMessage } = props;
  const mounted = useMounted();
  const { login } = useAuth();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const res = await login(values.email, values.password);
          if (!res?.cred) {
            if (res?.message) {
              setErrorMessage(res.message);
            }
            setLoginErrorModalState(true);
          }
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            autoComplete="new-password"
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            autoComplete="new-password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            inputProps={{
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
            }}
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Login
            </Button>
          </Box>
          <Box sx={{ mt: 6 }}>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "15px",
              }}
              align="center"
            >
              Haven’t created an account yet? Register now!
            </Typography>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button
              color="primary"
              fullWidth
              size="large"
              variant="outlined"
              component={BrowserRouter}
              to="/authentication/register"
            >
              Register
            </Button>
          </Box>
          <Box sx={{ mt: 2, mx: "auto" }}>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "15px",
                textAlign: "center",
                mx: "auto",
                display: "inherit",
              }}
              component={BrowserRouter}
              to={`/authentication/forgot-password?e=${values.email}`}
            >
              Forgot Password
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LogInForm;
