import React, { useEffect } from "react";
import { setDefaultProjectTasks } from "../../api/project/Project";

const Secure = () => {
  useEffect(() => {
    console.log("Secure generating all tasks orders");
    setDefaultProjectTasks();
  }, []);

  return <div>Secure</div>;
};

export default Secure;
