const projectComplexity = [
  {
    value: 1,
    title: "Low",
  },
  {
    value: 2,
    title: "Medium",
  },
  {
    value: 3,
    title: "High",
  },
];

export default projectComplexity;
