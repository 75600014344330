import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  getSavedReports,
  getTaskReports,
} from "../../../../api/reports/CustomReport";
import useAuth from "../../../../hooks/useAuth";
import SaveFilterModal from "./SaveFilterModal";
import SearchFilters from "./SearchFilters";

let configs = {};
const filterParams = {
  debounceMs: 500,
  suppressAndOrCondition: true,
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    if (cellValue == null) {
      return 0;
    }
    const dateParts = cellValue.split("-");
    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[2]);
    const cellDate = new Date(year, month, day);
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  },
};

function dateComparator(date1, date2) {
  var date1Number = _monthToNum(date1);
  var date2Number = _monthToNum(date2);

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }

  return date1Number - date2Number;
}
// HELPER FOR DATE COMPARISON
function _monthToNum(date) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }

  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);

  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  // 29/08/2004 => 20040829
  return result;
}

const CustomTask = ({ selectedFilter, setSelectedFilter }) => {
  const { user } = useAuth();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [defaultGridState, setDefaultGridState] = useState(null);
  // const [configs, setConfigs] = useState({});
  const [rowData, setRowData] = useState(null);
  const [savedFilters, setSavedFilters] = useState([]);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState("");
  const [saveFilterModalOpen, setSaveFilterModalOpen] = useState(false);
  const [isPriviewModeActive, setIsPriviewModeActive] = useState(false);

  useEffect(() => {
    getTaskSavedReports();
  }, []);

  useEffect(() => {
    if (selectedFilter?.filter_data && gridColumnApi && gridApi) {
      update(selectedFilter.filter_data);
      setSelectedSavedFilter(selectedFilter);
      setSelectedFilter(null);
      activatePreview();
    }
  }, [gridColumnApi, gridApi]);

  const onGridReady = async (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    setDefaultGridState({
      filterModel: params?.api?.getFilterModel(),
      columnState: params?.columnApi?.getColumnState(),
      columnGroupState: params?.columnApi?.getColumnGroupState(),
      pivotMode: params?.columnApi?.isPivotMode(),
    });

    const updateData = (data) => {
      params.api.setRowData(data);
    };

    const res = await getTaskReports();
    updateData(res.data.data.item);

    agGridEvent(params);

    console.log(params);
  };

  const getProjectName = (params) => {
    if (params.data) {
      return params.data.project_name;
    }

    if (params.node.allLeafChildren.length > 0) {
      return params.node.allLeafChildren[0].data.project_name;
    }

    return params.getValue();
  };

  const onPivotMode = () => {
    console.log("Switched to Pivot mode");
  };

  const milestonePivot = (params) => {
    if (params.data) {
      return params.data.milestone_name;
    }
    if (params.node.allLeafChildren.length > 0) {
      return params.node.allLeafChildren[0].data.milestone_name;
    }
    return params.getValue();
  };

  const agGridEvent = (props) => {
    props.api.addEventListener("firstDataRendered", changeHandler);
    props.api.addEventListener("filterChanged", changeHandler);
    props.api.addEventListener("filterModified", changeHandler);
    props.api.addEventListener("sortChanged", changeHandler);
    props.api.addEventListener("columnValueChanged", changeHandler);
    props.api.addEventListener("columnResized", changeHandler);
    props.api.addEventListener("columnVisible", changeHandler);
    props.api.addEventListener("columnPinned", changeHandler);
    props.api.addEventListener("columnResized", changeHandler);
    props.api.addEventListener("newColumnsLoaded", changeHandler);
    props.api.addEventListener("gridColumnsChanged", changeHandler);
    props.api.addEventListener("displayedColumnsChanged", changeHandler);
    props.api.addEventListener("rowGroupOpened", changeHandler);
    props.api.addEventListener("expandOrCollapseAll", changeHandler);
  };

  const changeHandler = (params) => {
    configs.filterModel = params?.api?.getFilterModel();
    configs.columnState = params?.columnApi?.getColumnState();
    configs.columnGroupState = params?.columnApi?.getColumnGroupState();
    configs.pivotMode = params?.columnApi?.isPivotMode();
  };

  const getTaskSavedReports = async () => {
    const res = await getSavedReports("task");
    if (res?.data?.data?.items?.length > 0) {
      setSavedFilters(res.data.data.items);
    }
  };

  const updateFilters = (e, val) => {
    if (val) {
      console.log(val);
      const value = val.id;
      setSelectedSavedFilter(val);
      const selectedFilter = savedFilters.filter(
        (filter) => filter.id === value
      );
      if (selectedFilter.length && selectedFilter[0]?.filter_data) {
        update(selectedFilter[0]?.filter_data);
      }
      activatePreview();
    } else {
      setIsPriviewModeActive(false);
      activateDefaultState();
      setSelectedSavedFilter(null);
    }
  };

  const update = (filterData) => {
    const savedFilters = JSON.parse(filterData);
    const isPivotModeActive = savedFilters.pivotMode;
    gridColumnApi.setPivotMode(isPivotModeActive);
    gridApi.setFilterModel(savedFilters.filterModel);
    gridColumnApi.applyColumnState({
      state: savedFilters.columnState,
      applyOrder: true,
    });
    gridColumnApi.setColumnGroupState(savedFilters.columnGroupState);
  };

  const activatePreview = () => {
    setIsPriviewModeActive(true);
    closeToolPanel();
  };

  const activateDefaultState = () => {
    if (defaultGridState) {
      const isPivotModeActive = defaultGridState?.pivotMode;
      gridColumnApi.setPivotMode(isPivotModeActive);
      gridApi.setFilterModel(defaultGridState?.filterModel);
      gridColumnApi.applyColumnState({
        state: defaultGridState?.columnState,
        applyOrder: true,
      });
      gridColumnApi.setColumnGroupState(defaultGridState?.columnGroupState);
    }
  };

  const closeToolPanel = useCallback(() => {
    gridRef.current.api.closeToolPanel();
  }, []);
  return (
    <>
      {saveFilterModalOpen && (
        <SaveFilterModal
          view="task"
          filterData={configs}
          saveFilterModalOpen={saveFilterModalOpen}
          setSaveFilterModalOpen={setSaveFilterModalOpen}
          getTaskSavedReports={getTaskSavedReports}
        />
      )}
      <Box sx={{ width: "100%", height: "85vh" }}>
        <div className="example-wrapper custom-task">
          <SearchFilters
            isPriviewModeActive={isPriviewModeActive}
            selectedSavedFilter={selectedSavedFilter}
            setSaveFilterModalOpen={setSaveFilterModalOpen}
            savedFilters={savedFilters}
            updateFilters={updateFilters}
            setIsPriviewModeActive={setIsPriviewModeActive}
            getTaskSavedReports={getTaskSavedReports}
          />

          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              defaultColDef={{
                flex: 1,
                minWidth: 150,
                sortable: true,
                resizable: true,
                filter: true,
              }}
              ref={gridRef}
              autoGroupColumnDef={{ minWidth: 250, pinned: "left" }}
              // sideBar={"columns"}
              sideBar={true}
              onGridReady={onGridReady}
              rowData={rowData}
              onColumnPivotModeChanged={onPivotMode}
              functionsReadOnly={user.custom_report === 1 ? false : true}
              // columnDefs={colDefs}
            >
              <AgGridColumn
                field="portfolio_name"
                rowGroup={true}
                enableRowGroup={true}
                enablePivot={true}
                headerName="Portfolio"
                hide={true}
                // pivot={true}
              />
              <AgGridColumn
                field="proj_id"
                enablePivot={true}
                rowGroup={true}
                enableRowGroup={true}
                headerName="Project"
                hide={true}
                cellRenderer={getProjectName}
                filterValueGetter={getProjectName}
              />
              <AgGridColumn
                field="name"
                // rowGroup={true}
                enableRowGroup={true}
                enablePivot={true}
                // pivot={true}
                headerName="Task"
              />
              <AgGridColumn
                field="start_date"
                enablePivot={true}
                headerName="Task Start Date"
                sortable={true}
                filter={true}
                comparator={dateComparator}
                filterParams={filterParams}
              />
              <AgGridColumn
                field="project_start_date"
                enablePivot={true}
                headerName="Project Start Date"
                sortable={true}
                filter={true}
                comparator={dateComparator}
                filterParams={filterParams}
              />
              <AgGridColumn
                field="project_due_date"
                enablePivot={true}
                headerName="Project End Date"
                sortable={true}
                filter={true}
                comparator={dateComparator}
                filterParams={filterParams}
              />
              <AgGridColumn
                field="portfolio_created_on"
                enablePivot={true}
                headerName="Portfolio Start Date"
                sortable={true}
                filter={true}
                comparator={dateComparator}
                filterParams={filterParams}
              />
              <AgGridColumn
                field="end_date"
                headerName="Task End Date"
                // valueFormatter= {dateFormatter}
                comparator={dateComparator}
                filterParams={filterParams}
                // floatingFilter={true}
                floatingFilterComponentParams={{
                  suppressFilterButton: true,
                }}
              />
              <AgGridColumn field="task_type" headerName="Type" />
              {/* <AgGridColumn field="skills" headerName="Skills" /> */}
              <AgGridColumn
                field="milestone"
                enablePivot={true}
                rowGroup={true}
                enableRowGroup={true}
                initialHide={true}
                headerName="Milestone"
                cellRenderer={milestonePivot}
                keyCreator={(params) => params?.data?.milestone_name}
                // Change row color when row is grouped
                cellStyle={() => {
                  return { color: "#f00" };
                }}
              />
              <AgGridColumn
                field="assigned_name"
                headerName="Asigned To"
                enableRowGroup={true}
              />
              <AgGridColumn
                field="task_priority"
                headerName="Priority"
                enableRowGroup={true}
              />
              <AgGridColumn
                field="task_status"
                headerName="Status"
                enableRowGroup={true}
              />
              <AgGridColumn
                field="estimate_hours"
                headerName="Estimate Hours"
                aggFunc="sum"
              />
              <AgGridColumn
                field="budget"
                headerName="Budget"
                filter="agNumberColumnFilter"
                aggFunc="sum"
              />
              {/* <AgGridColumn
              field="year"
              rowGroup={true}
              enableRowGroup={true}
              enablePivot={true}
            /> */}
              {/* <AgGridColumn field="date" />
            <AgGridColumn field="sport" />
            <AgGridColumn field="silver" aggFunc="sum" />
            <AgGridColumn field="bronze" aggFunc="sum" /> */}
            </AgGridReact>
          </div>
        </div>
      </Box>
    </>
  );
};

export default CustomTask;
