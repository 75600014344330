import { Box, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getSharedPortfolioID } from "../../api/share/Share";
import PortfolioDashboard from "../../components/share/PortfolioDashboard";
import SharedNav from "../../components/SharedNav";
const SharedPortfolioDashboard = () => {
  const { shareId } = useParams();
  const [portfolioDetails, setPortfolioDetails] = useState(null);

  useEffect(() => {
    handleGetPortfolioId();
  }, []);

  const handleGetPortfolioId = async () => {
    const res = await getSharedPortfolioID(shareId);
    if (res.data.data.item) {
      setPortfolioDetails(res.data.data.item);
    }
  };
  return (
    <>
      <SharedNav />
      <Box sx={{ pt: 4, mt: 6 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "primary.main",
              display: "block",
              fontSize: "28px",
              fontWeight: "bold",
              pl: 2,
              textAlign: "center",
            }}
          >
            {portfolioDetails?.name}
          </Typography>
        </Box>
      </Box>
      {portfolioDetails && (
        <PortfolioDashboard
          portfolioID={portfolioDetails.id}
          authToken={shareId}
        />
      )}
    </>
  );
};

export default SharedPortfolioDashboard;
