import React from "react";
import Chart from "react-apexcharts";

const TaskStatusPieChart = ({ data }) => {
  const options = {
    labels: ["Backlog", "To-Do", "In Progress", "QA", "Done"],
    colors: ["#f07", "#29b6f6", "#ff9803", "#9c27b0", "#4caf50"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    title: {
      text: "Task Status Distribution",
      align: "center",
    },
  };

  const series = [
    data?.["backlog"],
    data?.["to-dos"],
    data?.["in-progress"],
    data?.["q/a"],
    data?.["done"],
  ];

  return <Chart options={options} series={series} type="pie" height={250} />;
};

export default TaskStatusPieChart;
