import React, { useState } from "react";
import { Link as BrowserLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box, Container, Divider, Link, Typography } from "@material-ui/core";
import LogInForm from "../../components/Authentication/LogInForm";
import Logo from "../../components/Logo";
import ArrowRight from "../../icons/ArrowRight";
import LoginErrorModal from "../../components/Authentication/LoginErrorModal";

const aboutText = [
  {
    id: 0,
    text: "A complete PPM solution available via the Cloud to unlimited users within your organization.",
  },
  {
    id: 1,
    text: "Identify the most valuable projects with out of the box intake, scoring and selection.",
  },
  {
    id: 2,
    text: "Proactively manage issues, resources and project milestones to make better business decisions.",
  },
  {
    id: 3,
    text: "Support your stakeholders and decision makers with portfolio-level KPIs and metrics via dashboards and graphs.",
  },
  {
    id: 4,
    text: "Ensure that your strategic initiatives have the transparency and governance necessary for your company to adapt and succeed.",
  },
];

const Login = () => {
  const [loginErrorModalState, setLoginErrorModalState] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Please check your credentials and try again."
  );

  const handleLoginErrorModalClose = () => {
    setLoginErrorModalState(false);
    setErrorMessage("Please check your credentials and try again.");
  };
  return (
    <>
      <Helmet>
        <title>Login | Projectric</title>
      </Helmet>
      <Box>
        <LoginErrorModal
          loginErrorModalState={loginErrorModalState}
          handleLoginErrorModalClose={handleLoginErrorModalClose}
          errorMessage={errorMessage}
        />
      </Box>
      <Box>
        <Container
          maxWidth="lg"
          sx={{
            pt: 6,
            pl: {
              lg: 15,
              md: 10,
              sm: 6,
              xs: 2,
            },
            ml: 0,
            mr: 0,
          }}
        >
          <Link component={BrowserLink} to="/">
            <Logo
              sx={{
                height: 33,
                width: 42,
              }}
            />
          </Link>
        </Container>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            lg: "repeat(2, 1fr)",
            xs: "repeat(1, 1fr)",
          },
        }}
      >
        <Box
          sx={{
            backgroundColor: "background.default",
            pt: 8,
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              pl: {
                lg: 15,
                md: 10,
                sm: 6,
                xs: 2,
              },
            }}
          >
            <Typography
              color="textPrimary"
              sx={{ fontWeight: "bold", fontSize: "32px" }}
              variant="h5"
            >
              One stop solution for your
            </Typography>
            <Typography
              color="primary.orange"
              sx={{ fontWeight: "bold", mt: 0.5, fontSize: "32px", mb: 4 }}
              variant="h5"
            >
              Project Portfolio Management
            </Typography>
            {aboutText.map((content) => (
              <Box key={content.id} sx={{ display: "flex", mt: 2 }}>
                <Box sx={{ pr: 2, mt: 0.5 }}>
                  <ArrowRight fontSize="medium" />
                </Box>
                <Box>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    sx={{
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "24px",
                    }}
                  >
                    {content.text}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Container>
        </Box>
        <Box
          sx={{
            pt: 8,
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              pr: {
                lg: 15,
              },
              pl: {
                lg: 5,
              },
            }}
          >
            <Box
              sx={{
                px: { lg: 6, md: 4, sm: 3, xs: 2 },
                py: { lg: 4, md: 4, sm: 3, xs: 2 },
                backgroundColor: "background.paper",
                boxShadow: "0px 3px 4px rgba(45, 62, 86, 0.1)",
              }}
            >
              <Typography
                color="textPrimary"
                variant="h6"
                sx={{
                  pb: 2,
                  fontSize: "24px",
                  fontWeight: "bold",
                  lineHeight: "36px",
                }}
              >
                Login
              </Typography>
              <Box>
                <LogInForm
                  setErrorMessage={setErrorMessage}
                  setLoginErrorModalState={setLoginErrorModalState}
                />
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <Container
        maxWidth="lg"
        sx={{
          mt: 6,
          px: {
            lg: 15,
          },
          py: 3,
        }}
      >
        <Divider />
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "20px",
            textAlign: "justify",
            pt: 1,
          }}
        >
          Projectric is a leading Project Portfolio Management solution that
          organizes project and program data into relevant, actionable
          information for making business decisions. We are a trusted partner
          for organizations that need to perform meaningful analysis, forecast
          outcomes, collaborate in a centralized resource and adapt quickly to
          changing business needs.
        </Typography>
      </Container>
    </>
  );
};

export default Login;
