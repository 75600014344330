import {
  Autocomplete,
  CircularProgress,
  Grid,
  Link,
  TextField,
} from "@material-ui/core";
import { Link as RouterLink, useParams } from "react-router-dom";
import useProject from "../../hooks/useProject";

export default function ProjectMemberAutocomplete({
  selectedMember,
  onChange,
  variant,
}) {
  const { allProjectMembers } = useProject();
  const { projectId } = useParams();
  if (!allProjectMembers?.data?.length) {
    return <CircularProgress />;
  }
  return (
    <>
      <Grid item xs={12}>
        <Autocomplete
          options={allProjectMembers?.data?.map((_) => ({
            id: _.id,
            member_id: _.member_id,
            name:
              _?.get_member_details?.first_name +
              " " +
              _?.get_member_details?.last_name,
          }))}
          getOptionLabel={(option) => option.name}
          value={
            selectedMember
              ? allProjectMembers?.data
                  ?.map((_) => ({
                    id: _.id,
                    member_id: _.member_id,
                    name:
                      _?.get_member_details?.first_name +
                      " " +
                      _?.get_member_details?.last_name,
                  }))
                  .find((u) => u.member_id === selectedMember)
              : null
          }
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Assign a project member to this task"
              name="assignedUser"
              fullWidth
              variant={variant}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        {/* Create a link for adding new project members */}
        <Link
          component={RouterLink}
          to={`/dashboard/projects/view-and-edit-project/${projectId}?p=members`}
        >
          Don't see a project member? Add one here
        </Link>
      </Grid>
    </>
  );
}
