import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem } from '@material-ui/core';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";

const NavItem = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    isLink,
    ...other
  } = props;
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  // Branch
  if (children && !isLink) {
    return (
      <ListItem
        disableGutters
        className={`${!open ? "nav-closed" : "nav-opened"} ${
          !active ? "in-active" : "active nav-opened"
        }`}
        sx={{
          display: "block",
          py: 0,
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? (
              <ArrowDropDownIcon fontSize="small" />
            ) : (
              <ArrowDropUp fontSize="small" />
            )
          }
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: !open ? "#486383" : "#2D3E56",
            fontWeight: !active ? "500" : "bold",
            justifyContent: "flex-start",
            pl: `${paddingLeft}px`,
            pr: "8px",
            py: "12px",
            textAlign: "left",
            textTransform: "none",
            width: "100%",
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  if (isLink) {
    return (
      <ListItem
        disableGutters
        className={`${!open ? "nav-closed" : "nav-opened"} ${
          !active ? "in-active" : "active nav-opened"
        }`}
        sx={{
          display: "block",
          py: 0,
        }}
        {...other}
      >
        <Button
          component={path && RouterLink}
          startIcon={icon}
          sx={{
            color: !active ? "#486383" : "#2D3E56",
            fontWeight: !active ? "500" : "bold",
            justifyContent: "flex-start",
            pl: `${paddingLeft}px`,
            pr: "8px",
            py: "12px",
            textAlign: "left",
            textTransform: "none",
            width: "100%",
            ...(active && {
              color: "primary.main",
              fontWeight: "bold",
              "& svg": {
                color: "primary.main",
              },
            }),
          }}
          variant="text"
          to={path}
        >
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            {title}
          </Box>
          {info}
        </Button>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        py: 0,
      }}
    >
      <Button
        component={path && RouterLink}
        startIcon={icon}
        sx={{
          color: "text.secondary",
          fontWeight: "fontWeightMedium",
          justifyContent: "flex-start",
          textAlign: "left",
          pl: "40px",
          pr: "8px",
          py: "8px",
          textTransform: "none",
          width: "100%",
          ...(active && {
            color: "primary.main",
            fontWeight: "bold",
            "& svg": {
              color: "primary.main",
            },
          }),
        }}
        variant="text"
        to={path}
      >
        <Box
          sx={{
            flexGrow: 1,
            pl: "12px",
            ...(active && {
              borderLeft: "2px solid #F7B31E",
            }),
          }}
        >
          {title}
        </Box>
        {info}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  active: false,
  open: false
};

export default NavItem;
