import axios from "axios";
import React, { Component } from "react";
import Chart from "react-apexcharts";

export default class MilestoneHealth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colors: ["#F44336", "#E91E63", "#9C27B0"],
      series: [
        {
          name: "Green",
          data: [5, 4],
          color: "#AAFF00",
        },
        {
          name: "Yellow",
          data: [8, 4],
          color: "#FFEA00",
        },
        {
          name: "Red",
          data: [3, 5],
          color: "#F44336",
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true,
          },
          zoom: {
            enabled: true,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0,
              },
            },
          },
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
          },
        },
        xaxis: {
          type: "",
          categories: ["Milestone Report", "Hours Health Report"],
        },
        legend: {
          position: "right",
          offsetY: 40,
        },
        fill: {
          opacity: 1,
        },
      },
    };
  }
  async componentDidMount() {
    const red = [];
    const yellow = [];
    const green = [];

    axios
      .get("http://127.0.0.1:8000/api/report/hoursHealth")
      .then((Response) => {
        console.log(Response);
        for (const obj of Response.data) {
          console.log("obj", obj);
          red.push(obj.red);
          yellow.push(obj.yellow);
          green.push(obj.green);
        }
        // this.setState({
        //     series: [{
        //         name: 'Green',
        //         data: green
        //       }, {
        //         name: 'Yellow',
        //         data: yellow
        //       }, {
        //         name: 'Red',
        //         data: red
        //       }]
        // })
      });
  }
  render() {
    return (
      <div id="chart">
        <Chart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
          width={500}
        />
      </div>
    );
  }
}
