import { handleApiRequest } from "../../hooks/callApi";

export const getProjectGanttChart = async (startDate, endDate) => {
  const res = await handleApiRequest(
    `/api/common/project-gantt-chart?startDate=${startDate || ""}&endDate=${
      endDate || ""
    }`,
    "get"
  );

  return res;
};

export const getTaskReminders = async () => {
  const res = await handleApiRequest("/api/common/get-task-reminders", "post");
  return res;
};
