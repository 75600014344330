import { Box, IconButton, Typography } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import SavedReminders from "./SavedReminders";

const HomeDailyReminders = () => {
  const { user } = useAuth();

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    // Retrieve visibility preference from localStorage
    const savedVisibility = localStorage.getItem("dailyRemindersVisibility");
    setIsVisible(savedVisibility !== "false");
  }, []);

  const toggleVisibility = () => {
    const newVisibility = !isVisible;
    setIsVisible(newVisibility);
    // Save visibility preference to localStorage
    localStorage.setItem("dailyRemindersVisibility", newVisibility);
  };

  return (
    <Box mb={2} mt={3} sx={{ maxHeight: 400, overflowY: "auto" }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" color="textPrimary">
          TO DO for Today
        </Typography>
        <IconButton onClick={toggleVisibility}>
          {isVisible ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </Box>
      {isVisible && (
        <>
          <SavedReminders
            userId={user.id}
            date={new Date().toISOString().split("T")[0]}
          />
        </>
      )}
    </Box>
  );
};

export default HomeDailyReminders;
