import React, { useState, useEffect } from "react";
import {
  Dialog,
  Stepper,
  Step,
  StepLabel,
  Box,
  DialogActions,
  Button,
} from "@material-ui/core";
import toast from "react-hot-toast";
import {
  generateProjectFromTemplate,
  getSavedTemplates,
} from "../../../api/project/Project";
import moment from "moment";
import TemplateForm from "./project-template-steps/TemplateForm";
import ProjectPreview from "../../../Pages/projects/ProjectPreview";
import GenerateProjectMessage from "./project-template-steps/GenerateProjectMessage";

const steps = ["Select Template", "Preview", "Generate Project"];
const UseProjectTemplate = ({
  showUseProjectTemplate,
  setShowUseProjectTemplate,
  handleAllProject,
}) => {
  const [dropdownProjects, setDropdownProjects] = useState([]);
  const [projectStartDate, setProjectStartDate] = useState(Date.now());
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    setProjectDropdown();
  }, []);

  const setProjectDropdown = async () => {
    const res = await getSavedTemplates();
    if (res?.data?.data?.items.length) {
      setDropdownProjects(res.data.data.items);
    }
  };

  const onEndSubmit = async (values) => {
    const res = await generateProjectFromTemplate(
      selectedTemplateId,
      moment(projectStartDate).format("YYYY/MM/DD/")
    );

    if (res?.data?.success) {
      toast.success("Project copied successfully");
      handleAllProject();
      setShowUseProjectTemplate(false);
    }
  };

  return (
    <Dialog
      open={showUseProjectTemplate}
      onClose={() => setShowUseProjectTemplate(false)}
      fullWidth={true}
      maxWidth={"lg"}
      sx={{
        height: "100vh",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ pt: 5, pb: 3 }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 0 && (
        <TemplateForm
          setActiveStep={setActiveStep}
          setShowUseProjectTemplate={setShowUseProjectTemplate}
          projectStartDate={projectStartDate}
          dropdownProjects={dropdownProjects}
          setProjectStartDate={setProjectStartDate}
          setSelectedProjectId={setSelectedProjectId}
          setSelectedTemplateId={setSelectedTemplateId}
        />
      )}
      {activeStep === 1 && (
        <Box sx={{ height: "100%", overflowY: "auto" }}>
          <ProjectPreview selectedProjectId={selectedProjectId} />
        </Box>
      )}
      {activeStep === 2 && <GenerateProjectMessage />}
      {(activeStep === 1 || activeStep === 2) && (
        <DialogActions sx={{ zIndex: 3, backgroundColor: "#fff" }}>
          <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
            <Box>
              <Button
                sx={{
                  backgroundColor: "primary.blue",
                  color: "primary.contrastText",
                }}
                size="large"
                variant="contained"
                onClick={
                  activeStep === 1
                    ? () => setActiveStep(2)
                    : () => onEndSubmit()
                }
              >
                {activeStep === 1 ? "Next" : "Generate Project"}
              </Button>
            </Box>
            <Box sx={{ ml: 1 }}>
              <Button
                sx={{ backgroundColor: "primary.orange" }}
                size="large"
                variant="contained"
                onClick={() => {
                  setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }}
              >
                Back
              </Button>
            </Box>
            <Box sx={{ ml: 1 }}>
              <Button
                size="large"
                variant="contained"
                onClick={() => setShowUseProjectTemplate(false)}
                sx={{ backgroundColor: "#C0CBDE" }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default UseProjectTemplate;
