const experience = [
  {
    value: 1,
    title: "0-1 Years",
  },
  {
    value: 2,
    title: "1-2 years",
  },
  {
    value: 3,
    title: "5-10 years",
  },
];

export default experience;
