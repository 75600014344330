import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import { Link as BrowserRouter } from "react-router-dom";
import * as Yup from "yup";
import { forgotPassword } from "../../api/user/User";
import useMounted from "../../hooks/useMounted";

const ForgotPasswordForm = (props) => {
  const { setMessageModalState, setMessageModalErrorState, emailFromLogin } =
    props;
  const mounted = useMounted();

  return (
    <Formik
      initialValues={{
        email: emailFromLogin || "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const res = await forgotPassword(values.email);
          console.log(res);
          if (res?.status === 200) {
            setMessageModalState(true);
          } else {
            setMessageModalErrorState(true);
          }
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Registered Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            autoComplete="off"
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "15px",
                textAlign: "center",
                mx: "auto",
                display: "inherit",
              }}
              component={BrowserRouter}
              to="/authentication/login"
            >
              Back to Login
            </Typography>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
