const portfolioSize = [
  {
    value: 1,
    title: "Big",
  },
  {
    value: 2,
    title: "Medium",
  },
  {
    value: 3,
    title: "Small",
  },
];

export default portfolioSize;
