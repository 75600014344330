import { Box } from "@material-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getMemberReports,
  getSavedReports,
} from "../../../../api/reports/CustomReport";
import SaveFilterModal from "./SaveFilterModal";
import SearchFilters from "./SearchFilters";

let configs = {};
const filterParams = {
  debounceMs: 500,
  suppressAndOrCondition: true,
  comparator: function (filterLocalDateAtMidnight, cellValue) {
    if (cellValue == null) {
      return 0;
    }
    const dateParts = cellValue.split("-");
    const year = Number(dateParts[0]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[2]);
    const cellDate = new Date(year, month, day);
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    } else {
      return 0;
    }
  },
};

function dateComparator(date1, date2) {
  var date1Number = _monthToNum(date1);
  var date2Number = _monthToNum(date2);

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }

  return date1Number - date2Number;
}
// HELPER FOR DATE COMPARISON
function _monthToNum(date) {
  if (date === undefined || date === null || date.length !== 10) {
    return null;
  }

  var yearNumber = date.substring(6, 10);
  var monthNumber = date.substring(3, 5);
  var dayNumber = date.substring(0, 2);

  var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
  // 29/08/2004 => 20040829
  return result;
}

const CustomProjectMembers = ({ selectedFilter, setSelectedFilter }) => {
  //   const isSubscribed = true;
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [defaultGridState, setDefaultGridState] = useState(null);
  // const [configs, setConfigs] = useState({});
  const [rowData, setRowData] = useState(null);
  const [savedFilters, setSavedFilters] = useState([]);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState("");
  const [saveFilterModalOpen, setSaveFilterModalOpen] = useState(false);
  const [isPriviewModeActive, setIsPriviewModeActive] = useState(false);
  const [colDefs] = useState([
    {
      field: "id",
      headerName: "Project Name",
      enableRowGroup: true,
      enablePivot: true,
      pivot: true,
      rowGroup: true,
      hide: true,
      keyCreator: (params) => params?.data?.name,
      cellRenderer: (params) => {
        if (params.data) {
          return `${params.data.name}`;
        }
        if (params.node.allLeafChildren.length > 0) {
          return params.node.allLeafChildren[0].data.name;
        }

        return params.getValue();
      },
    },
    {
      field: "member_id",
      headerName: "Member Name",
      enableRowGroup: true,
      enablePivot: true,
      keyCreator: (params) => params?.data?.member_name,

      cellRenderer: (params) => {
        if (params.data) {
          return `${params.data.member_name}`;
        }

        return params.getValue();
      },
    },
    {
      field: "department",
      headerName: "Department Name",
      enableRowGroup: true,
      enablePivot: true,
      keyCreator: (params) => params?.data?.department_name,

      cellRenderer: (params) => {
        if (params.data) {
          return `${params.data.department_name}`;
        }

        return params.getValue();
      },
    },
    {
      field: "taskCount",
      headerName: "Task Count",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
    },
    {
      field: "expense",
      headerName: "Expense ($)",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
    },
    {
      field: "member_count",
      headerName: "Member count ($)",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
      hide: true,
    },
    {
      field: "time_on_proj",
      headerName: "Time on project",
      filter: "agNumberColumnFilter",
    },
    {
      field: "hourlyRate",
      headerName: "Hourly Rate  ($)",
      filter: "agNumberColumnFilter",
    },
    {
      field: "start_date",
      headerName: "Project Start Date",
      filter: "agDateColumnFilter",
      comparator: dateComparator,
      filterParams: filterParams,
    },
    {
      field: "due_date",
      headerName: "Project End Date",
      filter: "agDateColumnFilter",
      comparator: dateComparator,
      filterParams: filterParams,
    },
  ]);

  useEffect(() => {
    getTaskSavedReports();
  }, []);

  useEffect(() => {
    if (selectedFilter?.filter_data && gridColumnApi && gridApi) {
      update(selectedFilter.filter_data);
      setSelectedFilter(null);
      setSelectedSavedFilter(selectedFilter);
      activatePreview();
    }
  }, [gridColumnApi, gridApi]);

  const onGridReady = async (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    setDefaultGridState({
      filterModel: params?.api?.getFilterModel(),
      columnState: params?.columnApi?.getColumnState(),
      columnGroupState: params?.columnApi?.getColumnGroupState(),
      pivotMode: params?.columnApi?.isPivotMode(),
    });
    const updateData = (data) => {
      params.api.setRowData(data);
    };
    const res = await getMemberReports();
    updateData(res.data.data.item);

    agGridEvent(params);
  };

  const onPivotMode = () => {
    console.log("onPivotMode");
  };

  const agGridEvent = (props) => {
    props.api.addEventListener("firstDataRendered", changeHandler);
    props.api.addEventListener("filterChanged", changeHandler);
    props.api.addEventListener("filterModified", changeHandler);
    props.api.addEventListener("sortChanged", changeHandler);
    props.api.addEventListener("columnValueChanged", changeHandler);
    props.api.addEventListener("columnResized", changeHandler);
    props.api.addEventListener("columnVisible", changeHandler);
    props.api.addEventListener("columnPinned", changeHandler);
    props.api.addEventListener("columnResized", changeHandler);
    // props.api.addEventListener("columnPivotModeChanged", changeHandler);
    // props.api.addEventListener("columnPivotChanged", changeHandler);
    props.api.addEventListener("newColumnsLoaded", changeHandler);
    props.api.addEventListener("gridColumnsChanged", changeHandler);
    props.api.addEventListener("displayedColumnsChanged", changeHandler);
    props.api.addEventListener("rowGroupOpened", changeHandler);
    props.api.addEventListener("expandOrCollapseAll", changeHandler);
  };

  const changeHandler = (params) => {
    configs.filterModel = params?.api?.getFilterModel();
    configs.columnState = params?.columnApi?.getColumnState();
    configs.columnGroupState = params?.columnApi?.getColumnGroupState();
    configs.pivotMode = params?.columnApi?.isPivotMode();
  };

  const getTaskSavedReports = async () => {
    const res = await getSavedReports("projectmember");
    if (res?.data?.data?.items?.length > 0) {
      setSavedFilters(res.data.data.items);
    }
  };

  const updateFilters = (e, val) => {
    if (val) {
      console.log(val);
      const value = val.id;
      setSelectedSavedFilter(val);
      const selectedFilter = savedFilters.filter(
        (filter) => filter.id === value
      );
      if (selectedFilter.length && selectedFilter[0]?.filter_data) {
        update(selectedFilter[0]?.filter_data);
      }
      activatePreview();
    } else {
      setIsPriviewModeActive(false);
      activateDefaultState();
      setSelectedSavedFilter(null);
    }
  };

  const update = (filterData) => {
    const savedFilters = JSON.parse(filterData);
    const isPivotModeActive = savedFilters.pivotMode;
    gridColumnApi.setPivotMode(isPivotModeActive);
    gridApi.setFilterModel(savedFilters.filterModel);
    gridColumnApi.applyColumnState({
      state: savedFilters.columnState,
      applyOrder: true,
    });
    gridColumnApi.setColumnGroupState(savedFilters.columnGroupState);
  };

  const activatePreview = () => {
    setIsPriviewModeActive(true);
    closeToolPanel();
  };

  const activateDefaultState = () => {
    if (defaultGridState) {
      const isPivotModeActive = defaultGridState?.pivotMode;
      gridColumnApi.setPivotMode(isPivotModeActive);
      gridApi.setFilterModel(defaultGridState?.filterModel);
      gridColumnApi.applyColumnState({
        state: defaultGridState?.columnState,
        applyOrder: true,
      });
      gridColumnApi.setColumnGroupState(defaultGridState?.columnGroupState);
    }
  };

  const closeToolPanel = useCallback(() => {
    gridRef.current.api.closeToolPanel();
  }, []);

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  return (
    <>
      {saveFilterModalOpen && (
        <SaveFilterModal
          view="projectmember"
          filterData={configs}
          saveFilterModalOpen={saveFilterModalOpen}
          setSaveFilterModalOpen={setSaveFilterModalOpen}
          getTaskSavedReports={getTaskSavedReports}
        />
      )}
      <Box sx={{ width: "100%", height: "85vh" }}>
        <div className="example-wrapper">
          <SearchFilters
            isPriviewModeActive={isPriviewModeActive}
            selectedSavedFilter={selectedSavedFilter}
            setSaveFilterModalOpen={setSaveFilterModalOpen}
            savedFilters={savedFilters}
            updateFilters={updateFilters}
            setIsPriviewModeActive={setIsPriviewModeActive}
            getTaskSavedReports={getTaskSavedReports}
          />

          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              defaultColDef={{
                flex: 1,
                minWidth: 150,
                sortable: true,
                resizable: true,
                filter: true,
              }}
              ref={gridRef}
              autoGroupColumnDef={{ minWidth: 150, pinned: true }}
              sideBar={true}
              onGridReady={onGridReady}
              rowData={rowData}
              onColumnPivotModeChanged={onPivotMode}
              columnDefs={colDefs}
              enableRangeSelection={true}
              enableCharts={true}
              popupParent={popupParent}
              suppressAggFuncInHeader={true}
              suppressExpandablePivotGroups={true}
            ></AgGridReact>
          </div>
        </div>
      </Box>
    </>
  );
};

export default CustomProjectMembers;
