import createResourceId from "../utils/createResourceId";
import deepCopy from "../utils/deepCopy";

const getTimestamp = (t) => {
  const date = t || "2023-03-25";
  return new Date(date).getTime();
};

let events = [
  {
    id: "5e8882e440f6322fa399eeb8",
    allDay: true,
    color: "#43a048",
    description: "Task description",
    // end: setHours(setMinutes(subDays(now, 6), 0), 19).getTime(),
    start: getTimestamp("2023-03-23"),
    title: "Task 1",
  },
  {
    id: "5e8882eb5f8ec686220ff131",
    allDay: true,
    color: null,
    description: "Task description",
    // end: setHours(setMinutes(addDays(now, 2), 30), 15).getTime(),
    start: getTimestamp("2023-03-24"),
    title: "Task 2",
  },
  {
    id: "5e8882f1f0c9216396e05a9b",
    allDay: true,
    color: null,
    description: "Task description",
    // end: setHours(setMinutes(addDays(now, 5), 0), 12).getTime(),
    start: getTimestamp("2023-03-17"),
    title: "Task 3",
  },
  {
    id: "5e8882f6daf81eccfa40dee2",
    allDay: true,
    color: null,
    description: "Task description",
    // end: startOfDay(subDays(now, 11)).getTime(),
    start: getTimestamp("2023-03-17"),
    title: "Task 4",
  },
  {
    id: "5e8882fcd525e076b3c1542c",
    allDay: true,
    color: "#43a048",
    description: "Task description",
    // end: setHours(setMinutes(addDays(now, 3), 31), 7).getTime(),
    start: getTimestamp(),
    title: "Task 5",
  },
  {
    id: "5e888302e62149e4b49aa609",
    allDay: true,
    color: null,
    description: "Task description",
    // end: setHours(setMinutes(subDays(now, 6), 30), 9).getTime(),
    start: getTimestamp(),
    title: "Task 6",
  },
  {
    id: "5e88830672d089c53c46ece3",
    allDay: true,
    color: "#43a048",
    description: "Task description",
    // end: setHours(setMinutes(now, 30), 17).getTime(),
    start: getTimestamp(),
    title: "Task ",
  },
];

// Get timestamp of a date

class CalendarApi {
  getEvents() {
    return Promise.resolve(deepCopy(events));
  }

  createEvent(data) {
    return new Promise((resolve, reject) => {
      try {
        const { allDay, description, end, start, title } = data;

        // Make a deep copy
        const clonedEvents = deepCopy(events);

        // Create the new event
        const event = {
          id: createResourceId(),
          allDay,
          description,
          end,
          start,
          title,
        };

        // Add the new event to events
        clonedEvents.push(event);

        // Save changes
        events = clonedEvents;

        resolve(deepCopy(event));
      } catch (err) {
        console.error("[Calendar Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  updateEvent({ eventId, update }) {
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonedEvents = deepCopy(events);

        // Find the event that will be updated
        const event = events.find((_event) => _event.id === eventId);

        if (!event) {
          reject(new Error("Event not found"));
          return;
        }

        // Update the event
        Object.assign(event, update);

        // Save changes
        events = clonedEvents;

        resolve(deepCopy(event));
      } catch (err) {
        console.error("[Calendar Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  deleteEvent(eventId) {
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonedEvents = deepCopy(events);

        // Find the event that will be removed
        const event = events.find((_event) => _event.id === eventId);

        if (!event) {
          reject(new Error("Event not found"));
          return;
        }

        events = events.filter((_event) => _event.id !== eventId);

        // Save changes
        events = clonedEvents;

        resolve(true);
      } catch (err) {
        console.error("[Calendar Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const calendarApi = new CalendarApi();
