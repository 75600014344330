const projectPriority = [
  {
    value: 1,
    title: "Low",
  },
  {
    value: 2,
    title: "Normal",
  },
  {
    value: 3,
    title: "Medium",
  },
  {
    value: 4,
    title: "High",
  },
  {
    value: 5,
    title: "Critical",
  },
];

export default projectPriority;
