import React, { useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Typography,
  DialogActions,
  Button,
} from "@material-ui/core";
import BudgetDetailedRow from "./BudgetDetailedRow";
import numberFormatter from "../../../../utils/numberFormatter";

const ShowViewOnlyMonthlyBudget = ({
  showModal,
  setShowModal,
  data,
  setData,
  getAllMonthsDetailedBudget,
  allBudget,
  updateTotalActualOfAMonth,
}) => {
  const [total, setTotal] = useState({});

  useEffect(() => {
    if (isDetailsAvailable()) {
      const filteredData = allBudget.filter((item) => item.date === data.date);
      if (filteredData.length > 0) {
        // calculate  total budget, actual and variance
        const totalBudget = filteredData.reduce(
          (acc, item) => acc + item.budget,
          0
        );
        const totalActual = filteredData.reduce(
          (acc, item) => acc + item.actual,
          0
        );
        const totalVariance = totalBudget - totalActual;
        const percentage =
          totalBudget > 0 ? (totalActual / totalBudget) * 100 : 0;
        setTotal({
          totalBudget,
          totalActual,
          totalVariance,
          percentage,
        });
      }
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
    setData(null);
  };

  const calculateVariance = (budget, actual) => {
    return budget - actual;
  };

  const isDetailsAvailable = () => {
    const filteredData = allBudget.filter((item) => item.date === data.date);
    if (filteredData.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      fullWidth={true}
      maxWidth={"md"}
    >
      {isDetailsAvailable() ? (
        <Table hover>
          <TableHead
            sx={{
              th: {
                padding: "8px !important",
                fontSize: "14px !important",
                backgroundColor: "#f8f8f8 !important",
              },
            }}
          >
            <TableCell>Summary</TableCell>
            <TableCell>Budget ($)</TableCell>
            <TableCell>Actual ($)</TableCell>
            <TableCell>Variance ($)</TableCell>
            <TableCell>Percentage (%)</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableHead>
          <TableBody>
            <TableRow
              sx={{
                backgroundColor: "#fffdf2",
                fontWeight: "bold",
                td: {
                  fontWeight: "bold",
                },
              }}
            >
              <TableCell>{data.date}</TableCell>
              <TableCell>{numberFormatter(data.budget)}</TableCell>
              <TableCell>{numberFormatter(data.actual)}</TableCell>
              <TableCell
                sx={{
                  backgroundColor:
                    calculateVariance(data.budget, data.actual) < 0
                      ? "#ebb0b0"
                      : "#8dcebf",
                }}
              >
                {numberFormatter(calculateVariance(data.budget, data.actual))}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor:
                    calculateVariance(data.budget, data.actual) < 0
                      ? "#ebb0b0"
                      : "#8dcebf",
                }}
              >
                {data.budget > 0
                  ? ((data.actual / data.budget) * 100).toFixed(2)
                  : 0}{" "}
                %
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            {allBudget.map(
              (item) =>
                item?.date === data.date && (
                  <BudgetDetailedRow
                    item={item}
                    key={item.id}
                    calculateVariance={calculateVariance}
                    data={data}
                    getAllMonthsDetailedBudget={getAllMonthsDetailedBudget}
                  />
                )
            )}
            <TableRow
              sx={{
                backgroundColor: "#fffdf2 !important",
                fontWeight: "bold",
                td: {
                  fontWeight: "bold",
                },
              }}
            >
              <TableCell>Total (Monthly Detail)</TableCell>
              <TableCell>{numberFormatter(total.totalBudget)}</TableCell>
              <TableCell>{numberFormatter(total.totalActual)}</TableCell>
              <TableCell>{numberFormatter(total.totalVariance)}</TableCell>
              <TableCell>{Math.floor(total.percentage)} %</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      ) : (
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" color="textPrimary" align="center">
            No details available
          </Typography>
        </Box>
      )}
      <DialogActions>
        <Box sx={{ pt: 2, pb: 1 }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ShowViewOnlyMonthlyBudget;
