import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import useLoader from "../../../hooks/useLoader";
import useAuth from "../../../hooks/useAuth";
import { downloadReport, fetchReports } from "../../../api/reports/Report";
import moment from "moment";

const ProjectHistory = () => {
  const [reports, setReports] = useState([]);
  const { user } = useAuth();
  const { loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    getProjectReportHistory();
  }, []);

  const getProjectReportHistory = async () => {
    try {
      loaderStart();
      const res = await fetchReports({ addedBy: user.id });
      if (res.data.success) {
        setReports(res.data.data.reports.data);
      }
      loaderEnd();
    } catch (error) {
      console.log(error);
      loaderEnd();
    }
  };

  const handleDownload = async (id) => {
    const res = await downloadReport({ reportId: id });
    if (res.data.success) {
      window.open(res.data.data.tempLink);
    }
  };

  return (
    <>
      {reports?.length > 0 ? (
        <Box sx={{ pt: 4 }}>
          <Box>
            <Typography variant="h4" sx={{ pt: 1 }} align="center">
              Generated Report
            </Typography>
            <Typography variant="h6" sx={{ pt: 1, pb: 3 }} align="center">
              Project Status for All Projects of PQR Solutions
            </Typography>
          </Box>
          <Table>
            <TableHead sx={{ th: { fontSize: "12px", opacity: "0.8" } }}>
              <TableRow>
                <TableCell sx={{ px: 2 }}>#</TableCell>
                <TableCell>REPORT TITLE</TableCell>
                <TableCell>DATE OF CREATION </TableCell>
                <TableCell>REPORT FORMAT</TableCell>
                <TableCell align="center">STATUS</TableCell>
              </TableRow>
            </TableHead>

            {reports.map((report, index) => (
              <TableRow>
                <TableCell sx={{ px: 2 }}>{++index}</TableCell>
                <TableCell>{report.title}</TableCell>
                <TableCell>
                  {moment(report.date).format("MM-DD-YYYY")}
                </TableCell>
                <TableCell>{report.format}</TableCell>
                <TableCell align="center">
                  <Button
                    variant="outlined"
                    onClick={() => handleDownload(report.id)}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Box>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ pt: 8 }} align="center">
            No Report Found, Generate a new one
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ProjectHistory;
