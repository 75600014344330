import { handleApiRequest } from "../../hooks/callApi";

export const createNotification = async () => {
  const res = await handleApiRequest(`/api/create-notification`, "get");
  return res;
};

export const getAllNotification = async () => {
  const res = await handleApiRequest(`/api/view-all-notification`, "get");
  return res;
};

export const markAsRead = async () => {
  const res = await handleApiRequest(`/api/read-notifications`, "get");
  return res;
};

export const deleteAllNotification = async () => {
  const res = await handleApiRequest(`/api/delete-notifications`, "get");
  return res;
};
