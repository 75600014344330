import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import { Share } from "@material-ui/icons";
import { createSharedCalendar } from "../../../api/calender/Calender";
import toast from "react-hot-toast";

const ShareCalendar = ({ userId }) => {
  const [open, setOpen] = useState(false);
  const [expiryDate, setExpiryDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    const res = await createSharedCalendar({
      user_id: userId,
      expiry_date: expiryDate,
    });
    const { token } = res.data;
    const URL = `${window.location.origin}/shared-calendar/${userId}?t=${token}`;
    // Copy the URL to the clipboard
    navigator.clipboard.writeText(URL);

    toast(
      <span
        style={{ wordWrap: "break-word", whiteSpace: "normal", color: "green" }}
      >
        URL copied to clipboard:
        <br />
        <span
          style={{
            color: "#2196f3",
          }}
        >
          {URL}
        </span>
      </span>,
      {
        style: {
          maxWidth: "400px", // Limit the width to break lines naturally
          whiteSpace: "normal", // Allow wrapping within the container
          padding: "10px 15px",
          overflowX: "scroll",
        },
        duration: 5000,
      }
    );
  };

  const handleDateChange = (event) => {
    setExpiryDate(event.target.value);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        endIcon={<Share />}
        sx={{
          padding: "15px 26px",
          mr: 1,
        }}
      >
        Share
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm">
        <DialogTitle>Share Calendar</DialogTitle>
        <DialogContent>
          <TextField
            label="Expiry Date"
            type="date"
            fullWidth
            focused
            value={expiryDate}
            onChange={handleDateChange}
            InputLabelProps={{
              shrink: true,
            }}
            // Make the minimum date today
            inputProps={{ min: new Date().toISOString().split("T")[0] }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShareCalendar;
