import { handleApiRequest } from "../../hooks/callApi";

export const getAllCompanyScores = async () => {
  const res = await handleApiRequest(`/api/get-all-score`, "get");
  return res;
};

// Route::get('/project-score-range', [ScoreRangeController::class, 'getScoreRange']);
//     Route::post('/project-score-range', [ScoreRangeController::class, 'create']);

export const getScoreRange = async () => {
  const res = await handleApiRequest(`/api/project-score-range`, "get");
  return res;
};

export const createScoreRange = async (data) => {
  const res = await handleApiRequest(`/api/project-score-range`, "post", data);
  return res;
};
