import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { projectHealthGraph } from "../../../../api/project/ProjectDashboard";

const HealthGraph = ({ selectedProject, authToken = null }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getGraph();
  }, []);

  const getGraph = async () => {
    const res = await projectHealthGraph(selectedProject, authToken);
    if (res?.data?.data?.values) {
      setOptions(res.data.data.values);
    }
  };

  return (
    <Grid container spacing={2} sx={{ pl: 2, alignItems: "center" }}>
      {options.map((data, index) => (
        <React.Fragment key={index}>
          <Grid item xs={6} sm={6} md={3}>
            <Typography
              sx={{
                fontSize: "16px !important",
                fontWeight: "bold",
                color: "text.green",
              }}
            >
              {data.heading}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={9}>
            {data.value}
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default HealthGraph;
