import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@material-ui/core";
import { Add, RemoveRedEye, ViewList, ViewModule } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { sprintTaskPriorities } from "../../@const/sprintTaskPriorities";
import { addTask, editTask } from "../../api/project/Project";
import { useFetch } from "../../hooks/useAPI";
import useProject from "../../hooks/useProject";
import EditSprint from "./EditSprint";
import ProjectMemberAutocomplete from "./ProjectMemberAutocomplete";
import TaskPointsAutocomplete from "./TaskPointsAutocomplete";
import moment from "moment";

export default function SprintDetailsActions({
  status,
  sprint,
  refetchSprintTasks,
  setRefreshCount,
  sprintTask,
  isColumn,
  setIsColumn,
  startDate,
  endDate,
}) {
  console.log({ startDate, endDate });
  const queryString = window.location.search;
  const parameters = new URLSearchParams(queryString);
  const sprintId = parameters.get("sprintId");
  const { allProjectMembers, getAllProjectMembers } = useProject();
  const { data: sprintTaskStatuses, loading } = useFetch(
    `/api/sprint-task-statuses`
  );
  const { data: taskPoints } = useFetch(`/api/sprint-point-scores`);
  const { projectId } = useParams();
  const [open, setOpen] = useState(false);
  const [taskData, setTaskData] = useState({
    name: "",
    status: status,
    startDate: null,
    endDate: null,
    assignedUser: null,
    points: null,
    priority: null,
  });

  useEffect(() => {
    if (allProjectMembers.length === 0) {
      getAllProjectMembers(projectId);
    }
  }, [projectId]);

  const handleInputChange = (e) => {
    setTaskData({
      ...taskData,
      [e.target.name]: e.target.value,
    });
  };

  const handleTaskStatusChange = (_e, newValue) => {
    setTaskData({
      ...taskData,
      status: newValue ? newValue.id : null,
    });
  };

  const handleUserChange = (_e, newValue) => {
    setTaskData({
      ...taskData,
      assignedUser: newValue ? newValue.member_id : null,
    });
  };

  const handlePriorityChange = (event) => {
    setTaskData({
      ...taskData,
      priority: event.target.value,
    });
  };

  const handleSave = async () => {
    try {
      // Validate dates
      if (
        moment(taskData.startDate).isBefore(moment(startDate), "day") ||
        moment(taskData.endDate).isAfter(moment(endDate), "day")
      ) {
        alert(
          `Start Date must be on or after ${moment(startDate).format(
            "YYYY-MM-DD"
          )}, and End Date must be on or before ${moment(endDate).format(
            "YYYY-MM-DD"
          )}.`
        );
        return;
      }

      if (!sprintTask) {
        const data = {
          task_name: taskData.name,
          proj_id: projectId,
          task_estimate: taskPoints.find((p) => p.id == taskData.points).score,
          sprint_points: taskData.points,
          task_status: taskData.status,
          start_date: taskData.startDate,
          end_date: taskData.endDate,
          sprint_id: sprintId,
          person_id: taskData.assignedUser,
          task_priority: taskData.priority,
        };

        const res = await addTask(data);
        console.log({ res });
      } else {
        const updatedData = {
          task_name: taskData.name,
          proj_id: projectId,
          task_estimate: taskPoints.find((p) => p.id == taskData.points).score,
          task_status: taskData.status,
          start_date: taskData.startDate,
          end_date: taskData.endDate,
          person_id: taskData.assignedUser,
          task_id: sprintTask.id,
          task_priority: taskData.priority,
        };

        const response = await editTask(updatedData);
        console.log({ response });
      }
    } catch (error) {
      console.log({ error });
    } finally {
      await refetchSprintTasks?.();
      setRefreshCount?.((prev) => prev + 1);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (sprintTask) {
      console.log({ sprintTask });
      setTaskData({
        name: sprintTask.name || "",
        status: sprintTask.status || status,
        startDate: sprintTask.start_date || null,
        endDate: sprintTask.end_date || null,
        assignedUser: sprintTask.assigned_to || null,
        points: +sprintTask.sprint_points || null,
        priority: +sprintTask.priority || null,
      });
    }
  }, [sprintTask, status]);

  return (
    <>
      {sprintTask ? (
        <Tooltip title="View & Edit Task">
          <IconButton
            color="inherit"
            size="small"
            onClick={() => setOpen(true)}
          >
            <RemoveRedEye fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="contained"
          size="small"
          startIcon={<Add fontSize="small" />}
          onClick={() => setOpen(true)}
        >
          Create Task
        </Button>
      )}
      {!status && sprint && <EditSprint sprint={sprint} />}
      {setIsColumn && (
        <ToggleButtonGroup
          value={isColumn}
          exclusive
          onChange={(_, value) => setIsColumn(value)}
        >
          <ToggleButton value={false} aria-label="list">
            <ViewList fontSize="small" />
          </ToggleButton>
          <ToggleButton value={true} aria-label="module">
            <ViewModule fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{sprintTask ? "Edit Task" : "Add New Task"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Task Name"
                name="name"
                value={taskData.name}
                onChange={handleInputChange}
                required
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                loading={loading}
                options={sprintTaskStatuses?.data || []}
                getOptionLabel={(option) => option.status?.toUpperCase()}
                value={
                  taskData.status
                    ? sprintTaskStatuses?.data?.find(
                        (p) => p.id === taskData.status
                      )
                    : null
                }
                onChange={handleTaskStatusChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Task Status"
                    name="status"
                    required
                    fullWidth
                    variant="standard"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="standard">
                <InputLabel id="task-priority-label">
                  Select Task Priority
                </InputLabel>
                <Select
                  labelId="task-priority-label"
                  value={taskData.priority || ""}
                  onChange={handlePriorityChange}
                  variant="standard"
                >
                  {sprintTaskPriorities.map((priority) => (
                    <MenuItem key={priority.id} value={priority.id}>
                      {priority.priority.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Start Date"
                name="startDate"
                type="date"
                value={taskData.startDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: startDate, // Minimum date from the startDate prop
                  max: endDate, // Maximum date from the endDate prop
                }}
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="End Date"
                name="endDate"
                type="date"
                value={taskData.endDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: taskData.startDate || startDate, // Minimum date should be the selected startDate or the prop startDate
                  max: endDate, // Maximum date from the endDate prop
                }}
                fullWidth
                variant="standard"
              />
            </Grid>

            <ProjectMemberAutocomplete
              onChange={handleUserChange}
              selectedMember={taskData.assignedUser}
              variant="standard"
            />

            <Grid item xs={12}>
              <TaskPointsAutocomplete
                point_score={taskData?.point_score}
                points={taskData?.points}
                onChange={(_e, newValue) =>
                  setTaskData({
                    ...taskData,
                    points: newValue ? newValue.id : null,
                  })
                }
                variant="standard"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
