const numFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  //   Dont show the currency symbol
  //   currencyDisplay: "narrowSymbol",
});

const numberFormatter = (number) => {
  const isNegative = number < 0;
  const formattedNum = numFormatter.format(number).split("$")[1];
  return isNegative ? `(${formattedNum})` : formattedNum;
};

export default numberFormatter;
