import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, TableCell, TableRow, Switch, Box } from "@material-ui/core";

import PencilAltIcon from "../../../icons/PencilAlt";
import TrashIcon from "../../../icons/Trash";
import EditCredentialModal from "./EditCredentialModal";
import { deleteUser } from "../../../api/user/User";
import toast from "react-hot-toast";
import moment from "moment";
import useAuth from "../../../hooks/useAuth";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteModal from "../../DeleteModal";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    backgroundColor: "#F7B31E",
    color: "#000",
    fontWeight: "600",
    fontSize: "12px",
    padding: "8px 12px",
  },
  customArrow: {
    color: "rgba(220, 0, 78, 0.8)",
  },
}));

const SingleCredentialInfo = ({
  user,
  index,
  handleAdminRequest,
  refreshUserList,
  filterUserRole,
  handleAllUser,
  currentPage,
  nameSearchValue,
}) => {
  const [memberModalState, setMemberModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const classes = useStyles();

  const handleCreateNewMemberModalOpen = () => {
    setMemberModalState(true);
  };

  const handleCreateNewMemberModalClose = () => {
    setMemberModalState(false);
  };

  const deleteSingleUser = async () => {
    const res = await deleteUser({ userId: user?.id });
    if (res?.data?.success) {
      toast.success("Member removed");
      handleAllUser(currentPage, filterUserRole, nameSearchValue);
    }
  };

  const { user: logedInUser } = useAuth();

  const handleAccess = async () => {
    const access = user.is_authorized === 0 ? 1 : 0;
    const res = await handleAdminRequest(user.id, access);
    if (res?.data) {
      refreshUserList();
    }
  };

  return (
    <>
      <DeleteModal
        open={deleteModalState}
        setOpen={setDeleteModalState}
        onYesClick={deleteSingleUser}
        warningMessage="Are you sure you want to delete this member?"
      />
      <EditCredentialModal
        handleCreateNewMemberModalClose={handleCreateNewMemberModalClose}
        memberModalState={memberModalState}
        userData={user}
        handleAllUser={handleAllUser}
        currentPage={currentPage}
        nameSearchValue={nameSearchValue}
        filterUserRole={filterUserRole}
      />
      <TableRow hover>
        <TableCell sx={{ px: 2 }}>{index + 1}</TableCell>
        <TableCell
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "120px",
            overflow: "hidden",
          }}
        >
          <Link
            color="textPrimary"
            component={RouterLink}
            to="#"
            underline="none"
            variant="subtitle2"
            title={`${user.first_name} ${user.last_name}`}
          >
            {`${user.first_name} ${user.last_name}`}
          </Link>
        </TableCell>
        {+filterUserRole === 0 && (
          <TableCell>{user?.role?.role_name}</TableCell>
        )}
        <TableCell
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "120px",
            overflow: "hidden",
          }}
          title={user.email}
        >
          {user.email.split(":")[1] || user.email}
        </TableCell>
        {logedInUser.role === 1 && (
          <TableCell>{user?.company?.company_name}</TableCell>
        )}
        {logedInUser.role === 1 && (
          <TableCell>
            {user?.company?.code ? user?.company?.code : "-"}
          </TableCell>
        )}
        <TableCell>
          {user?.created_at
            ? moment(user.created_at).format("MM-DD-YYYY")
            : "-"}
        </TableCell>
        <TableCell>
          {(logedInUser.role === 1 || logedInUser.role === 2) &&
          user.email !== "" ? (
            <Switch
              color="primary"
              name="direction"
              checked={user.is_authorized !== 0}
              onClick={handleAccess}
            />
          ) : (
            <Tooltip
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
              title={
                user.email === "" ? "User doesn't have the credentials" : ""
              }
            >
              <Box>
                <Switch color="primary" name="direction" disabled={true} />
              </Box>
            </Tooltip>
          )}
        </TableCell>
        <TableCell align="right">
          {logedInUser.role === 1 || logedInUser.role === 2 ? (
            <>
              <PencilAltIcon
                title="Edit"
                onClick={handleCreateNewMemberModalOpen}
                className="action-icon"
              />
              <Tooltip title="Delete">
                <TrashIcon
                  onClick={() => {
                    setDeleteModalState(true);
                  }}
                  className="action-icon"
                />
              </Tooltip>
            </>
          ) : (
            <>
              <PencilAltIcon title="Edit" className="icon-disabled" />
              <Tooltip title="Delete">
                <TrashIcon title="delete" className="action-icon" />
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleCredentialInfo;
