import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useLoader from "../../../hooks/useLoader";
import { Link as RouterLink } from "react-router-dom";
import {
  dropdownPortfolios,
  dropdownProjects,
} from "../../../api/budget/Budget";
import useAuth from "../../../hooks/useAuth";
import {
  downloadStatusReport,
  getStatusReport,
} from "../../../api/reports/Report";
import ReportAction from "./ReportAction";
import toast from "react-hot-toast";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ProjectStatus = () => {
  const date = moment().format("YYYY-MM-DD");
  const classes = useStyles();
  const [allProjects, setAllProjects] = useState([]);
  const [allPortfolio, setAllPortfolio] = useState([]);
  const [seledtedPortfolio, setSeledtedPortfolio] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [reports, setReports] = useState([]);
  const { user } = useAuth();
  const { loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    getDropdownPortfolios();
    generateReport();
    return () => {
      setSelectedProject("");
    };
  }, []);

  useEffect(() => {
    getDropdownProjects();
  }, [seledtedPortfolio]);

  const getDropdownProjects = async () => {
    const res = await dropdownProjects({ portfolioId: seledtedPortfolio });
    if (res.data.success) {
      setAllProjects(res.data.data.projectDropdown);
    }
  };

  const getDropdownPortfolios = async () => {
    const res = await dropdownPortfolios();
    if (res.data.success) {
      setAllPortfolio(res.data.data.portfolioDropdown);
    }
  };

  const handlePortfolioChange = (e) => {
    console.log("clcked");
    setSeledtedPortfolio(e.target.value);
    setSelectedProject("");
  };

  const generateReport = async (pdf) => {
    loaderStart();
    const res = await getStatusReport({
      generatePDF: "",
      saveInBucket: "",
      projectId: selectedProject,
      portfolioId: seledtedPortfolio,
      projectAdmin: "",
      fileAdminId: "",
    });
    console.log(res.data);
    if (res.data) {
      setReports(res.data);
    }
    loaderEnd();
  };

  const downloadReport = async (pdf) => {
    loaderStart();
    const res = await downloadStatusReport({
      generatePDF: "1",
      saveInBucket: "",
      projectId: selectedProject,
      portfolioId: seledtedPortfolio,
      projectAdmin: "",
      fileAdminId: "",
    });
    loaderEnd();
  };

  const saveReport = async () => {
    try {
      loaderStart();
      const res = await getStatusReport({
        generatePDF: "1",
        saveInBucket: "1",
        projectId: selectedProject,
        portfolioId: seledtedPortfolio,
        projectAdmin: "",
        fileAdminId: user.id,
      });
      if (res.data) {
        toast.success("File saved successfully");
      }
      loaderEnd();
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item md={3} sm={12} xs={12}>
          <Box sx={{ width: "100%" }}>
            <FormControl className={classes.formControl}>
              <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                Portfolios
              </InputLabel>
              <Select
                labelId="select-status"
                label="SELECT PORTFOLIO"
                name="seledtedPortfolio"
                value={seledtedPortfolio}
                fullWidth
                onChange={handlePortfolioChange}
              >
                <MenuItem value="">
                  {" "}
                  {allPortfolio.length > 0 ? "All Portfolios" : "none"}
                </MenuItem>
                {allPortfolio.map((portfolio) => {
                  return (
                    <MenuItem key={portfolio.id} value={portfolio.id}>
                      {portfolio.name}
                    </MenuItem>
                  );
                })}{" "}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <Box sx={{ width: "100%" }}>
            <FormControl className={classes.formControl}>
              <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                Projects
              </InputLabel>
              <Select
                labelId="select-status"
                label="SELECT PROJECT"
                name="selectedProject"
                value={selectedProject}
                fullWidth
                onChange={(e) => {
                  setSelectedProject(e.target.value);
                }}
              >
                <MenuItem value="">
                  {allProjects.length > 0 ? "All Projects" : "none"}
                </MenuItem>
                {allProjects?.length > 0 &&
                  allProjects.map((proj) => (
                    <MenuItem key={proj.id} value={proj.id}>
                      {proj.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item md={3} sm={12} xs={12}>
          <Box sx={{ m: -1 }}>
            <Button
              color="primary"
              sx={{ m: 1, py: 1.7 }}
              variant="contained"
              size="large"
              fullWidth
              onClick={generateReport}
            >
              Generate Report
            </Button>
          </Box>
        </Grid>
      </Grid>
      {reports?.statusReport?.length > 0 ? (
        <Box sx={{ pt: 4 }}>
          <Box sx={{ ml: "auto", display: "flex", justifyContent: "flex-end" }}>
            <Box component="span">
              <ReportAction
                downloadReport={downloadReport}
                saveReport={saveReport}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="h4" sx={{ pt: 1 }} align="center">
              Report
            </Typography>
            <Typography variant="h6" sx={{ pt: 1 }} align="center">
              {reports?.reportHeading}
            </Typography>
            <Typography
              variant="h6"
              sx={{ pt: 1, pb: 3, fontSize: "12px" }}
              align="center"
            >
              Date: {date}
            </Typography>
          </Box>
          <Table>
            <TableHead sx={{ th: { fontSize: "12px", opacity: "0.8" } }}>
              <TableRow>
                <TableCell sx={{ px: 2 }}>#</TableCell>
                <TableCell>PROJECT NAME</TableCell>
                <TableCell>START DATE</TableCell>
                <TableCell>END DATE</TableCell>
                <TableCell>BUDGET($)</TableCell>
                <TableCell>ACTUAL($)</TableCell>
                <TableCell align="center">STATUS</TableCell>
              </TableRow>
            </TableHead>

            {reports?.statusReport.map((report, index) => (
              <TableRow>
                <TableCell sx={{ px: 2 }}>{++index}</TableCell>
                <TableCell>
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to={`/dashboard/projects/view-and-edit-project/${report.id}`}
                    underline="none"
                    variant="subtitle2"
                    className="redirect-link"
                  >
                    {report.project_name}
                  </Link>
                </TableCell>
                <TableCell>{report.start_date}</TableCell>
                <TableCell>{report.end_date}</TableCell>
                <TableCell>{report.budget}</TableCell>
                <TableCell>{report.actual}</TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      height: "18px",
                      width: "18px",
                      borderRadius: "18px",
                      backgroundColor: report.color_code,
                      mx: "auto",
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Box>
      ) : (
        <Box>
          <Typography variant="h5" sx={{ pt: 8 }} align="center">
            No Report Found, Generate a new one
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ProjectStatus;
