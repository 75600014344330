import { Box } from "@material-ui/core";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  getBudgetReports,
  getSavedReports,
} from "../../../../api/reports/CustomReport";
import useAuth from "../../../../hooks/useAuth";
import SaveFilterModal from "./SaveFilterModal";
import SearchFilters from "./SearchFilters";

let configs = {};

// HELPER FOR DATE COMPARISON

const calculateVariance = (params) => {
  let diff = 0;
  if (params.columnApi.columnModel.pivotColumns.length > 0) {
    // console.log(params);
  }
  if (params.rowNode.group) {
    const totalBudget = params.rowNode.allLeafChildren.reduce(
      (previousValue, currentValue) =>
        previousValue + Number(currentValue.data.budget),
      0
    );
    const totalActual = params.rowNode.allLeafChildren.reduce(
      (previousValue, currentValue) =>
        previousValue + Number(currentValue.data.actual),
      0
    );
    diff = totalBudget - totalActual;
    const render = diff < 0 ? `(${Math.abs(diff)})` : diff;
    let className = "black";
    if (diff > 0) {
      className = "green";
    } else if (diff < 0) {
      className = "red";
    }
    return render;
  }

  return params.getValue();
};

const CustomBudget = ({ selectedFilter, setSelectedFilter }) => {
  const { user } = useAuth();
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [defaultGridState, setDefaultGridState] = useState(null);
  // const [configs, setConfigs] = useState({});
  const [rowData, setRowData] = useState(null);
  const [savedFilters, setSavedFilters] = useState([]);
  const [selectedSavedFilter, setSelectedSavedFilter] = useState("");
  const [saveFilterModalOpen, setSaveFilterModalOpen] = useState(false);
  const [isPriviewModeActive, setIsPriviewModeActive] = useState(false);
  const [colDefs, setColDefs] = useState([
    {
      field: "portfolio",
      rowGroup: true,
      enableRowGroup: true,
      enablePivot: true,
      headerName: "Portfolio",
      hide: true,
    },
    {
      field: "project",
      enablePivot: true,
      rowGroup: true,
      enableRowGroup: true,
      headerName: "Project",
      hide: true,
    },
    {
      field: "date",
      enablePivot: true,
      headerName: "Month",
    },
    {
      field: "quater",
      enablePivot: true,
      headerName: "Quarter",
      hide: true,
    },
    {
      field: "year",
      enablePivot: true,
      headerName: "Year",
      hide: true,
      filter: "agNumberColumnFilter",
    },
    {
      field: "budget",
      headerName: "Budget",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
    },
    {
      field: "actual",
      headerName: "Actual",
      aggFunc: "sum",
      filter: "agNumberColumnFilter",
    },
    {
      colId: "variance",
      headerName: "Variance ($)",
      enablePivot: true,
      filter: "agNumberColumnFilter",
      aggFunc: calculateVariance,
      cellRenderer: (params) => {
        if (params.colDef.pivotKeys && params.colDef.pivotKeys.length > 0) {
          const allLeafChildren = params.node.allLeafChildren;
          const pivotKeys = params.colDef.pivotKeys;
          const lowestLevel = pivotKeys.includes(allLeafChildren[0].data.date)
            ? "date"
            : pivotKeys.includes(allLeafChildren[0].data.quater)
            ? "quater"
            : pivotKeys.includes(allLeafChildren[0].data.year)
            ? "year"
            : pivotKeys.includes(allLeafChildren[0].data.project)
            ? "project"
            : pivotKeys.includes(allLeafChildren[0].data.portfolio)
            ? "portfolio"
            : "null";
          const selectedMonth = params.node.allLeafChildren.filter((dt) =>
            params.colDef.pivotKeys.includes(dt.data[lowestLevel])
          );
          if (selectedMonth.length) {
            const totalBudget = selectedMonth.reduce(
              (previousValue, currentValue) =>
                previousValue + Number(currentValue.data.budget),
              0
            );
            const totalActual = selectedMonth.reduce(
              (previousValue, currentValue) =>
                previousValue + Number(currentValue.data.actual),
              0
            );
            return totalBudget - totalActual;
          } else {
            return "";
          }
        }
        if (!params.node.group) {
          return params.data.budget - params.data.actual;
        }
        return params.getValue();
      },
    },
    {
      field: "note",
      headerName: "Note",
    },
  ]);

  useEffect(() => {
    getTaskSavedReports();
  }, []);

  useEffect(() => {
    if (selectedFilter?.filter_data && gridColumnApi && gridApi) {
      update(selectedFilter.filter_data);
      setSelectedSavedFilter(selectedFilter);
      setSelectedFilter(null);
      activatePreview();
    }
  }, [gridColumnApi, gridApi]);

  const onGridReady = async (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    setDefaultGridState({
      filterModel: params?.api?.getFilterModel(),
      columnState: params?.columnApi?.getColumnState(),
      columnGroupState: params?.columnApi?.getColumnGroupState(),
      pivotMode: params?.columnApi?.isPivotMode(),
    });
    const updateData = (data) => {
      params.api.setRowData(data);
    };
    const res = await getBudgetReports();
    updateData(res.data);
    agGridEvent(params);
  };

  const onPivotMode = () => {
    console.log("onPivotMode");
  };

  const agGridEvent = (props) => {
    props.api.addEventListener("firstDataRendered", changeHandler);
    props.api.addEventListener("filterChanged", changeHandler);
    props.api.addEventListener("filterModified", changeHandler);
    props.api.addEventListener("sortChanged", changeHandler);
    props.api.addEventListener("columnValueChanged", changeHandler);
    props.api.addEventListener("columnResized", changeHandler);
    props.api.addEventListener("columnVisible", changeHandler);
    props.api.addEventListener("columnPinned", changeHandler);
    props.api.addEventListener("columnResized", changeHandler);
    props.api.addEventListener("newColumnsLoaded", changeHandler);
    props.api.addEventListener("gridColumnsChanged", changeHandler);
    props.api.addEventListener("displayedColumnsChanged", changeHandler);
    props.api.addEventListener("rowGroupOpened", changeHandler);
    props.api.addEventListener("expandOrCollapseAll", changeHandler);
  };

  const changeHandler = (params) => {
    configs.filterModel = params?.api?.getFilterModel();
    configs.columnState = params?.columnApi?.getColumnState();
    configs.columnGroupState = params?.columnApi?.getColumnGroupState();
    configs.pivotMode = params?.columnApi?.isPivotMode();
  };

  const getTaskSavedReports = async () => {
    const res = await getSavedReports("budget");
    if (res?.data?.data?.items?.length > 0) {
      setSavedFilters(res.data.data.items);
    }
  };

  const updateFilters = (e, val) => {
    if (val) {
      const value = val.id;
      setSelectedSavedFilter(val);
      const selectedFilter = savedFilters.filter(
        (filter) => filter.id === value
      );
      if (selectedFilter.length && selectedFilter[0]?.filter_data) {
        update(selectedFilter[0]?.filter_data);
      }
      activatePreview();
    } else {
      setIsPriviewModeActive(false);
      activateDefaultState();
      setSelectedSavedFilter(null);
    }
  };

  const update = (filterData) => {
    const savedFilters = JSON.parse(filterData);
    const isPivotModeActive = savedFilters.pivotMode;
    gridColumnApi.setPivotMode(isPivotModeActive);
    gridApi.setFilterModel(savedFilters.filterModel);
    gridColumnApi.applyColumnState({
      state: savedFilters.columnState,
      applyOrder: true,
    });
    gridColumnApi.setColumnGroupState(savedFilters.columnGroupState);
  };

  const activatePreview = () => {
    setIsPriviewModeActive(true);
    closeToolPanel();
  };

  const activateDefaultState = () => {
    if (defaultGridState) {
      const isPivotModeActive = defaultGridState?.pivotMode;
      gridColumnApi.setPivotMode(isPivotModeActive);
      gridApi.setFilterModel(defaultGridState?.filterModel);
      gridColumnApi.applyColumnState({
        state: defaultGridState?.columnState,
        applyOrder: true,
      });
      gridColumnApi.setColumnGroupState(defaultGridState?.columnGroupState);
    }
  };

  const closeToolPanel = useCallback(() => {
    gridRef.current.api.closeToolPanel();
  }, []);

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const aggFuncs = useMemo(() => {
    return {
      variance: calculateVariance,
    };
  }, []);
  return (
    <>
      {saveFilterModalOpen && (
        <SaveFilterModal
          view="budget"
          filterData={configs}
          saveFilterModalOpen={saveFilterModalOpen}
          setSaveFilterModalOpen={setSaveFilterModalOpen}
          getTaskSavedReports={getTaskSavedReports}
        />
      )}
      <Box sx={{ width: "100%", height: "70vh" }}>
        <div className="example-wrapper">
          <SearchFilters
            isPriviewModeActive={isPriviewModeActive}
            selectedSavedFilter={selectedSavedFilter}
            setSaveFilterModalOpen={setSaveFilterModalOpen}
            savedFilters={savedFilters}
            updateFilters={updateFilters}
            setIsPriviewModeActive={setIsPriviewModeActive}
            getTaskSavedReports={getTaskSavedReports}
          />

          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              defaultColDef={{
                flex: 1,
                minWidth: 150,
                sortable: true,
                resizable: true,
                filter: true,
              }}
              ref={gridRef}
              autoGroupColumnDef={{ minWidth: 250 }}
              sideBar={true}
              onGridReady={onGridReady}
              rowData={rowData}
              onColumnPivotModeChanged={onPivotMode}
              columnDefs={colDefs}
              enableRangeSelection={true}
              enableCharts={true}
              popupParent={popupParent}
              suppressAggFuncInHeader={true}
              aggFuncs={aggFuncs}
              suppressExpandablePivotGroups={true}
              functionsReadOnly={user.custom_report === 1 ? false : true}
            ></AgGridReact>
          </div>
        </div>
      </Box>
    </>
  );
};

export default CustomBudget;
