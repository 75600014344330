import { handleApiRequest } from "../../hooks/callApi";

// Get all archived projects
export const getArchivedProjects = async () => {
  const res = await handleApiRequest(`/api/archive/projects`, "get");
  return res;
};

// Restore archived project
export const restoreArchivedProject = async (id) => {
  const res = await handleApiRequest(`/api/archive/project/${id}`, "put");
  return res;
};

// Get all archived tasks
export const getArchivedTasks = async () => {
  const res = await handleApiRequest(`/api/archive/tasks`, "get");
  return res;
};

// Restore archived task
export const restoreArchivedTask = async (id) => {
  const res = await handleApiRequest(`/api/archive/task/${id}`, "put");
  return res;
};

// Get all archived users
export const getArchivedUsers = async () => {
  const res = await handleApiRequest(`/api/archive/users`, "get");
  return res;
};

// Restore archived user
export const restoreArchivedUser = async (id) => {
  const res = await handleApiRequest(`/api/archive/user/${id}`, "put");
  return res;
};

// Get all archived portfolios
export const getArchivedPortfolios = async () => {
  const res = await handleApiRequest(`/api/archive/portfolios`, "get");
  return res;
};

// Restore archived portfolio
export const restoreArchivedPortfolio = async (id) => {
  const res = await handleApiRequest(`/api/archive/portfolio/${id}`, "put");
  return res;
};

// Get all archived milestones
export const getArchivedMilestones = async () => {
  const res = await handleApiRequest(`/api/archive/milestones`, "get");
  return res;
};

// Restore archived milestone
export const restoreArchivedMilestone = async (id) => {
  const res = await handleApiRequest(`/api/archive/milestone/${id}`, "put");
  return res;
};
