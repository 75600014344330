import React, { useState } from "react";
import {
  FormHelperText,
  Box,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import * as Yup from "yup";
import useMounted from "../../../hooks/useMounted";
import { Formik } from "formik";
import toast from "react-hot-toast";
import FileUploader from "../../FileUploader";
import { Link } from "react-router-dom";
import { importProject } from "../../../api/project/Project";
const ImportProjectModal = ({
  showImportProjectModal,
  setShowImportProjectModal,
  handleAllProject,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const mounted = useMounted();

  return (
    <Dialog
      open={showImportProjectModal}
      onClose={() => setShowImportProjectModal(false)}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <Formik
        // enableReinitialize
        initialValues={{
          submit: null,
        }}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          let data = new FormData();
          data.append("projectFile", selectedFile);

          try {
            const res = await importProject(data);

            if (res.data.success) {
              toast.success("Project imported successfully");
              handleAllProject(1, "", "");
              setShowImportProjectModal(false);
            }
            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            setStatus({ success: false });
            setErrors({ submit: "Please check file type or file content" });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleSubmit, isSubmitting }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            enctype="multipart/form-data"
          >
            <DialogTitle>Import Tasks</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FileUploader
                    onFileSelectSuccess={(file) => setSelectedFile(file)}
                    onFileSelectError={({ error }) => alert(error)}
                  />
                </Grid>
              </Grid>

              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 2 }}>
                <Link
                  to="/static/project_import_sample.xlsx"
                  target="_blank"
                  download
                  className="sample-download-btn"
                >
                  {" "}
                  Download Sample{" "}
                </Link>
              </Box>
            </DialogContent>
            <DialogActions>
              <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                <Box>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Button
                    disabled={isSubmitting}
                    size="large"
                    variant="contained"
                    onClick={() => setShowImportProjectModal(false)}
                    sx={{ backgroundColor: "#C0CBDE" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ImportProjectModal;
