import { handleApiRequest } from "../../hooks/callApi";

export const addNewTimeEntry = async ({
  userId,
  adminId,
  project,
  task,
  billable,
  overrideRate,
  description,
  weekCode,
  logDates,
  logTimes,
  totalTime,
}) => {
  const body = {
    person: userId,
    project: project,
    task: task,
    bill: billable,
    o_rate: overrideRate,
    desc: description,
    week_code: weekCode,
    added_by: adminId,
    log_dates: logDates,
    log_times: logTimes,
    total_time: totalTime,
  };

  const res = await handleApiRequest(`/api/time`, "post", body);

  return res;
};

export const editTimeEntry = async ({
  groupId,
  adminId,
  billable,
  overrideRate,
  description,
  logTimes,
  totalTime,
  approved,
}) => {
  const body = {
    bill: billable,
    o_rate: overrideRate,
    desc: description,
    modified_by: adminId,
    log_times: logTimes,
    total_time: totalTime,
    group_id: groupId,
    approved: approved,
  };
  const res = await handleApiRequest(`/api/time/${groupId}`, "put", body);
  return res;
};

export const getTimesheetRecord = async ({ userId, startDate, endDate }) => {
  const res = await handleApiRequest(
    `/api/time/${userId}?start_date=${startDate}&end_date=${endDate}`,
    "get"
  );

  return res;
};

export const approveTime = async ({ groupID, adminID, approved = 1 }) => {
  const body = {
    group_id: groupID,
    approved: approved,
    approved_by: adminID,
  };

  const res = await handleApiRequest(
    `/api/time/approve/${groupID}`,
    "post",
    body
  );

  return res;
};

export const deleteTimeEntry = async ({ groupID }) => {
  const res = await handleApiRequest(`/api/time/${groupID}`, "delete");

  return res;
};

// Get tasks for dropdown

export const taskDropDown = async ({
  userId,
  projectId,
  startDate,
  endDate,
}) => {
  const res = await handleApiRequest(
    `/api/scheduling/tasks/${userId}?proj_id=${projectId || ""}&start_date=${
      startDate || ""
    }&end_date=${endDate || ""}`,
    "get"
  );

  return res;
};

export const checkForQuickbooksSubscription = async () => {
  const res = await handleApiRequest(
    `/api/time/check-quickbooks-subscription`,
    "get"
  );

  return res;
};
