import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import {
  fetchMemberScheduling,
  fetchScheduling,
} from "../../../api/scheduling/Scheduling";
import moment from "moment";
import ViewAllSchedules from "./ViewAllSchedules";
import useLoader from "../../../hooks/useLoader";

const WeekView = ({ memberId, setUtilizationDetails }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [tabs, setTab] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const { loaderState, loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    generateTabs();
  }, []);

  useEffect(() => {
    if (tabs.length === 12) {
      getSchedulingData();
    }
    return () => {
      setSchedules([]);
    };
  }, [tabs, currentTab]);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const getSchedulingData = async () => {
    loaderStart();
    const selectedTabDetails = tabs[currentTab];
    let res;
    if (memberId) {
      res = await fetchMemberScheduling({
        startDate: selectedTabDetails.weekStartDate,
        endDate: selectedTabDetails.weekEndDate,
        userId: memberId || "",
      });
      if (res?.data?.data?.daySchedules?.data?.length > 0) {
        setUtilizationDetails({
          utilization: res?.data?.data?.utilization,
          availability: res?.data?.data?.availability,
        });
      } else {
        setUtilizationDetails({
          utilization: 0,
          availability: 100,
        });
      }
    } else {
      res = await fetchScheduling({
        startDate: selectedTabDetails.weekStartDate,
        endDate: selectedTabDetails.weekEndDate,
        userId: memberId || "",
      });
    }
    if (res?.data?.success) {
      if (memberId) {
        setSchedules(res.data.data.daySchedules);
      } else {
        setSchedules(res.data.data);
      }
    }
    loaderEnd();
  };

  const generateTabs = () => {
    let currentDate = moment();
    let weekStart = currentDate.clone().startOf("isoWeek");
    const tempTab = [];
    for (let i = 0; i <= 11; i++) {
      const weekEnd = moment(weekStart).add(6, "days").format("YYYY-MM-DD");
      const weekNumber = getWeekNumber(weekStart);
      const weekYear =
        weekNumber === 1
          ? moment(weekEnd).format("YY")
          : moment(weekStart).format("YY");

      const tempWeek = {
        label: weekNumber,
        value: i,
        weekStartDate: moment(weekStart).format("YYYY-MM-DD"),
        weekEndDate: weekEnd,
        year: weekYear,
      };

      tempTab.push(tempWeek);
      weekStart = moment(weekEnd).add(1, "days").format("YYYY-MM-DD");
    }

    setTab(tempTab);
  };

  const getWeekNumber = (dt) => {
    return moment(dt, "YYYY-MM-DD").isoWeek();
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Week View</title>
      </Helmet>
      <Box>
        <Box>
          <Tabs
            indicatorColor="primary"
            onChange={handleTabsChange}
            scrollButtons="auto"
            textColor="primary"
            value={currentTab}
            sx={{
              ".MuiTab-wrapper": {
                textTransform: "none",
                fontSize: "12px",
              },
              ".Mui-selected": {
                fontWeight: "bold",
                color: "#000",
              },
              button: {
                minWidth: "80px",
                width: "8.33%",
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                },
                "&:hover": {
                  backgroundColor: "#eee",
                },
              },
              ".MuiTabs-indicator": {
                minWidth: "60px",
                width: "8.33%",
              },
            }}
          >
            {tabs.map((tab) => (
              <Tab
                key={tab.value}
                label={`WK ${tab.label} ’${tab.year}`}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ mt: 1, p: 2 }}>
          {schedules?.data?.length > 0 ? (
            <ViewAllSchedules
              schedules={schedules}
              userView={memberId ? true : false}
            />
          ) : (
            <Typography
              variant="h6"
              color="secondary"
              align="center"
              sx={{ p: 4 }}
            >
              {loaderState ? "Loading..." : "No Records Available"}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default WeekView;
