import { handleApiRequest } from "../../hooks/callApi";

// Fetch all task reports
export const getTaskReports = async () => {
  const res = await handleApiRequest(`/api/report/aggtaskmodule`, "get");

  return res;
};

// Fetch all budget reports
export const getBudgetReports = async () => {
  const res = await handleApiRequest(`/api/report/aggbudgetmodule`, "get");

  return res;
};

// Fetch all project reports
export const getProjectReports = async () => {
  const res = await handleApiRequest(`/api/report/aggprojectmodule`, "get");

  return res;
};

// Fetch all portfolio reports
export const getPortfolioReports = async () => {
  const res = await handleApiRequest(`/api/report/aggportfoliomodule`, "get");

  return res;
};

// Fetch all member reports
export const getPeopleReports = async () => {
  const res = await handleApiRequest(`/api/report/aggpeoplemodule`, "get");

  return res;
};


// Fetch all time reports
export const getMemberReports = async () => {
  const res = await handleApiRequest(`/api/report/aggprojectmembermodule`, "get");

  return res;
};

// Fetch all time reports
export const getTimesheetReports = async () => {
  const res = await handleApiRequest(`/api/report/aggtimesheetmodule`, "get");

  return res;
};


// Fetch saved reports
export const getSavedReports = async (viewType, title) => {
  const res = await handleApiRequest(
    `/api/report/fetchdynamicreports/${viewType}?title=${title || ""}`,
    "get"
  );
  return res;
};

// delete saved reports
export const deleteSavedReport = async (reportId) => {
  const res = await handleApiRequest(
    `/api/report/deleteReport/${reportId}`,
    "delete"
  );
  return res;
};

// Save report filter
export const saveReportFilter = async ({
  title,
  description,
  view,
  level,
  filter_data,
}) => {
  const body = {
    title: title,
    description: description,
    view: view,
    level: level,
    filter_data: filter_data,
  };
  const res = await handleApiRequest(
    `/api/report/savedynamicreports`,
    "post",
    body
  );

  return res;
};

// Update report filter
export const updateReportFilter = async ({
  reportId,
  title,
  description,
  view,
  level,
  filter_data,
}) => {
  const body = {
    title: title,
    description: description,
    view: view,
    level: level,
    filter_data: filter_data,
  };
  const res = await handleApiRequest(
    `/api/report/updateReport/${reportId}`,
    "post",
    body
  );

  return res;
};
