import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import useCommons from "../hooks/useCommons";

const AuthGuard = ({ children }) => {
  const { isAuthenticated, logoutInitiated } = useAuth();
  const { setInitialRedirectURL, initialRedirectURL } = useCommons();
  const location = useLocation();

  if (!isAuthenticated) {
    setInitialRedirectURL(
      window.location.href.replace(window.location.origin, "")
    );
    return (
      <Navigate
        to={
          location.pathname === "/" || logoutInitiated
            ? "/authentication/login"
            : "/contact-company-admin"
        }
      />
    );
  }
  if (isAuthenticated && initialRedirectURL) {
    const to = initialRedirectURL;
    setInitialRedirectURL(null);
    return <Navigate to={to} />;
  }
  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
