import { handleApiRequest } from "../../hooks/callApi";

export const searchPortfolio = async (
  currentPage,
  portfoliotype,
  portfolioName
) => {
  const res = await handleApiRequest(
    `/api/portfolio/search?page=${currentPage}&portfolioName=${
      portfolioName || ""
    }&portfolioType=${portfoliotype || ""}`,
    "get"
  );
  return res;
};

export const portfolioDropDown = async () => {
  const res = await handleApiRequest(`/api/portfolio-dropdown`, "get");
  return res;
};

export const getAllPortfolioTypes = async () => {
  const res = await handleApiRequest(`/api/portfolio/type`, "get");
  return res;
};

export const deletePortfolio = async (portfolioId) => {
  const res = await handleApiRequest(`/api/portfolio/${portfolioId}`, "delete");
  return res;
};

export const getSinglePortfolio = async (portfolioId) => {
  const res = await handleApiRequest(`/api/portfolio/${portfolioId}`, "get");
  return res;
};

export const getPortfolioDashboardGanttChart = async (id, authToken) => {
  const URL = authToken
    ? `/api/shared/portfolio-project-gantt-chart/${id}`
    : `/api/portfolio-project-gantt-chart/${id}`;
  const res = await handleApiRequest(URL, "get");
  return res;
};

export const getPortfolioDashboardProgress = async (id, authToken) => {
  const URL = authToken
    ? `/api/shared/portfolio-project-progress/${id}`
    : `/api/portfolio-project-progress/${id}`;
  const res = await handleApiRequest(URL, "get");
  return res;
};

export const getPortfolioDashboardStatusGraph = async (id, authToken) => {
  const URL = authToken
    ? `/api/shared/portfolio-project-task-graph/${id}`
    : `/api/portfolio-project-task-graph/${id}`;
  const res = await handleApiRequest(URL, "get");
  return res;
};

export const getPortfolioDashboardCostGraph = async (id, authToken) => {
  const URL = authToken
    ? `/api/shared/portfolio-project-cost-graph/${id}`
    : `/api/portfolio-project-cost-graph/${id}`;
  const res = await handleApiRequest(URL, "get");
  return res;
};

export const getMilestoneReport = async (id) => {
  const res = await handleApiRequest(
    `/api/portfolio/milestone-report/${id}`,
    "get"
  );
  return res;
};
