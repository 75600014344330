import axios from "axios";
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const getStatusReport = async ({
  generatePDF,
  saveInBucket,
  projectId,
  portfolioId,
  projectAdmin,
  fileAdminId,
}) => {
  const accessToken = window.localStorage.getItem("accessToken");

  const res = await axios.post(
    `${BACKEND_URL}/api/report/status`,
    {
      gen_Pdf: generatePDF,
      save_rep: saveInBucket,
      proj_id: projectId,
      pf_id: portfolioId,
      proj_admin: projectAdmin,
      rep_adder: fileAdminId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return res;
};

export const downloadStatusReport = async ({
  generatePDF,
  saveInBucket,
  projectId,
  portfolioId,
  projectAdmin,
  fileAdminId,
}) => {
  const accessToken = window.localStorage.getItem("accessToken");

  const res = await axios
    .request({
      url: `${BACKEND_URL}/api/report/status`,
      method: "post",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        gen_Pdf: generatePDF,
        save_rep: saveInBucket,
        proj_id: projectId,
        pf_id: portfolioId,
        proj_admin: projectAdmin,
        rep_adder: fileAdminId,
      },
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]));

      const link = document.createElement("a");

      link.href = downloadUrl;

      link.setAttribute("download", "file.pdf"); //any other extension

      document.body.appendChild(link);

      link.click();

      link.remove();
    });
  return res;
};

export const fetchReports = async ({ addedBy }) => {
  const accessToken = window.localStorage.getItem("accessToken");

  const res = await axios.get(
    `${BACKEND_URL}/api/report/status/saved?report_type=0`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return res;
};

export const downloadReport = async ({ reportId }) => {
  const accessToken = window.localStorage.getItem("accessToken");

  const res = await axios.get(
    `${BACKEND_URL}/api/report/download/${reportId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return res;
};

export const healthReport = async({
  projectId,
  portfolioId,
})=>{

  const accessToken = window.localStorage.getItem("accessToken");

  const res = await axios.post(
    `${BACKEND_URL}/api/report/hoursHealth`,
    {
      proj_id: projectId,
      pf_id: portfolioId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return res;
};

export const listReport = async({
  projectId,
  portfolioId,
})=>{

  const accessToken = window.localStorage.getItem("accessToken");

  const res = await axios.post(
    `${BACKEND_URL}/api/report/tableData`,
    {
      proj_id: projectId,
      pf_id: portfolioId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return res;
};

export const healthReportPdf = async({
  imgURLId,
  projectId,
  portfolioId,
})=>{

  const accessToken = window.localStorage.getItem("accessToken");

  const res = await axios.post(
    `${BACKEND_URL}/api/report/healthReportPdf`,
    {
     imgURI: imgURLId,
     projectID: projectId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return res;
};

export const getHeading = async({
  projectId,
  portfolioId,
})=>{

  const accessToken = window.localStorage.getItem("accessToken");

  const res = await axios.post(
    `${BACKEND_URL}/api/report/getHeading`,
    {
      proj_id: projectId,
      pf_id: portfolioId,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  return res;
};
