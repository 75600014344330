import React, { useState } from "react";
import { TableCell, TableRow, Tooltip } from "@material-ui/core";
import PencilAltIcon from "../../../icons/PencilAlt";
import TrashIcon from "../../../icons/Trash";
import { deleteTemplate } from "../../../api/project/Project";
import EditTemplateModal from "../projects/EditTemplateModal";
const SingleTemplateInfo = ({ template, getAllTemplates, index }) => {
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);

  const deleteTem = async () => {
    const res = await deleteTemplate(template.id);
    if (res?.data?.success) {
      getAllTemplates();
    }
  };

  return (
    <>
      {showEditTemplateModal && (
        <EditTemplateModal
          showEditTemplateModal={showEditTemplateModal}
          setShowEditTemplateModal={setShowEditTemplateModal}
          template={template}
          getAllTemplates={getAllTemplates}
        />
      )}
      <TableRow hover>
        <TableCell sx={{ px: 2 }}>{index + 1}</TableCell>
        <TableCell>{template.title}</TableCell>
        <TableCell>{template.description}</TableCell>
        <TableCell>
          {" "}
          <>
            <Tooltip title="Edit">
              <PencilAltIcon
                onClick={() => setShowEditTemplateModal(true)}
                title="Edit"
                className="action-icon"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <TrashIcon
                onClick={deleteTem}
                title="delete"
                className="action-icon"
              />
            </Tooltip>
          </>
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleTemplateInfo;
