const roles = [
  {
    roleType: 1,
    roleValue: "Super Admin",
    message: "Content for super admin",
  },
  {
    roleType: 2,
    roleValue: "Admin",
    message: "Content for Admin",
  },
  {
    roleType: 3,
    roleValue: "Manager",
    message: "Content for Manager",
  },
  // {
  //   roleType: 4,
  //   roleValue: "Power User",
  //   message: "Content for Power User",
  // },
  {
    roleType: 5,
    roleValue: "User",
    message: "Content for User",
  },
  {
    roleType: 6,
    roleValue: "Viewer",
    message: "Content for Viewer",
  },
];

export default roles;
