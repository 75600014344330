import { Grid, LinearProgress, Typography } from "@material-ui/core";
import React from "react";

const SingleProgressGraph = ({ data }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{ alignItems: "center", pl: 2, pt: 0, mb: 1 }}
    >
      <Grid item xs={3} sx={{ pt: 0 }}>
        <Typography sx={{ fontSize: "14px !important", fontWeight: "500" }}>
          {data.name}
        </Typography>
      </Grid>
      <Grid item xs={1} sx={{ pt: 0 }}>
        <Typography sx={{ fontSize: "14px !important", fontWeight: "500" }}>
          {data.percent}
        </Typography>
      </Grid>
      <Grid item xs={8} sx={{ pt: 0 }}>
        <LinearProgress
          sx={{
            height: "10px",
            backgroundColor: "#ddd",
            ".MuiLinearProgress-bar": { backgroundColor: data.color },
          }}
          variant="determinate"
          value={data.percent}
        />
      </Grid>
    </Grid>
  );
};

export default SingleProgressGraph;
