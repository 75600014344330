import {Box} from '@material-ui/core'
import LogoImg from '../assets/images/Logo.png'

const Logo = ({ height }) => (
  <Box
    alt="Logo Image"
    component="img"
    src={LogoImg}
    sx={{
      height: height || "30px",
      width: "auto",
    }}
  />
);

export default Logo