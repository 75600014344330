import { handleApiRequest } from "../../hooks/callApi";

export const generateShareLink = async (projectId) => {
  const body = {
    project_id: projectId,
  };

  const res = await handleApiRequest(
    `/api/shareableProjectDashboard`,
    "post",
    body
  );
  return res;
};

export const generateShareTaskLink = async (projectId) => {
  const body = {
    project_id: projectId,
  };

  const res = await handleApiRequest(`/api/shareableProjectTask`, "post", body);
  return res;
};

export const getProjectID = async (token) => {
  const res = await handleApiRequest(
    `/api/get-shared-project-id/${token}`,
    "get",
    null,
    {
      sharedKey: token,
    }
  );
  return res;
};

export const generatePortfolioShareLink = async (portfolioID) => {
  const body = {
    portfolio_id: portfolioID,
  };

  const res = await handleApiRequest(
    `/api/shareablePortfolioDashboard`,
    "post",
    body
  );
  return res;
};

export const getSharedPortfolioID = async (token) => {
  const res = await handleApiRequest(
    `/api/get-shared-portfolio-id/${token}`,
    "get"
  );
  return res;
};
