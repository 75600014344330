import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Pagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import SingleIssue from "./SingleIssue";
import AddNewIssueModal from "./AddNewIssueModal";
import { getAllProjectIssues } from "../../../api/project/Project";
import useLoader from "../../../hooks/useLoader";

const Issues = ({ setShowAddIssueodal, showAddIssueodal, selectedProject }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [issues, setIssues] = useState([]);
  const handleChange = (event, value) => {
    setCurrentPage(value);
  };
  const { loaderState, loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    getIssues();
  }, [currentPage]);

  const getIssues = async () => {
    loaderStart();
    const res = await getAllProjectIssues({
      projectId: selectedProject.id,
      page: currentPage,
    });

    if (res.data.success) {
      setIssues(res.data.data.issues);
    }
    loaderEnd();
  };

  return (
    <Box sx={{ pt: 3 }}>
      {showAddIssueodal && (
        <AddNewIssueModal
          setShowAddIssueodal={setShowAddIssueodal}
          showAddIssueodal={showAddIssueodal}
          selectedProject={selectedProject}
          getIssues={getIssues}
        />
      )}
      <Table>
        <TableHead>
          <TableRow sx={{ th: { fontWeight: "700", fontSize: "13px" } }}>
            <TableCell>#</TableCell>
            <TableCell>ISSUE NAME</TableCell>
            <TableCell>ISSUE ID</TableCell>
            <TableCell>STATUS </TableCell>
            <TableCell>START DATE</TableCell>
            <TableCell>END DATE </TableCell>
            <TableCell>ASSIGNED TO</TableCell>
            <TableCell>Document</TableCell>
            <TableCell align="center">ACTION</TableCell>
          </TableRow>
        </TableHead>
        {issues?.data?.length > 0 && (
          <TableBody>
            {issues?.data.map((issue, index) => {
              return (
                <SingleIssue
                  issue={issue}
                  key={index}
                  index={index}
                  getIssues={getIssues}
                />
              );
            })}
          </TableBody>
        )}
      </Table>
      <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
        {issues?.data?.length > 0 ? (
          <Pagination
            page={currentPage}
            onChange={handleChange}
            count={issues.last_page}
          />
        ) : (
          <Typography variant="h6" color="secondary">
            {loaderState ? "Loading..." : "No Records Available"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default Issues;
