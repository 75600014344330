import React, { useState, useEffect } from "react";
import { Link as RouterLink, Prompt, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import ChevronRightIcon from "../../icons/ChevronRight";
import StageSettingContent from "../../components/dashboard/admin/stages-settings/StageSettingContent";
import useSettings from "../../hooks/useSettings";
import { addStage } from "../../api/admin/Stage";
import toast from "react-hot-toast";
import useCommons from "../../hooks/useCommons";

const StagesSetting = () => {
  const { settings } = useSettings();
  const { stages, getStages } = useCommons();
  const [count, setCount] = useState([1, 2, 3]);
  const [stage, setStage] = useState([]);
  const [editActive, setEditActive] = useState(false);

  let navigate = useNavigate();
  useEffect(() => {
    if (stages.length > 0) {
      setStage(stages);
      const tempArr = [];
      for (let i = 1; i <= stages.length; i++) {
        tempArr.push(i);
      }
      setCount(tempArr);
    }
  }, [stages]);

  const handleChange = (order, val) => {
    setEditActive(true);
    let temp = [...stage];
    if (!val) {
      temp[order - 1] = null;
    } else {
      temp[order - 1] = { order: order, stage: val };
    }
    setStage(temp);
  };

  const handleAddCount = () => {
    setEditActive(true);
    setCount([...count, count.length + 1]);
  };

  const handleRemoveCount = () => {
    setEditActive(true);
    const temp = [...count];
    const tempStage = [...stage];
    temp.pop();
    if (count.length === stages.length) {
      tempStage.pop();
      setStage(tempStage);
    }
    setCount(temp);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    createStage();
  };

  const createStage = async () => {
    const res = await addStage(stage);
    if (res.data.success) {
      toast.success("Stage Added Successfully");
      setEditActive(false);
      getStages();
      // Go to previous page
      navigate(-1);
    }
  };

  const onCancel = () => {
    setEditActive(false);
    if (stages.length > 0) {
      setStage(stages);
      const tempArr = [];
      for (let i = 1; i <= stages.length; i++) {
        tempArr.push(i);
      }
      setCount(tempArr);
    } else {
      setStage([]);
      setCount([1, 2, 3]);
    }
    navigate(-1);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Stages Settings |</title>
      </Helmet>
      <Prompt
        when={editActive}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Projects
                </Typography>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Workflow
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Workflow
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                sx={{ m: 1 }}
                variant="contained"
                onClick={handleAddCount}
                disabled={count.length === 100}
              >
                ADD STEP
              </Button>
              <Button
                color="primary"
                sx={{ m: 1 }}
                variant="outlined"
                onClick={handleRemoveCount}
                disabled={count.length === 1}
              >
                Remove Step
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <form onSubmit={handleSubmit}>
              <Card sx={{ py: 6, px: 2 }}>
                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                  {count.map((item) => (
                    <StageSettingContent
                      order={item}
                      handleChange={handleChange}
                      stage={stage[item - 1] || null}
                    />
                  ))}
                </Grid>
                <Box sx={{ display: "flex", mr: "auto", pb: 1, mt: 3 }}>
                  <Box>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      type="submit"
                    >
                      SAVE
                    </Button>
                  </Box>
                  <Box sx={{ px: 1 }}>
                    <Button
                      onClick={onCancel}
                      color="primary"
                      size="large"
                      variant="outlined"
                    >
                      CANCEL
                    </Button>
                  </Box>
                </Box>
              </Card>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default StagesSetting;
