import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useLoader from "../hooks/useLoader";
import useCommons from "../hooks/useCommons";
import {
  acceptUserRequestAPI,
  addCredentialAPI,
  getAllUserRequestsAPI,
  getAllUsersAPI,
} from "../api/user/User";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  allUsers: [],
  userRequests: [],
  logoutInitiated: false,
  permissions: [],
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, permissions } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      permissions,
    };
  },
  LOGIN: (state, action) => {
    const { user, permissions } = action.payload;

    return {
      ...state,
      logoutInitiated: false,
      isAuthenticated: true,
      user,
      permissions,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    logoutInitiated: true,
    isAuthenticated: false,
    user: null,
    permissions: [],
  }),
  REGISTER: (state, action) => {
    return {
      ...state,
    };
  },
  GETALLUSERS: (state, action) => {
    const { allUsers } = action.payload;
    return {
      ...state,
      allUsers,
    };
  },
  GETALLUSERREQUESTS: (state, action) => {
    const { userRequests } = action.payload;
    return {
      ...state,
      userRequests,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  platform: "JWT",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  getAllUsers: () => Promise.resolve(),
  acceptUserRequest: () => Promise.resolve(),
  getAllUserRequests: () => Promise.resolve(),
  addUserCredential: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loaderStart, loaderEnd } = useLoader();
  const { initialRedirectURL, setInitialRedirectURL } = useCommons();

  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken) {
          const res = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/me`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (res?.data?.success) {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: true,
                user: res.data.data.user,
                permissions: res.data.data.permission,
              },
            });
          } else {
            dispatch({
              type: "INITIALIZE",
              payload: {
                isAuthenticated: false,
                permissions: [],
                user: null,
              },
            });
          }
        } else {
          dispatch({
            type: "INITIALIZE",
            payload: {
              isAuthenticated: false,
              user: null,
              permissions: [],
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/login`,
        {
          email,
          password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (res.data.data.subscriptionExpired) {
        return { cred: false, message: res.data.data.message };
      }
      if (res.data.success) {
        localStorage.setItem("accessToken", res.data.data.token);

        const user = res.data.data.user;
        const permissions = res.data.data.permission;
        dispatch({
          type: "LOGIN",
          payload: {
            user,
            permissions,
          },
        });
        const navigateURL = initialRedirectURL;
        setInitialRedirectURL(null);
        if (navigateURL) {
          navigate(navigateURL);
        }
      } else if (!res.data) {
        return { cred: false };
      } else {
        navigate("/authentication/request-pending");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async () => {
    localStorage.removeItem("accessToken");
    dispatch({ type: "LOGOUT" });
  };

  const register = async (
    email,
    first_name,
    last_name,
    company,
    password,
    c_password
  ) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/register`,
        {
          first_name,
          last_name,
          email,
          company,
          password,
          c_password,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      dispatch({
        type: "REGISTER",
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const acceptUserRequest = async (uid, userState, isActive = 1) => {
    try {
      const res = await acceptUserRequestAPI(uid, userState, isActive);
      if (userState === 1) {
        toast.success("Successfully Added User Permission");
      } else if (userState === 0) {
        toast.error("Removed user Permission");
      }
      return res;
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const getAllUsers = async (pageNum, roleFilter, userNameSearch) => {
    loaderStart();
    const res = await getAllUsersAPI(pageNum, roleFilter, userNameSearch);

    dispatch({
      type: "GETALLUSERS",
      payload: {
        allUsers: res.data.data.users,
      },
    });
    loaderEnd();
  };

  const getAllUserRequests = async (pageNum) => {
    loaderStart();
    const res = await getAllUserRequestsAPI(pageNum);

    dispatch({
      type: "GETALLUSERREQUESTS",
      payload: {
        userRequests: res.data.data.unauthUsers,
      },
    });
    loaderEnd();
  };

  const addUserCredential = async (credential) => {
    try {
      const res = await addCredentialAPI(credential);

      if (res.data.success) {
        toast.success("New member credential added");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        logout,
        register,
        getAllUsers,
        acceptUserRequest,
        getAllUserRequests,
        addUserCredential,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
