import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { editDepartment } from "../../../api/department/Department";
import { getUsersUnderAdmin } from "../../../api/project/Project";
import useMounted from "../../../hooks/useMounted";
const ViewAndEditDepartmentModal = (props) => {
  const {
    showViewAndEditDepartmentModal,
    setShowViewAndEditDepartmentModal,
    getAllDepartmentsUnderAdmin,
    singleDepartment,
  } = props;

  const [editActive, setEditActive] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [taskAssignedTo, setTaskAssignedTo] = useState(
    singleDepartment?.get_department_head
      ? singleDepartment?.get_department_head
      : {
          id: "",
          first_name: "",
          last_name: "",
        }
  );

  const mounted = useMounted();

  useEffect(() => {
    getMemberList();
  }, []);

  const getMemberList = async () => {
    const res = await getUsersUnderAdmin();
    if (res.data.success) {
      setMemberList(res.data.data.peopleDropdown);
    } else {
      console.log("No Members");
    }
  };

  const handleUChange = (e, val) => {
    setTaskAssignedTo(val);
  };

  return (
    <>
      <Dialog
        open={showViewAndEditDepartmentModal}
        onClose={() => setShowViewAndEditDepartmentModal(false)}
        fullWidth={true}
        maxWidth={"sm"}
      >
        {!editActive && (
          <Box
            sx={{
              px: 3,
              py: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">{editActive ? "EDIT" : "VIEW"}</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditActive(true)}
            >
              Edit
            </Button>
          </Box>
        )}
        <Formik
          initialValues={{
            departmentId: singleDepartment.id,
            departmentName: singleDepartment.department,
            departmentHead: singleDepartment.head_id,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            departmentName: Yup.string()
              .max(255)
              .required("Department Name is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const res = await editDepartment({
                ...values,
                departmentHead: taskAssignedTo?.id || "",
              });

              if (res.data.success) {
                toast.success("Department edited");
                setShowViewAndEditDepartmentModal(false);
                getAllDepartmentsUnderAdmin();
              }

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              toast.error("Something went wrong");
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit} {...props}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      disabled={!editActive}
                      label="Department Name"
                      required
                      error={Boolean(
                        touched.departmentName && errors.departmentName
                      )}
                      fullWidth
                      helperText={
                        touched.departmentName && errors.departmentName
                      }
                      margin="normal"
                      name="departmentName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.departmentName}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      disabled={!editActive}
                      disablePortal
                      id="combo-box-demo"
                      blurOnSelect={true}
                      options={memberList}
                      defaultValue={taskAssignedTo}
                      onChange={handleUChange}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name}`
                      }
                      renderOption={(props, option) => {
                        return (
                          <li
                            {...props}
                            key={`${option.first_name} ${option.last_name} ${option.id}`}
                          >
                            {`${option.first_name} ${option.last_name}`}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Department Head" />
                      )}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={() => setShowViewAndEditDepartmentModal(false)}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default ViewAndEditDepartmentModal;
