import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { dropdownProjects } from "../../../api/budget/Budget";
import { taskDropDown } from "../../../api/time/Time";
import useAuth from "../../../hooks/useAuth";
import { assignTask } from "../../../api/scheduling/Scheduling";
import toast from "react-hot-toast";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AssignTaskToMember = ({
  userId,
  isModalOpen,
  setIsModalOpen,
  startDate,
  endDate,
}) => {
  const classes = useStyles();
  const [allProjects, setAllProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [allProjectTasks, setAllProjectTasks] = useState([]);

  const [values, setValues] = useState({
    task: "",
    project: "",
  });

  const { user } = useAuth();

  useEffect(() => {
    getDropdownProjects();
  }, [userId, startDate, endDate]);

  useEffect(() => {
    if (selectedProject !== "") {
      getDropdownTasks();
    }
  }, [selectedProject]);

  const getDropdownProjects = async () => {
    const res = await dropdownProjects({
      adminId: user.id,
      portfolioId: "",
      memberId: userId,
      startDate,
      endDate,
      onlyProjectsWhereUserIsMember: true,
    });
    if (res?.data?.success) {
      setAllProjects(res.data.data.projectDropdown);
    }
  };

  const getDropdownTasks = async () => {
    const res = await taskDropDown({
      userId: 0,
      projectId: selectedProject,
      startDate: startDate,
      endDate: endDate,
    });
    if (res.data.success) {
      setAllProjectTasks(res.data.data.userTasks);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    if (name === "project") {
      setSelectedProject(event.target.value);
    }
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await assignTask({
      userId: userId,
      taskId: values.task,
    });
    if (res.data.success) {
      toast.success(res.data.message);
    }
    setIsModalOpen(null);
  };
  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(null)}
        maxWidth="sm"
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle>Assign Task</DialogTitle>
          <DialogContent>
            <FormControl className={classes.formControl} required>
              <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                Select Project
              </InputLabel>
              <Select
                labelId="select-status"
                label="SELECT PROJECT"
                name="project"
                value={values.project}
                fullWidth
                onChange={handleChange}
              >
                <MenuItem value="">none</MenuItem>
                {allProjects?.length > 0 &&
                  allProjects.map((proj) => (
                    <MenuItem key={proj.id} value={proj.id}>
                      {proj.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl
              className={classes.formControl}
              required
              sx={{
                mt: 2,
              }}
            >
              <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                Task{" "}
              </InputLabel>
              <Select
                labelId="select-status"
                label="task *"
                name="task"
                value={values.task}
                fullWidth
                placeholder="task"
                onChange={handleChange}
              >
                <MenuItem value="">none</MenuItem>
                {allProjectTasks?.length > 0 &&
                  allProjectTasks.map((task) => (
                    <MenuItem key={task.id} value={task.id}>
                      {task.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsModalOpen(null)}
              variant="contained"
              color="error"
              sx={{
                mr: 1,
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              type="submit"
            >
              Assign
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AssignTaskToMember;
