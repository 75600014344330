import { Button, Popover, Typography } from "@material-ui/core";
import React from "react";
import AssignTask from "./AssignTask";

const ScheduleAction = ({ userId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showAssignTaskModal, setShowAssignTaskModal] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {showAssignTaskModal && (
        <AssignTask
          showAssignTaskModal={showAssignTaskModal}
          setShowAssignTaskModal={setShowAssignTaskModal}
          userId={userId}
        />
      )}
      <Button aria-describedby={id} variant="outlined" onClick={handleClick}>
        Action
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* <Typography sx={{ p: 2 }}>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/dashboard/scheduling/member/task/${userId}?projectId=${projectId}`}
            underline="none"
            variant="subtitle2"
          >
            View Task
          </Link>
        </Typography> */}
        <Typography
          onClick={() => setShowAssignTaskModal(true)}
          sx={{ p: 2, cursor: "pointer" }}
        >
          Assign Task
        </Typography>
      </Popover>
    </>
  );
};

export default ScheduleAction;
