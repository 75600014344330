// src/components/MemberContributionBarChart.js
import React from "react";
import Chart from "react-apexcharts";

const MemberContributionBarChart = ({ data }) => {
  const options = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Member Contribution",
      align: "center",
    },
    xaxis: {
      categories: data.map((data) => data.member),
      title: {
        text: "Total Points",
      },
    },
    yaxis: {
      title: {
        text: "Team Members",
      },
    },
  };

  const series = [
    {
      name: "Total Points",
      data: data.map((data) => data.points),
    },
  ];

  return <Chart options={options} series={series} type="bar" height={350} />;
};

export default MemberContributionBarChart;
