import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { changeCompanyID, getAllCompanies } from "../../../api/user/User";
import useAuth from "../../../hooks/useAuth";
import useLoader from "../../../hooks/useLoader";
import useMounted from "../../../hooks/useMounted";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const ChangeCompanyModal = (props) => {
  const { changeCompanyModalState, setChangeCompanyModalState } = props;
  const [companies, setCompanies] = useState([]);
  const { user } = useAuth();
  const { loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const res = await getAllCompanies("");
    if (res.data.data) {
      setCompanies(res.data.data.company_data);
    }
  };

  const mounted = useMounted();
  const classes = useStyles();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={changeCompanyModalState}
      onClose={() => setChangeCompanyModalState(false)}
    >
      <Formik
        initialValues={{
          company: user.company_id,
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          company: Yup.number().required("Please select a company"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            loaderStart();
            const res = await changeCompanyID(values.company);
            if (res?.data?.data?.value) {
              toast.success("Company Changed Successfully");
              loaderEnd();
              setChangeCompanyModalState(false);
              window.location.reload();
            }
            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            toast.error("Something went wrong");
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            autocomplete="off"
            {...props}
          >
            <DialogTitle>Change Company</DialogTitle>
            <DialogContent sx={{ p: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    error={Boolean(
                      touched.subscriptionType && errors.subscriptionType
                    )}
                  >
                    <InputLabel id="select-status" sx={{ color: "#bbb" }}>
                      {" "}
                      Select Company{" "}
                    </InputLabel>
                    <Select
                      error={Boolean(touched.company && errors.company)}
                      fullWidth
                      helperText={touched.company && errors.company}
                      margin="normal"
                      name="company"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.company}
                      variant="outlined"
                      label="Select Company"
                      sx={{ mt: 0 }}
                    >
                      {companies.map((individualCompany) => (
                        <MenuItem
                          key={individualCompany.id}
                          value={individualCompany.id}
                        >
                          {" "}
                          {`${individualCompany.company_name} [${individualCompany.code}]`}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                <Box>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Button
                    disabled={isSubmitting}
                    size="large"
                    variant="contained"
                    onClick={() => setChangeCompanyModalState(false)}
                    sx={{ backgroundColor: "#C0CBDE" }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ChangeCompanyModal;
