import { Navigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const AdminSuperAdminGuard = ({ children }) => {
  const { user } = useAuth();
  if (user.role === 2 || user.role === 1) {
    return <>{children}</>;
  }

  return <Navigate to="/" />;
};

export default AdminSuperAdminGuard;
