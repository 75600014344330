import { handleApiRequest } from "../../hooks/callApi";

export const getAllTemplates = async () => {
  const res = await handleApiRequest(`/api/process-templates`, "get");

  return res;
};

export const deleteTemplate = async (id) => {
  const res = await handleApiRequest(`/api/process-templates/${id}`, "delete");

  return res;
};

export const createTemplate = async (data) => {
  const res = await handleApiRequest(`/api/process-templates`, "post", data);

  return res;
};
