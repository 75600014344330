import React, { useState } from "react";
import {
  Edit as EditIcon,
  DeleteOutline,
  Add,
  Check,
  UndoOutlined,
} from "@material-ui/icons";
import { Box, TableCell, TableRow, Typography } from "@material-ui/core";
import AddPreveiousTime from "./AddPreviousTime";
import EditTimeEntry from "./EditTimeEntry";
import { deleteTimeEntry } from "../../../api/time/Time";
import toast from "react-hot-toast";
import { handleApiRequest } from "../../../hooks/callApi";
const SingleProjectHour = ({
  singleTask,
  week,
  year,
  selectedUser,
  getUserTimeLogHistory,
}) => {
  const [showPreviousTimeModal, setShowPreviousTimeModal] = useState(false);
  const [showEditTimeModal, setShowEditTimeModal] = useState(false);

  const deleteTimeEntryRecoed = async () => {
    const res = await deleteTimeEntry({
      groupID: singleTask.id,
    });
    if (res?.data?.success) {
      toast.success("Log Deleted");
      getUserTimeLogHistory();
    }
  };
  return (
    <>
      {showPreviousTimeModal && (
        <AddPreveiousTime
          showPreviousTimeModal={showPreviousTimeModal}
          setShowPreviousTimeModal={setShowPreviousTimeModal}
          week={week}
          year={year}
          selectedUser={selectedUser}
          getUserTimeLogHistory={getUserTimeLogHistory}
        />
      )}
      {showEditTimeModal && (
        <EditTimeEntry
          showEditTimeModal={showEditTimeModal}
          setShowEditTimeModal={setShowEditTimeModal}
          week={week}
          year={year}
          selectedUser={selectedUser}
          getUserTimeLogHistory={getUserTimeLogHistory}
          currentTimeEntry={singleTask}
        />
      )}
      <TableRow
        sx={{
          td: {
            borderBottom: "1px solid #ECF1F9",
          },
        }}
      >
        <TableCell>
          {!singleTask.fakeData ? (
            <>
              {!singleTask.isApproved ? (
                <Box sx={{ display: "flex" }}>
                  <Box
                    component="span"
                    sx={{
                      p: 1,
                      backgroundColor: "#0043ff26",
                      display: "inherit",
                      lineHeight: 0,
                      borderRadius: "6px",
                    }}
                    onClick={() => setShowEditTimeModal(true)}
                  >
                    <EditIcon className="action-icon time-edit" />
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      p: 1,
                      backgroundColor: "#FB6C7E26",
                      display: "inherit",
                      lineHeight: 0,
                      borderRadius: "6px",
                      ml: 1,
                    }}
                    onClick={() => deleteTimeEntryRecoed()}
                  >
                    <DeleteOutline className="action-icon time-delete" />
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      p: 1,
                      backgroundColor: "lightgoldenrodyellow",
                      display: "inherit",
                      lineHeight: 0,
                      borderRadius: "6px",
                      ml: 1,
                    }}
                    onClick={async () => {
                      const { data } = await handleApiRequest(
                        `/api/time-entry-backups/${singleTask.id}`,
                        "get"
                      );
                      console.log(data);
                      if (!data.backup_data)
                        return toast.error("No time entry to restore");
                      const resRestore = await handleApiRequest(
                        `/api/time/${singleTask.id}`,
                        "put",
                        JSON.parse(data.backup_data)
                      );
                      console.log(resRestore);
                      toast.success("Restored successfully");
                      const destroyedData = await handleApiRequest(
                        `/api/time-entry-backups/${data.id}`,
                        "delete"
                      );
                      console.log(destroyedData);
                      getUserTimeLogHistory();
                    }}
                  >
                    <UndoOutlined className="action-icon time-undo" />
                  </Box>
                </Box>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component="span"
                    sx={{
                      p: 1,
                      backgroundColor: "#0043ff26",
                      display: "inherit",
                      lineHeight: 0,
                      borderRadius: "6px",
                      mr: 1,
                    }}
                  >
                    <Check className="action-icon time-edit" />
                  </Box>
                  <Typography>Approved</Typography>
                </Box>
              )}
            </>
          ) : (
            <Box sx={{ display: "flex" }}>
              <Box
                component="span"
                sx={{
                  p: 1,
                  backgroundColor: "#FB6C7E26",
                  display: "inherit",
                  lineHeight: 0,
                  borderRadius: "6px",
                }}
                onClick={() => setShowPreviousTimeModal(true)}
              >
                <Add className="action-icon time-delete" />
              </Box>
            </Box>
          )}
        </TableCell>
        <TableCell sx={{ maxWidth: "150px" }}>
          {!singleTask?.fakeData ? (
            <>
              <b>Project:</b> {singleTask?.projectName} <br />
              <b>Billable:</b> {singleTask?.billable ? "Yes" : "No"}
              <br />
              <b>Override Rate:</b> {singleTask?.overrideRate ? "Yes" : "No"}
              <br />
              <b>Description:</b> {singleTask?.description}
            </>
          ) : (
            <b>{singleTask?.description}</b>
          )}
        </TableCell>
        <TableCell></TableCell>
        {singleTask?.weeklyReport.map((singleDayInAWeek) => (
          <TableCell>{singleDayInAWeek.hrs}</TableCell>
        ))}
        <TableCell sx={{ fontWeight: "600" }}>
          {singleTask?.totalWeekTaskHours}
        </TableCell>
      </TableRow>
    </>
  );
};

export default SingleProjectHour;
