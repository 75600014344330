import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import CreatePortfolioForm from "../../components/dashboard/portfolios/CreatePortfolioForm";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";

const AddNewPortfolio = () => {
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>Dashboard: Add New Portfolio</title>
      </Helmet>

      <Box
        sx={{ backgroundColor: "background.default", minHeight: "100%", py: 2 }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Add New Portfolio
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <CreatePortfolioForm />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default AddNewPortfolio;
