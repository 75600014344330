import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { editUserCredential } from "../../../api/user/User";
import useMounted from "../../../hooks/useMounted";

const EditCredentialModal = (props) => {
  const {
    memberModalState,
    handleCreateNewMemberModalClose,
    userData,
    handleAllUser,
    currentPage,
    filterUserRole,
    nameSearchValue,
  } = props;

  const mounted = useMounted();

  return (
    <>
      <Dialog open={memberModalState} onClose={handleCreateNewMemberModalClose}>
        <Formik
          initialValues={{
            email: userData.email.split(":")[1] || userData.email,
            first_name: userData.first_name,
            last_name: userData.last_name,
            userName: userData.username || "",
            role: userData.role.id || "",
            password: "",
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Must be a valid email")
              .max(255)
              .required("Email is required"),
            first_name: Yup.string()
              .max(255)
              .required("First Name is required"),
            role: Yup.string().max(255).required("User Role is required"),
            userName: Yup.string().max(255).nullable(),
            last_name: Yup.string().max(255).required("Last Name is required"),
            password: Yup.string()
              .min(7)
              .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{7,}$/,
                "Must Contain One Uppercase, One Lowercase, One Number and one special case Character"
              )
              .nullable(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              const res = await editUserCredential({
                userId: userData.id,
                firstName: values.first_name,
                lastName: values.last_name,
                email: values.email,
                password: values.password,
                role: values.role,
              });
              handleCreateNewMemberModalClose();
              if (res.data.success) {
                toast.success("User credential modifed");
                handleAllUser(currentPage, filterUserRole, nameSearchValue);
              }
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err) {
              console.error(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form
              autocomplete="off"
              noValidate
              onSubmit={handleSubmit}
              role="presentation"
              {...props}
            >
              <DialogTitle>Edit User Credentials</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="First Name"
                      required
                      error={Boolean(touched.first_name && errors.first_name)}
                      fullWidth
                      helperText={touched.first_name && errors.first_name}
                      margin="normal"
                      name="first_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.first_name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Last Name"
                      required
                      error={Boolean(touched.last_name && errors.last_name)}
                      fullWidth
                      helperText={touched.last_name && errors.last_name}
                      margin="normal"
                      name="last_name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.last_name}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Email"
                      required
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      margin="normal"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      value={values.email}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="User Name"
                      error={Boolean(touched.userName && errors.userName)}
                      fullWidth
                      helperText={touched.userName && errors.userName}
                      margin="normal"
                      name="userName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.userName}
                      variant="outlined"
                      autoComplete="new-password"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Select
                      label="Role"
                      error={Boolean(touched.role && errors.role)}
                      fullWidth
                      helperText={touched.role && errors.role}
                      margin="normal"
                      name="role"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.role}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    >
                      <MenuItem value={2}> Admin </MenuItem>
                      <MenuItem value={3}> Manager </MenuItem>
                      {/* <MenuItem value={4}> Power User </MenuItem> */}
                      <MenuItem value={5}> User </MenuItem>
                      <MenuItem value={6}> Viewer </MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Password"
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      autoComplete="new-password"
                      value={values.password}
                      variant="outlined"
                      sx={{ mt: 0 }}
                    />
                  </Grid>
                </Grid>

                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: "flex", mr: "auto", px: 2, pb: 1 }}>
                  <Box>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      size="large"
                      type="submit"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </Box>
                  <Box sx={{ ml: 1 }}>
                    <Button
                      disabled={isSubmitting}
                      size="large"
                      variant="contained"
                      onClick={handleCreateNewMemberModalClose}
                      sx={{ backgroundColor: "#C0CBDE" }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default EditCredentialModal;
