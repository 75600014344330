import React from "react";

import {
  Box,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Pagination,
  Link,
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";
import useTable from "../../../hooks/useTable";
// Testing Production Action

const headCells = [
  { id: "portfolioName", label: "PORTFOLIO NAME", disableSorting: true },
  { id: "projectName", label: "PROJECT NAME" },
  { id: "budget", label: "BUDGET ($)" },
  { id: "actual", label: "ACTUAL ($)" },
  { id: "variance", label: "VARIANCE ($)" },
];

const AllProjectBudget = ({ currentPage, setCurrentPage, budget }) => {
  const { TblContainer, TblHead, recordsAfterPagingAndSorting } = useTable(
    budget?.data,
    headCells
  );

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box sx={{ pt: 3 }}>
      <TblContainer>
        <TblHead />
        {budget?.data?.length > 0 && (
          <TableBody>
            {recordsAfterPagingAndSorting().map((portfolio, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell>
                    {portfolio?.get_portfolio ? (
                      <Link
                        color="textPrimary"
                        component={RouterLink}
                        to={`/dashboard/portfolios/view-and-edit-portfolio/${portfolio?.get_portfolio?.id}`}
                        underline="none"
                        variant="subtitle2"
                        className="redirect-link"
                      >
                        {portfolio?.get_portfolio?.name}
                      </Link>
                    ) : (
                      "-"
                    )}
                  </TableCell>
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to={`/dashboard/projects/view-and-edit-project/${portfolio?.id}`}
                      underline="none"
                      variant="subtitle2"
                      className="redirect-link"
                    >
                      {portfolio?.projectName}
                    </Link>
                  </TableCell>
                  <TableCell>{portfolio?.budget}</TableCell>
                  <TableCell>{portfolio?.actual}</TableCell>
                  <TableCell>{portfolio?.variance}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        )}
      </TblContainer>
      <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
        {budget?.data?.length > 0 ? (
          <Pagination
            page={currentPage}
            onChange={handleChange}
            count={budget.last_page}
          />
        ) : (
          <Typography variant="h6" color="secondary">
            No Records Available
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AllProjectBudget;
