import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link as RouterLink } from "react-router-dom";
import {
  getDepartmentsForDropdown,
  getDesignationsForDropdown,
} from "../../api/department/Department";
import Utilization from "../../components/dashboard/people/Utilization";
import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import { getProjectDropDown } from "../../slices/user";
import { useDispatch, useSelector } from "../../store";

const PeopleUtilization = () => {
  const dispatch = useDispatch();
  const { settings } = useSettings();
  const { projectDropDown } = useSelector((state) => state.userList);
  const [utilizationType, setUtilizationType] = useState("week");
  const [viewPercentage, setViewPercentage] = useState(true);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [departmentDropDownOptions, setDepartmentDropDownOptions] = useState(
    []
  );
  const [selectedRole] = useState("");
  const [steps, setSteps] = useState(0);
  const [designationDropDownOptions, setDesignationDropDownOptions] = useState(
    []
  );
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [memberNameFilter, setMemberNameFilter] = useState("");
  const [projectFilter, setProjectFilter] = useState("");

  useEffect(() => {
    getDepartments();
    getDesignationDropdown();
    dispatch(getProjectDropDown());
  }, []);

  useEffect(() => {
    setSteps(0);
  }, [utilizationType]);

  const handleStepsChange = (clickType) => {
    if (clickType === "next") {
      setSteps((prev) => prev + 1);
    } else if (clickType === "prev") {
      setSteps((prev) => prev - 1);
    } else if (clickType === "today") {
      setSteps(0);
    }
  };

  const getDepartments = async () => {
    const res = await getDepartmentsForDropdown();
    const deps = res.data.data.departments || [];
    // SORTING DEPARTMENT DROPDOWN OPTIONS by later case insensitive
    deps.sort((a, b) =>
      a.department.toLowerCase() > b.department.toLowerCase() ? 1 : -1
    );

    setDepartmentDropDownOptions(deps);
  };

  const getDesignationDropdown = async () => {
    const res = await getDesignationsForDropdown();
    const designations = res.data.data.items || [];
    // SORTING DESIGNATION DROPDOWN OPTIONS by later case insensitive
    designations.sort((a, b) =>
      a.designation.toLowerCase() > b.designation.toLowerCase() ? 1 : -1
    );

    setDesignationDropDownOptions(designations);
  };

  return (
    <>
      <Helmet>
        <title>Dashboard: Resource Utilization</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  Resource Utilization
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                Resource Utilization
              </Typography>
            </Grid>
            <Grid item sx={{ display: "flex" }}>
              <Box sx={{ m: -1 }}>
                <Button
                  color="primary"
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() =>
                    setViewPercentage((viewPercentage) => !viewPercentage)
                  }
                >
                  {viewPercentage ? "VIEW HOURS" : "VIEW PERCENTAGE"}
                </Button>
              </Box>
              <Box sx={{ m: -1, pl: 1 }}>
                <Button
                  color="primary"
                  sx={{ m: 1 }}
                  variant="outlined"
                  onClick={() =>
                    setUtilizationType((t) => (t === "week" ? "month" : "week"))
                  }
                >
                  {utilizationType === "week" ? "VIEW MONTHLY" : "VIEW WEEKLY"}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ mt: 2, display: "flex" }}>
              <FormControl>
                <InputLabel
                  id="select-status"
                  sx={{ color: "#bbb", top: "-8px" }}
                >
                  Department
                </InputLabel>
                <Select
                  label="Department"
                  value={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                  sx={{
                    width: "200px",
                    ".MuiSelect-select": {
                      padding: "10px 10px",
                    },
                    // height: "40px",
                  }}
                >
                  <MenuItem selected value={""}>
                    None
                  </MenuItem>
                  {departmentDropDownOptions.map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.department}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ ml: 2 }}>
                <FormControl>
                  <InputLabel
                    id="select-status"
                    sx={{ color: "#bbb", top: "-8px" }}
                  >
                    Designation
                  </InputLabel>
                  <Select
                    label="Designation"
                    value={selectedDesignation}
                    onChange={(e) => setSelectedDesignation(e.target.value)}
                    sx={{
                      width: "200px",
                      ".MuiSelect-select": {
                        padding: "10px 10px",
                      },
                      // height: "40px",
                    }}
                  >
                    <MenuItem selected value={""}>
                      None
                    </MenuItem>
                    {designationDropDownOptions.map(({ designation }) => (
                      <MenuItem key={designation} value={designation}>
                        {designation}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ ml: 2 }}>
                <FormControl>
                  <InputLabel
                    id="select-status"
                    sx={{ color: "#bbb", top: "-8px" }}
                  >
                    Project
                  </InputLabel>
                  <Select
                    label="Project"
                    value={projectFilter}
                    onChange={(e) => setProjectFilter(e.target.value)}
                    sx={{
                      width: "200px",
                      ".MuiSelect-select": {
                        padding: "10px 10px",
                      },
                      // height: "40px",
                    }}
                  >
                    <MenuItem selected value={""}>
                      None
                    </MenuItem>
                    {/* {designationDropDownOptions.map(({ designation }) => (
                      <MenuItem key={designation} value={designation}>
                        {designation}
                      </MenuItem>
                    ))} */}
                    {projectDropDown.map((project) => (
                      <MenuItem key={project.id} value={project.id}>
                        {project.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  ml: 2,
                }}
              >
                <FormControl>
                  {memberNameFilter && (
                    <InputLabel
                      shrink={true}
                      id="select-status"
                      sx={{
                        color: "#bbb",
                        top: "-8px",
                        background: "#F8F9FB",
                        p: 0.5,
                      }}
                    >
                      Member Name
                    </InputLabel>
                  )}
                  <TextField
                    id="outlined-basic"
                    placeholder=" Member Name"
                    variant="outlined"
                    // shrink={true}
                    value={memberNameFilter}
                    onChange={(e) => setMemberNameFilter(e.target.value)}
                    sx={{
                      width: "200px",
                      height: "40px",
                      ".MuiOutlinedInput-input": {
                        padding: "10px 14px",
                      },
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
            {/* <Box sx={{ mt: 2, ml: 2 }}>
              <FormControl>
                <InputLabel
                  id="select-status"
                  sx={{ color: "#bbb", top: "-8px" }}
                >
                  Role
                </InputLabel>
                <Select
                  label="Role"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  sx={{
                    width: "200px",
                    ".MuiSelect-select": {
                      padding: "10px 10px",
                    },
                    // height: "40px",
                  }}
                >
                  <MenuItem selected value={""}>
                    None
                  </MenuItem>
                  {roles.map((role) => (
                    <MenuItem key={role.roleType} value={role.roleType}>
                      {role.roleValue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box> */}
            <Box>
              <ButtonGroup size="small">
                <Button
                  onClick={() => {
                    handleStepsChange("prev");
                  }}
                >
                  Prev
                </Button>
                <Button
                  onClick={() => {
                    handleStepsChange("today");
                  }}
                >
                  Today
                </Button>
                <Button
                  onClick={() => {
                    handleStepsChange("next");
                  }}
                >
                  Next
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
          <Box sx={{ mt: 3 }}>
            <Utilization
              view={utilizationType}
              viewPercentage={viewPercentage}
              selectedDepartment={selectedDepartment}
              selectedDesignation={selectedDesignation}
              selectedRole={selectedRole}
              memberNameFilter={memberNameFilter}
              projectFilter={projectFilter}
              steps={steps}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default PeopleUtilization;
