import { handleApiRequest } from "../../hooks/callApi";

export const createDesignation = async (data) => {
  return await handleApiRequest("/api/designations", "post", data);
};

export const getAllDesignations = async () => {
  return await handleApiRequest("/api/designations", "get");
};

export const editDesignation = async (data) => {
  return await handleApiRequest(`/api/designations/${data.id}`, "put", data);
};
