import React, { useState, useEffect } from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import {
  getArchivedPortfolios,
  restoreArchivedPortfolio,
} from "../../../../api/archive/Archive";
import useLoader from "../../../../hooks/useLoader";
import SingleArchiveRow from "./SingleArchiveRow";
const ArchivePortfolios = () => {
  const [archivedPortfolios, setArchivedPortfolios] = useState([]);
  const { loaderStart, loaderEnd } = useLoader();

  useEffect(() => {
    getAllArchivedPortfolios();
  }, []);

  const getAllArchivedPortfolios = async () => {
    loaderStart();
    const res = await getArchivedPortfolios();
    if (res.data.data.items) {
      setArchivedPortfolios(res.data.data.items);
    }
    loaderEnd();
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Deleted At</TableCell>
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {archivedPortfolios.map((project) => (
          <SingleArchiveRow
            key={project.id}
            item={project}
            onSuccess={getAllArchivedPortfolios}
            onDelete={restoreArchivedPortfolio}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default ArchivePortfolios;
