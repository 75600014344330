import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";

import useSettings from "../../hooks/useSettings";
import ChevronRightIcon from "../../icons/ChevronRight";
import PlusIcon from "../../icons/Plus";
import ViewAllPeopleListTable from "../../components/dashboard/people/ViewAllPeopleListTable";
import useCommons from "../../hooks/useCommons";
import PermissionGuard from "../../components/PermissionGuard";

const ViewAllPeople = () => {
  const { settings } = useSettings();

  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const { allPeopleCount } = useCommons();

  return (
    <>
      <Helmet>
        <title>Dashboard: View All People</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  to="/"
                  variant="breadCrumbText"
                >
                  Dashboard
                </Link>
                <Typography color="textSecondary" variant="breadCrumbText">
                  People
                </Typography>
              </Breadcrumbs>
              <Typography color="textPrimary" variant="dashboardHeading">
                People
              </Typography>
              <Box
                sx={{
                  mt: 0.5,
                }}
              >
                <Typography
                  color="textSecondary"
                  component="span"
                  variant="dashboardInfo"
                >
                  Total People:
                </Typography>
                <Typography
                  component="span"
                  variant="dashboardInfo"
                  sx={{
                    color: "#00000E",
                    pl: 1,
                  }}
                >
                  {allPeopleCount}
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <PermissionGuard
                  permissionCheck="PEOPLE_CREATE"
                  returnEmpty={true}
                >
                  <Button
                    color="primary"
                    startIcon={<PlusIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => setShowAddMemberModal(true)}
                  >
                    ADD NEW PEOPLE
                  </Button>
                </PermissionGuard>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            <ViewAllPeopleListTable
              showAddMemberModal={showAddMemberModal}
              setShowAddMemberModal={setShowAddMemberModal}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ViewAllPeople;
