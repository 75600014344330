import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Pagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import SingleDocument from "./SingleDocument";
import { getAllDocuments } from "../../../api/project/Project";
import AddPhysicalDocument from "./AddPhysicalDocument";
import AddDocumentLink from "./AddDocumentLink";
import useLoader from "../../../hooks/useLoader";

const Documents = ({
  selectedProject,
  setShowAddPhysicalDoumentModal,
  showAddPhysicalDoumentModal,
  setShowAddDoumentLinkModal,
  showAddDoumentLinkModal,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [documents, setDocuments] = useState([]);

  const { loaderState, loaderStart, loaderEnd } = useLoader();
  useEffect(() => {
    getProjectDocuments();
  }, [currentPage]);

  const getProjectDocuments = async () => {
    loaderStart();
    const res = await getAllDocuments({
      projectId: selectedProject.id,
      page: currentPage,
    });
    if (res.data.success) {
      setDocuments(res.data.data.documents);
    }
    loaderEnd();
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      {showAddDoumentLinkModal && (
        <AddDocumentLink
          selectedProject={selectedProject}
          setShowAddDoumentLinkModal={setShowAddDoumentLinkModal}
          showAddDoumentLinkModal={showAddDoumentLinkModal}
          getProjectDocuments={getProjectDocuments}
        />
      )}
      {showAddPhysicalDoumentModal && (
        <AddPhysicalDocument
          selectedProject={selectedProject}
          showAddPhysicalDoumentModal={showAddPhysicalDoumentModal}
          setShowAddPhysicalDoumentModal={setShowAddPhysicalDoumentModal}
          getProjectDocuments={getProjectDocuments}
        />
      )}
      <Box sx={{ pt: 1 }}>
        <Table>
          <TableHead>
            <TableRow sx={{ th: { fontWeight: "700", fontSize: "13px" } }}>
              <TableCell>#</TableCell>
              <TableCell>DOCUMENT TITLE</TableCell>
              <TableCell>UPLOADED BY</TableCell>
              <TableCell align="center" sx={{ maxWidth: "100px" }}>
                DOCUMENT TYPE
              </TableCell>
              <TableCell>DOCUMENT LINK</TableCell>
              <TableCell align="center">ACTION</TableCell>
            </TableRow>
          </TableHead>
          {documents?.data?.length > 0 && (
            <TableBody>
              {documents?.data.map((document, index) => {
                return (
                  <SingleDocument
                    document={document}
                    key={index}
                    index={index}
                    getProjectDocuments={getProjectDocuments}
                  />
                );
              })}
            </TableBody>
          )}
        </Table>
        <Box sx={{ py: 4, display: "flex", justifyContent: "center" }}>
          {documents?.data?.length > 0 ? (
            <Pagination
              page={currentPage}
              onChange={handleChange}
              count={documents.last_page}
            />
          ) : (
            <Typography variant="h6" color="secondary">
              {loaderState ? "Loading..." : "No Records Available"}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Documents;
