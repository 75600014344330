import { Button, Paper } from "@material-ui/core";
import useCommons from "../hooks/useCommons";

const CustomAutocomplete = (props) => {
  const { children } = props;
  const { setShowAddMemberModal } = useCommons();
  return (
    <>
      <Paper elivation={8} {...props}>
        {children}
        <Button
          onMouseDown={(event) => {
            event.preventDefault();
          }}
          onClick={(e) => {
            setShowAddMemberModal(true);
          }}
          color="primary"
          size="small"
          variant="contained"
          fullWidth
        >
          Add User
        </Button>
      </Paper>
    </>
  );
};

export default CustomAutocomplete;
