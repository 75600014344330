import { createContext, useReducer, useEffect } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { getAllProjectsAPI, getProjectMembers } from "../api/project/Project";
import useLoader from "../hooks/useLoader";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const initialState = {
  allProjects: [],
  allProjectMembers: [],
  totalProjectCount: 0,
};

const handlers = {
  ALLPROJECTS: (state, action) => {
    const { allProjects } = action.payload;

    return {
      ...state,
      allProjects,
    };
  },
  ALLPROJECTMEMBERS: (state, action) => {
    const { allProjectMembers } = action.payload;

    return {
      ...state,
      allProjectMembers,
    };
  },
  PROJECTCOUNT: (state, action) => {
    const { totalProjectCount } = action.payload;

    return {
      ...state,
      totalProjectCount,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const ProjectContext = createContext({
  ...initialState,
  getAllProject: () => Promise.resolve(),
  getProjectCount: () => Promise.resolve(),
  getSingleProject: () => Promise.resolve(),
  getAllProjectMembers: () => Promise.resolve(),
});

export const ProjectProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loaderStart, loaderEnd } = useLoader();
  const navigate = useNavigate();

  const { user } = useAuth();
  useEffect(() => {
    if (user?.id) {
      getProjectCount();
    }
  }, [user]);

  const getAllProject = async (pageNum, projectType, projectName) => {
    try {
      loaderStart();
      const res = await getAllProjectsAPI(pageNum, projectType, projectName);

      dispatch({
        type: "ALLPROJECTS",
        payload: {
          allProjects: res.data.data.projects,
        },
      });
      loaderEnd();
    } catch (err) {
      loaderEnd();
      console.log(err);
    }
  };

  const getSingleProject = async (projectId) => {
    try {
      loaderStart();
      const accessToken = window.localStorage.getItem("accessToken");
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project/${projectId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (res.data.success) {
        return res.data.data.project;
      }
      loaderEnd();
    } catch (err) {
      console.log(err);
      if (err.response.status === 401) {
        navigate("/un-authenticated");
      }
      loaderEnd();
    }
  };

  const getAllProjectMembers = async (projectId, page) => {
    try {
      loaderStart();
      const res = await getProjectMembers(projectId, page);

      if (res.data.success) {
        dispatch({
          type: "ALLPROJECTMEMBERS",
          payload: {
            allProjectMembers: res.data.data.projectMembers,
          },
        });
      }
      loaderEnd();
    } catch (err) {
      loaderEnd();
      console.log(err);
    }
  };

  const getProjectCount = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/project-count`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      dispatch({
        type: "PROJECTCOUNT",
        payload: {
          totalProjectCount: res.data.data.count,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        ...state,
        getAllProject,
        getSingleProject,
        getAllProjectMembers,
        getProjectCount,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProjectContext;
