import { handleApiRequest } from "../../hooks/callApi";

export const fetchScheduling = async ({ startDate, endDate, userId }) => {
  const body = {
    start_date: startDate,
    end_date: endDate,
    user_id: userId || "",
  };

  const res = await handleApiRequest(`/api/utilization`, "post", body);
  return res;
};

export const fetchMemberScheduling = async ({ startDate, endDate, userId }) => {
  const body = {
    start_date: startDate,
    end_date: endDate,
    user_id: userId || "",
  };

  const res = await handleApiRequest(`/api/scheduling`, "post", body);
  return res;
};

export const fetchAvailableMembers = async ({ startDate, endDate }) => {
  const body = {
    start_date: startDate,
    end_date: endDate,
  };

  const res = await handleApiRequest(`/api/membersAvailable`, "post", body);
  return res;
};

export const fetchUserTasks = async ({ userId, projectId }) => {
  const res = await handleApiRequest(
    `/api/scheduling/tasks/${userId}?proj_id=${projectId}`,
    "get"
  );

  return res;
};

export const assignTask = async ({ userId, taskId }) => {
  const body = {
    person_id: userId,
  };

  const res = await handleApiRequest(
    `/api/project/task/assign/${taskId}`,
    "post",
    body
  );

  return res;
};
