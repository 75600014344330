import { Avatar, Box, Divider, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core'
import React from 'react'
import User from '../../../../icons/User'

const ProjectComments = ({allComments}) => {
  
  return (
    <Box sx={{maxHeight: '320px', overflowY: 'auto'}}>
        {/* Show Comment design using Material UI */}
        {allComments.length > 0 ? <List sx={{width: '100%', bgcolor: 'background.paper'}}>
             
              {allComments.map((comment) => <React.Fragment key={comment.id}><ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar alt={`${comment.first_name} ${comment.last_name}`} src={User} />
              </ListItemAvatar>
              <ListItemText
                primary= {`${comment.first_name} ${comment.last_name}`}
                secondary={
                  <>
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                     
                      {comment.comment}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <Divider sx={{opacity: '0.5'}} variant="inset" component="li" />
            </React.Fragment>
            )}
        </List> : <Typography variant="h6"  sx={{textAlign: 'center', mt: 2, color: "#ccbbbc"}}>No Comments</Typography>}
    </Box>
  )
}

export default ProjectComments